import React, { useState } from 'react';
import '../maszyny.css'; 
import { Carousel } from 'react-responsive-carousel'; 
import { CgArrowDownR } from "react-icons/cg";
import Navbar4 from '../../../compo/navbar4/navbar4';
import Stopa from '../../../compo/stopa/Stopa';

// AccordionSection component to handle collapsible sections
const AccordionSection = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => setIsOpen(!isOpen);

  return (
    <div className={`accordion-section ${isOpen ? 'is-open' : ''}`}>
      <button className="accordion-title" onClick={toggleOpen}>
        {title}
      </button>
      <div className="accordion-content">
        {children}
      </div>
    </div>
  );
};

// Function to render the table sections
const renderTableSection = (data) => (
  <div className="table-container">
    <table className="specs-table">
      <tbody>
        {Object.entries(data).map(([label, value]) => (
          <tr key={label}>
            <td>{label}</td>
            <td>{value}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);

const Mt73575dSt5 = () => {
  const [isDescriptionExpanded, setDescriptionExpanded] = useState(false); // State for toggling description

  const toggleDescription = () => {
    setDescriptionExpanded(!isDescriptionExpanded); // Toggle description state
  };

  const specs = {
    'Pojemność': {
      'Udźwig max.': '3500 kg',
      'Max. wysokość podnoszenia':'6.90 m',
      'Wysięg max.': '4 m',
      'Siła skrawania z łyżką': '6850 daN'
    },
    'Waga i wymiary': {
      'Długość całkowita do karetki': '4.70 m',
      'Długość do czoła wideł': '4.78 m',
      'Długość całkowita (z widłami)': '5.98 m',
      'Szerokość całkowita': '2.27 m',
      'Wysokość całkowita': '2.48 m',
      'Rozstaw osi': '2.80 m',
      'Prześwit': '0.42 m',
      'Szerokość całkowita kabiny': '0.95 m',
      'Kąt nabierania': '13°',
      'Kąt wysypu': '114°',
      'Zewnętrzny promień skrętu': '3.78 m',
      'Masa bez ładunku (z widłami) z kabiną': '6930 kg',
      'Masa własna': '6610 kg',
      'Masa całkowita': '6930 kg',
      'Typ ogumienia': 'Pneumatyczne',
      'Ogumienie standardowe': 'Alliance 400/80 - 24 -162A8',
      'Długość wideł / Szerokość wideł / Przekrój wideł': '1200 mm x 100 mm x 50 mm'
    },
    'Wydajność': {
      'Podnoszenie': '5.90 s',
      'Opuszczanie': '4.20 s',
      'Wysuwanie teleskopu': '12.30 s',
      'Wsuw teleskopu': '4.70 s',
      'Nabieranie': '3.50 s',
      'Wysyp': '3.60 s'
    },
    'Silnik': {
      'Producent silnika': 'Deutz',
      'Norma emisji spalin': 'Stage V / Tier 4 Final',
      'Model silnika': 'TCD 3.6 L',
      'Liczba siłowników / Parametry siłowników': '4 - 2924 cm³',
      'Moc znamionowa silnika spalinowego / Moc': "75 Hp / 55.40 kW",
      'Max. moment obrotowy / Obroty silnika': '375 Nm @ 1400 rpm',
      'Siła uciągu (z obciążeniem)': "7220 daN",
      'Zatwierdzony przez HVO (zgodnie z normą EN 15940)': 'Tak'
    },
    'Obwód elektryczny': {
      '12V Pojemność baterii': '110 Ah'
    },
    'Transmisja': {
      'Typ przekładni': 'Przetwornik momentu obrotowego',
      'Liczba biegów (do przodu) / tyłu)': '4/4',
      'Prędkość maksymalna': '24.70 km/h',
      'Hamulec postojowy': 'Automatyczny hamulec postojowy',
      'Hamulec serwisowy': 'Hydraulic servo brake'
    },
    'Hydrauliczny': {
      'Typ pompy hydraulicznej': 'Osiowo-tłokowa',
      'Przepływ / Ciśnienie hydrauliki': '110 l/min-260 bar'
    },
    'Pojemność zbiornika': {
      'Olej silnikowy': '8.50 l',
      'Olej hydrauliczny': '129 l',
      'Pojemność zbiornika paliwa': '103 l'
    },
    'Hałas i wibracje': {
      'Hałas na stanowisku operatora (LpA)': '77 dB',
      'Hałas na zewnątrz (LwA)': '104 dB',
      'Wibracje przenoszone na ręce/ramiona': '<2.50 m/s²'
    },
    'Pozostałe': {
      'Koła skrętne (przód / tył)': '2 / 2',
      'Koła napędzane (przód / tył)': '2 / 2',
      'Bezpieczeństwo / Homologacja bezpieczeństwa kabiny': 'Standard EN 15000 / ROPS - FOPS level 2 cab',
      'Rodzaj sterowania': 'JSM'
    }
  };

  const images = [
    "https://views.manitou-group.com/tessa/studio_construction_telehandler_mt_735_manitou_003.PNG/no-locale?channel=medium",
    "https://views.manitou-group.com/tessa/studio_construction_telehandler_mt_1335_manitou_013.png/no-locale?channel=large",
    "https://views.manitou-group.com/tessa/studio_construction_telehandler_mt_735_manitou_001.PNG/no-locale?channel=large",
    "https://views.manitou-group.com/tessa/studio_construction_telehandler_mt_735_manitou_002.PNG/no-locale?channel=large"
  ];

  return (
    <div >
    <div className="product-container">
      <Navbar4 />
      <div className="product-images-info">
        <Carousel showThumbs={true} infiniteLoop autoPlay showStatus={false} className="carousel-root">
          {images.map((src, index) => (
            <img key={index} src={src} alt={`Zdjęcie ${index + 1}`} />
          ))}
        </Carousel>
        <div className="product-info">
          <h2>MANITOU</h2>
          <h1>MT 735 75D ST5</h1>
          <h3>Ładowarki teleskopowe budowlane</h3>
          <table className="info-table">
            <tbody>
              <tr>
                <td>Udźwig max.:</td>
                <td>3500 kg</td>
              </tr>
              <tr>
                <td>Max. wysokość podnoszenia:</td>
                <td>6.90 m</td>
              </tr>
              <tr>
                <td>Wysięg max.:</td>
                <td>4 m</td>
              </tr>
              <tr>
                <td>Moc znamionowa silnika spalinowego:</td>
                <td>75 Hp</td>
              </tr>
            </tbody>
          </table>
          <div className="product-buttons">
          <a href="https://views.manitou-group.com/machines/52001879?format=pdf&amp;unit=metric&amp;locale=pl-PL" className="btn download-button">
          Pobierz kartę danych technicznych
        </a>          </div>
        </div>
      </div>
      <div className="header-container">
        <h2 className="main-header">CHARAKTERYSTYKA TECHNICZNA</h2>
        <CgArrowDownR className='arrow' />
      </div>
      <div className="product-specifications">
        {Object.entries(specs).map(([sectionTitle, data], index) => (
          <AccordionSection key={index} title={sectionTitle}>
            {renderTableSection(data)}
          </AccordionSection>
        ))}
      </div>
      <div className="download-section">

      </div>
    </div>
    <Stopa />
      </div>
  );
};

export default Mt73575dSt5;
