import { useContext } from 'react';
import * as jwt_decode from 'jwt-decode';
import { Link } from 'react-router-dom';
import React from 'react';
import logo from '../img/logo.png';
import AuthContext from '../../context/AuthContext';
import './navbar4.css';

function Navbar4() {
  const { user, logoutUser } = useContext(AuthContext);
  const token = localStorage.getItem("authTokens");

  let decoded;
  if (token) {
    decoded = jwt_decode(token);
  }

  return (

      <div className='navContainer2'>
        <Link to='/' reloadDocument className='logos2'>
          <img src={logo} alt='logo' className='logo2' />
        </Link>
        <div className='ainv2'>

          <Link to='/'><button className='b9'>Strona Główna</button></Link>

        </div>
      </div>

  );
}

export default Navbar4;
