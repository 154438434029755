import React, { useState } from 'react';
import '../maszyny.css'; 
import { Carousel } from 'react-responsive-carousel'; 
import { CgArrowDownR } from "react-icons/cg";
import Navbar4 from '../../../compo/navbar4/navbar4';
import Stopa from '../../../compo/stopa/Stopa';

// AccordionSection component to handle collapsible sections
const AccordionSection = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => setIsOpen(!isOpen);

  return (
    <div className={`accordion-section ${isOpen ? 'is-open' : ''}`}>
      <button className="accordion-title" onClick={toggleOpen}>
        {title}
      </button>
      <div className="accordion-content">
        {children}
      </div>
    </div>
  );
};

// Function to render the table sections
const renderTableSection = (data) => (
  <div className="table-container">
    <table className="specs-table">
      <tbody>
        {Object.entries(data).map(([label, value]) => (
          <tr key={label}>
            <td>{label}</td>
            <td>{value}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);

const Mt930h = () => {
  const [isDescriptionExpanded, setDescriptionExpanded] = useState(false); // State for toggling description

  const toggleDescription = () => {
    setDescriptionExpanded(!isDescriptionExpanded); // Toggle description state
  };

  const specs = {
    'Pojemność': {
      'Udźwig max.': '3000 kg',
      'Max. wysokość podnoszenia':'8.85 m',
      'Wysięg max.': '6.05 m'
    },
    'Waga i wymiary': {
      'Długość całkowita do karetki': '4.67 m',
      'Długość całkowita (z widłami)': '5.77 m',
      'Szerokość całkowita': '1.99 m',
      'Wysokość całkowita': '1.99 m',
      'Rozstaw osi': '2.69 m',
      'Prześwit': '0.35 m',
      'Szerokość całkowita kabiny': '0.79 m',
      'Kąt nabierania': '11°',
      'Kąt wysypu': '116°',
      'Zewnętrzny promień skrętu (po krawędziach opon)': '3.35 m',
      'Masa bez ładunku (z widłami) z kabiną': '6550 kg',
      'Masa całkowita': '6550 kg',
      'Ogumienie standardowe': 'Alliance - 380/75 R20 148A8 A580',
      'Długość wideł / Szerokość wideł / Przekrój wideł': '1070 mm x 100 mm x 50 mm'
    },
    'Wydajność': {
      'Podnoszenie': '10.24 s',
      'Opuszczanie': '7.95 s',
      'Wysuwanie teleskopu': '9.89 s',
      'Wsuw teleskopu': '9.48 s',
      'Nabieranie': '3.06 s',
      'Wysyp': '2.44 s'
    },
    'Silnik': {
      'Producent silnika': 'Kubota',
      'Norma emisji spalin': 'Stage V',
      'Model silnika': 'V3307-CR-T-EW05',
      'Liczba siłowników / Parametry siłowników': '4 - 3331 cm³',
      'Moc znamionowa silnika spalinowego / Moc': "75 Hp / 54.60 kW",
      'Max. moment obrotowy / Obroty silnika': '263 Nm @ 1400 rpm',
      'Siła uciągu (z obciążeniem)': "3860 daN"
    },
    'Transmisja': {
      'Typ przekładni': 'Hydrostatyczny',
      'Liczba biegów (do przodu) / tyłu)': '2/2',
      'Prędkość maksymalna': '24.90 km/h',
      'Hamulec serwisowy': 'Hydrauliczny'
    },
    'Hydrauliczny': {
      'Typ pompy hydraulicznej': 'Pompa zębata',
      'Przepływ / Ciśnienie hydrauliki': '88 l/min-245 bar'
    },
    'Pojemność zbiornika': {
      'Olej silnikowy': '11 l',
      'Olej hydrauliczny': '115 l',
      'Pojemność zbiornika paliwa': '90 l'
    },
    'Hałas i wibracje': {
      'Hałas na stanowisku operatora (LpA)': '74 dB',
      'Hałas na zewnątrz (LwA)': '103 dB',
      'Wibracje przenoszone na ręce/ramiona': '<2.50 m/s²'
    },
    'Pozostałe': {
      'Koła skrętne (przód / tył)': '2 / 2',
      'Homologacja bezpieczeństwa kabiny': 'ROPS - FOPS level 2 cab'
    }
  };

  const images = [
    "https://views.manitou-group.com/tessa/studio_construction_telehandler_mt_930h_manitou_005.png/no-locale?channel=medium",
    "https://views.manitou-group.com/tessa/studio_construction_telehandler_mt_930h_manitou_001.png/no-locale?channel=large",
    "https://views.manitou-group.com/tessa/studio_construction_telehandler_mt_930h_manitou_002.png/no-locale?channel=large",
    "https://views.manitou-group.com/tessa/studio_construction_telehandler_mt_930h_manitou_003.png/no-locale?channel=large"
  ];

  return (
    <div>
    <div className="product-container">
      <Navbar4 />
      <div className="product-images-info">
        <Carousel showThumbs={true} infiniteLoop autoPlay showStatus={false} className="carousel-root">
          {images.map((src, index) => (
            <img key={index} src={src} alt={`Zdjęcie ${index + 1}`} />
          ))}
        </Carousel>
        <div className="product-info">
          <h2>MANITOU</h2>
          <h1>MT 930 H</h1>
          <h3>Ładowarki teleskopowe budowlane</h3>
          <table className="info-table">
            <tbody>
              <tr>
                <td>Udźwig max.:</td>
                <td>3000 kg</td>
              </tr>
              <tr>
                <td>Max. wysokość podnoszenia:</td>
                <td>8.85 m</td>
              </tr>
              <tr>
                <td>Wysięg max.:</td>
                <td>6.05 m</td>
              </tr>
              <tr>
                <td>Moc znamionowa silnika spalinowego:</td>
                <td>75 Hp</td>
              </tr>
            </tbody>
          </table>
          <div className="product-description">
            <p>
              {isDescriptionExpanded 
                ? `The MT 930 can be used for a wide range of applications in confined spaces. The Manitou carriage can be combined with a full range of accessories with the ability to quickly and easily switch out attachments. The MT 930 provides a smooth ride with a hydrostatic transmission while being capable of high off-road performance with 4WS and 4WD. Transportation is simplified with 4 easy to access and well identified lashing and slinging points at the front and rear. Operation in confined spaces is made possible thanks to exceptional compact dimensions + short turning radius 10'10" (3.33 m) outside wheels. Plus high visibility with new TFF and lifting cylinder integrated in the boom`
                : `The MT 930 can be used for a wide range of applications in confined spaces. The Manitou carriage can be combined with a full range of accessories with the ability to quickly and easily switch out attachments.  `
              }
            </p>
            <button className="see-more" onClick={toggleDescription}>
              {isDescriptionExpanded ? 'Pokaż mniej' : 'Zobacz więcej'}
            </button>
          </div>
          <div className="product-buttons">
          <a href="https://views.manitou-group.com/machines/MT_930_H?format=pdf&amp;unit=metric&amp;locale=pl-PL" className="btn download-button">
          Pobierz kartę danych technicznych
        </a>          </div>
        </div>
      </div>
      <div className="header-container">
        <h2 className="main-header">CHARAKTERYSTYKA TECHNICZNA</h2>
        <CgArrowDownR className='arrow' />
      </div>
      <div className="product-specifications">
        {Object.entries(specs).map(([sectionTitle, data], index) => (
          <AccordionSection key={index} title={sectionTitle}>
            {renderTableSection(data)}
          </AccordionSection>
        ))}
      </div>
      <div className="download-section">

      </div>
    </div>
    <Stopa />
    </div>
  );
};

export default Mt930h;
