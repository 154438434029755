import React, { useState } from 'react';
import '../maszyny.css';
import { Carousel } from 'react-responsive-carousel';
import { CgArrowDownR } from "react-icons/cg";
import Navbar4 from '../../../compo/navbar4/navbar4';
import Stopa from '../../../compo/stopa/Stopa';

// AccordionSection component to handle collapsible sections
const AccordionSection = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => setIsOpen(!isOpen);

  return (
    <div className={`accordion-section ${isOpen ? 'is-open' : ''}`}>
      <button className="accordion-title" onClick={toggleOpen}>
        {title}
      </button>
      <div className="accordion-content">
        {children}
      </div>
    </div>
  );
};

// Function to render the table sections
const renderTableSection = (data) => (
  <div className="table-container">
    <table className="specs-table">
      <tbody>
        {Object.entries(data).map(([label, value]) => (
          <tr key={label}>
            <td>{label}</td>
            <td>{value}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);

const Mlt42060h = () => {
  const [isDescriptionExpanded, setDescriptionExpanded] = useState(false); // State for toggling description

  const toggleDescription = () => {
    setDescriptionExpanded(!isDescriptionExpanded); // Toggle description state
  };

  const specs = {
    'Pojemność': {
      'Udźwig max.': '2000 kg',
      'Max. wysokość podnoszenia':'4.35 m',
      'Wysięg max.': '2.65 m',
      'Zasięg przy max. wysokości podnoszenia': '1 m',
      'Siła skrawania z łyżką': '1886 daN'
    },
    'Waga i wymiary': {
      'Masa bez ładunku (z widłami) z kabiną': '4190 kg',
      'Prześwit': '0.26 m',
      'Rozstaw osi': '2.13 m',
      'Długość całkowita do karetki (z zaczepem)': '3.64 m',
      'Długość całkowita do karetki (bez zaczepu)': '3.64 m',
      'Zwis przedni': '1.04 m',
      'Szerokość całkowita': '1.49 m',
      'Szerokość całkowita kabiny': '0.80 m',
      'Wysokość całkowita': '1.96 m',
      'Kąt wysypu': '116°',
      'Kąt nabierania': '12°',
      'Zewnętrzny promień skrętu (po krawędziach opon)': '3.06 m',
      'Ogumienie standardowe': 'CAMSO - SKS532 - 12-16.5 145A2',
      'Koła napędzane (przód / tył)': '2 / 2',
      'Koła skrętne (przód / tył)': '2 / 2'
    },
    'Wydajność': {
      'Podnoszenie': '5 s',
      'Opuszczanie': '3.50 s',
      'Wysuwanie teleskopu': '4.80 s',
      'Wsuw teleskopu': '3 s',
      'Nabieranie': '3.30 s',
      'Wysyp': '2.70 s'
    },
    'Silnik': {
      'Producent silnika': 'Kubota',
      'Norma emisji spalin': 'Stage V',
      'Model silnika': 'V2607-CR-E5B',
      'Liczba siłowników / Parametry siłowników': '4 - 2615 cm³',
      'Moc znamionowa silnika spalinowego / Moc': "57 Hp / 42 kW",
      'Max. moment obrotowy / Obroty silnika': '174 Nm @1600 rpm',
      'Siła uciągu (z obciążeniem)': "2850 daN",
      'Wentylator zwrotny': 'Standard',
      'Układ chłodzenia silnika': '2 chłodnice: wody i oleju hydraulicznego'
    },
    'Transmisja': {
      'Typ przekładni': 'Hydrostatyczny',
      'Liczba biegów (do przodu / tyłu)': '2 / 2',
      'Prędkość max. (może się różnić w zależności od obowiązujących przepisów)': '25 km/h',
      'Blokada dyferencjału': 'Mechanizm różnicowy o ograniczonym poślizgu na osi przedniej',
      'Hamulec postojowy': 'Automatyczny hamulec postojowy (negatywny)'
    },
    'Hydrauliczny': {
      'Typ pompy hydraulicznej': 'Pompa zębata',
      'Przepływ / Ciśnienie hydrauliki': '77 l/min - 235 bar'
    },
    'Pojemność zbiornika': {
      'Pojemność zbiornika paliwa': '60 l'
    },
    'Hałas i wibracje': {
      'Hałas na stanowisku operatora (LpA)': '76 dB',
      'Hałas na zewnątrz (LwA)': '104 dB',
      'Wibracje przenoszone na ręce/ramiona': '<2.50 m/s²'
    },
    'Pozostałe': {
      'Traktorowa homologacja bezpieczeństwa': '',
      'Homologacja bezpieczeństwa kabiny': 'ROPS - FOPS level 1 cab',
      'Rodzaj sterowania': 'JSM'
    }
  };

  const images = [
    "https://views.manitou-group.com/tessa/main_visual_agriculture_telehandler_mlt_420_60_h_manitou.png/no-locale?channel=medium",
    "https://views.manitou-group.com/tessa/studio_agriculture_telehandler_mlt_420_60_h_manitou.jpg/no-locale?channel=large",
    "https://views.manitou-group.com/tessa/studio_agriculture_telehandler_mlt_420_manitou_001.png/no-locale?channel=large",
    "https://views.manitou-group.com/tessa/studio_agriculture_telehandler_mlt_420_manitou_002.png/no-locale?channel=large"
  ];

  return (
    <div>
    <div className="product-container">
      <Navbar4 />
      <div className="product-images-info">
        <Carousel showThumbs={true} infiniteLoop autoPlay showStatus={false} className="carousel-root">
          {images.map((src, index) => (
            <img key={index} src={src} alt={`Zdjęcie ${index + 1}`} />
          ))}
        </Carousel>
        <div className="product-info">
          <h2>MANITOU</h2>
          <h1>MLT 420-60 H</h1>
          <h3>Ładowarki teleskopowe rolnicze</h3>
          <table className="info-table">
            <tbody>
              <tr>
                <td>Udźwig max.:</td>
                <td>2000 kg</td>
              </tr>
              <tr>
                <td>Max. wysokość podnoszenia:</td>
                <td>4.35 m</td>
              </tr>
              <tr>
                <td>Siła skrawania z łyżką:</td>
                <td>1886 daN</td>
              </tr>
              <tr>
                <td>Prześwit:</td>
                <td>0.26 m</td>
              </tr>
            </tbody>
          </table>
          <div className="product-description">
            <p>
              {isDescriptionExpanded 
                ? `Wózek teleskopowy MLT 420-60 H jest bardzo kompaktową maszyną przeznaczoną specjalnie do zastosowań w rolnictwie i pomoże Ci we wszystkich pracach rolniczych. Niewielkie wymiary maszyny zapewniają niezwykłą zwrotność oraz umożliwiają wykonywanie prac w budynkach i ciasnych miejscach. Komfort w kabinie, wygłuszenie kabiny (76 dB max), widoczność 360°, joystick JSM (Joystick Switch & Move) to cechy które zapewnią bezpieczną pracę na co dzień przy minimalnym wysiłku. System ECS (Easy Connect System) ułatwia wymianę osprzętu na głowicy wysięgnika, a cały zestaw kompatybilnego osprzętu zapewni Ci wszechstronność wymaganą przez Twoje gospodarstwo. Precyzyjny, płynny ruch układu hydraulicznego oraz 4 koła napędzane i skrętne w połączeniu z 3 trybami jazdy zapewniają elastyczność pracy w każdej sytuacji.`
                : `Wózek teleskopowy MLT 420-60 H jest bardzo kompaktową maszyną przeznaczoną specjalnie do zastosowań w rolnictwie i pomoże Ci we wszystkich pracach rolniczych. Niewielkie wymiary maszyny zapewniają niezwykłą zwrotność oraz umożliwiają wykonywanie prac w budynkach i ciasnych miejscach. `
              }
            </p>
            <button className="see-more" onClick={toggleDescription}>
              {isDescriptionExpanded ? 'Pokaż mniej' : 'Zobacz więcej'}
            </button>
          </div>
          <div className="product-buttons">
          <a href="https://views.manitou-group.com/machines/XRM4PBIAACAApa2r?format=pdf&amp;unit=metric&amp;locale=pl-PL" className="btn download-button">
          Pobierz kartę danych technicznych
        </a>          </div>
        </div>
      </div>
      <div className="header-container">
        <h2 className="main-header">CHARAKTERYSTYKA TECHNICZNA</h2>
        <CgArrowDownR className='arrow' />
      </div>
      <div className="product-specifications">
        {Object.entries(specs).map(([sectionTitle, data], index) => (
          <AccordionSection key={index} title={sectionTitle}>
            {renderTableSection(data)}
          </AccordionSection>
        ))}
      </div>
      <div className="download-section">

      </div>
    </div>
    <Stopa />
  </div>
  );
};

export default Mlt42060h;
