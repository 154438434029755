import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './imgcarou.css';
import sprite from './sprite.svg';
import zdj2 from '../zdjMaszyn/carousel/2.jpg';
import zdj3 from '../zdjMaszyn/carousel/3.jpg';
import zdj4 from '../zdjMaszyn/carousel/4.jpg';

const images = [zdj2, zdj3, zdj4];

function Imgcaro() {
  return (
    <div className="image-carousel-container">
      <Carousel
        showThumbs={false}
        infiniteLoop
        autoPlay
        showStatus={false}
        className="custom-carousel-root"
        renderArrowPrev={(onClickHandler, hasPrev, label) => null}
        renderArrowNext={(onClickHandler, hasNext, label) => null}
      >
        {images.map((src, index) => (
          <div key={index} className="carousel-slide">
            <img src={src} alt={`Zdjęcie ${index + 1}`} />
          </div>
        ))}
      </Carousel>
    </div>
  );
}

export default Imgcaro;
