import React, { useState } from 'react';
import '../maszyny.css';
import { Carousel } from 'react-responsive-carousel';
import { CgArrowDownR } from "react-icons/cg";
import Navbar4 from '../../../compo/navbar4/navbar4';
import Stopa from '../../../compo/stopa/Stopa';

const AccordionSection = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => setIsOpen(!isOpen);

  return (
    <div className={`accordion-section ${isOpen ? 'is-open' : ''}`}>
      <button className="accordion-title" onClick={toggleOpen}>
        {title}
      </button>
      <div className="accordion-content">
        {children}
      </div>
    </div>
  );
};

const renderTableSection = (data) => (
  <div className="table-container">
    <table className="specs-table">
      <tbody>
        {Object.entries(data).map(([label, value]) => (
          <tr key={label}>
            <td>{label}</td>
            <td>{value}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);

const Mla665hz = () => {
  const [isDescriptionExpanded, setDescriptionExpanded] = useState(false);

  const toggleDescription = () => {
    setDescriptionExpanded(!isDescriptionExpanded);
  };

  const specs = {
    'Pojemność': {
      'Statyczne obciążenie destabilizujące z łyżką (na wyprost)': '2907 kg',
      'Statyczne obciążenie destabilizujące z łyżką (pełny skręt)': '2230 kg',
      'Statyczne obciążenie destabilizujące z widłami (na wyprost)': '2259 kg',
      'Statyczne obciążenie destabilizujące z widłami (pełny skręt)': '1778 kg',
      'Max. wysokość punktu obrotu łyżki': '3.32 m',
      'Max. wysokość podnoszenia (pod widłami)': '3.13 m',
      'Siła skrawania z łyżką': '5743 daN'
    },
    'Waga i wymiary': {
      'Masa bez ładunku (z widłami) z dachem na 4 słupkach': '4150 kg',
      'Masa bez ładunku (z widłami) z kabiną': '4309 kg',
      'Rozstaw osi': '2.01 m',
      'Szerokość całkowita dachu na 4 słupkach': '1.28 m',
      'Szerokość całkowita kabiny': '1.28 m',
      'Wysokość całkowita bez FOPS i złożoną ROPS': '1.98 m',
      'Wysokość całkowita z dachem na 4 słupkach': '2.47 m',
      'Wysokość całkowita z kabiną': '2.47 m',
      'Kąt nabierania': '45°',
      'Kąt wysypu': '45°',
      'Kąt przegubu': '45°',
      'Całkowita szerokość bez łyżki': '1.65 m',
      'Całkowita długość - bez łyżki': '4.26 m',
      'Prześwit': '0.37 m'
    },
    'Silnik': {
      'Producent silnika': 'Yanmar',
      'Model silnika': '4TNV98CT_PGWL1',
      'Norma emisji spalin': 'Stage IIIB',
      'Liczba siłowników / Parametry siłowników': '4 - 3300 cm³',
      'Moc znamionowa silnika spalinowego / Moc': '65 Hp / 48.50 kW',
      'Max. moment obrotowy / Obroty silnika': '280 Nm / 1560 rpm',
      'Układ chłodzenia silnika': '2 chłodnice: wody i oleju hydraulicznego'
    },
    'Transmisja': {
      'Typ przekładni': 'Hydrostatyczny',
      'Liczba biegów (do przodu) / tyłu)': '2 / 2',
      'Prędkość max. (może się różnić w zależności od obowiązujących przepisów)': '30 km/h',
      'Prędkość jazdy (z obciążeniem)': '20 km/h',
      'Blokada dyferencjału': 'Blokada przód i tył',
      'Hamulec postojowy': 'Manualny'
    },
    'Hydrauliczny': {
      'Typ pompy hydraulicznej': 'Pompa zębata',
      'Przepływ - Ciśnienie hydrauliki': '70 l/min / 200 bar',
      'Opcjonalna hydraulika wysokiego przepływu (l/min)': '100 l/min',
      'Ciśnienie w hydraulicznym układzie napędowym': '420 bar'
    },
    'Pojemność zbiornika': {
      'Olej hydrauliczny': '74 l',
      'Pojemność zbiornika paliwa': '95 l'
    },
    'Hałas i wibracje': {
      'Wibracje przenoszone na ręce/ramiona': '<2.50 m/s²'
    },
    'Pozostałe': {
      'Homologacja bezpieczeństwa kabiny': 'ROPS - FOPS level 1 cab'
    }
  };

  const images = [
    "https://views.manitou-group.com/tessa/main_visual_articulated_loader_mla_6_65_h_z_manitou.png/no-locale?channel=medium",
    "https://views.manitou-group.com/tessa/studio_articulated_loader_mla6_65h_manitou_001.png/no-locale?channel=large",
    "https://views.manitou-group.com/tessa/studio_articulated_loader_mla6_65h_manitou_002.png/no-locale?channel=large",
    "https://views.manitou-group.com/tessa/studio_articulated_loader_mla6_65h_manitou_003.png/no-locale?channel=large"
  ];

  const galleryImages = [
    "https://views.manitou-group.com/tessa/application_articulated_loader_mla6_65hz_manitou_001.tif/no-locale?channel=medium",
    "https://views.manitou-group.com/tessa/application_articulated_loader_mla6_65hz_manitou_002.tif/no-locale?channel=medium",
    "https://views.manitou-group.com/tessa/application_articulated_loader_mla6_65hz_manitou_003.tif/no-locale?channel=medium"
  ];

  return (
    <div>
    <div className="product-container">
      <Navbar4 />
      <div className="product-images-info">
        <Carousel showThumbs={true} infiniteLoop autoPlay showStatus={false} className="carousel-root">
          {images.map((src, index) => (
            <img key={index} src={src} alt={`Zdjęcie ${index + 1}`} />
          ))}
        </Carousel>
        <div className="product-info">
          <h2>MANITOU</h2>
          <h1>MLA 6-65 H-Z</h1>
          <h3>Ładowarki przegubowe</h3>
          <table className="info-table">
            <tbody>
              <tr>
                <td>Statyczne obciążenie destabilizujące z widłami (na wyprost):</td>
                <td>2259 kg</td>
              </tr>
              <tr>
                <td>Statyczne obciążenie destabilizujące z łyżką (na wyprost):</td>
                <td>2907 kg</td>
              </tr>
              <tr>
                <td>Max. wysokość punktu obrotu łyżki:</td>
                <td>3.32 m</td>
              </tr>
              <tr>
                <td>Moc znamionowa silnika spalinowego:</td>
                <td>65 Hp</td>
              </tr>
            </tbody>
          </table>
          <div className="product-description">
            <p>
              {isDescriptionExpanded 
                ? `Ładowarka MLA 6-65 jest ładowarką kołową która łączy w sobie niewielkie wymiary z prawdziwą mocą. Przekładnia hydrostatyczna zapewnia doskonałe możliwości w każdym terenie, nie ograniczając przy tym płynności pracy na niewielkich przestrzeniach. Zastosowanie kinematyki ramienia "Z" zapewnia doskonałą siłę odspajania, wyższą wysokość podnoszenia i większy zasięg. Maszyna została zaprojektowana z myślą o zapewnieniu komfortu operatora. Wszystkie przyrządy sterujące skoncentrowane są wokół joysticka i na regulowanym oparciu. Dzięki widoczności w zakresie 360°, możliwości dostosowania przestrzeni roboczej i ergonomicznym przyrządom sterującym, ta maszyna jest przyjazna dla operatora korzystającego z niej nawet wiele godzin dziennie.`
                : `Ładowarka MLA 6-65 jest ładowarką kołową która łączy w sobie niewielkie wymiary z prawdziwą mocą. Przekładnia hydrostatyczna zapewnia doskonałe możliwości w każdym terenie, nie ograniczając przy tym płynności pracy na niewielkich przestrzeniach.`
              }
            </p>
            <button className="see-more" onClick={toggleDescription}>
              {isDescriptionExpanded ? 'Pokaż mniej' : 'Zobacz więcej'}
            </button>
          </div>
          <div className="product-buttons">
          <a href="https://views.manitou-group.com/machines/XBpH5RUAACoAayZr?format=pdf&unit=metric&locale=pl-PL" className="btn download-button">
          Pobierz kartę danych technicznych
        </a>          </div>
        </div>
      </div>
     
      <div className="product-specifications">
        {Object.entries(specs).map(([sectionTitle, data], index) => (
          <AccordionSection key={index} title={sectionTitle}>
            {renderTableSection(data)}
          </AccordionSection>
        ))}
      </div>
      <div className="download-section">

      </div>
    </div>
    <Stopa/>
    </div>
  );
};

export default Mla665hz;
