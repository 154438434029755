import React, { useState } from 'react';
import '../maszyny.css'; 
import AccordionSection from '../AccordionSection';
import { Carousel } from 'react-responsive-carousel';
import zdj1 from './zdj/mht790/mht_790_manitou_001.png';
import zdj2 from './zdj/mht790/mht_790_manitou_002.png';
import zdj3 from './zdj/mht790/mht_790_manitou_004.png';
import { CgArrowDownR } from "react-icons/cg";
import Navbar4 from '../../../compo/navbar4/navbar4';
import Stopa from '../../../compo/stopa/Stopa';

const mt733 = () => {
  const specs = {
    'Pojemność': {
      'Udźwig max.': '3500 kg',
      'Max. wysokość podnoszenia':'6.90 m',
      'Wysięg max.': '4 m',
      'Siła skrawania z łyżką':'6850daN'
    },
    'Waga i wymiary': {
      'Długość całkowita do karetki': '4.70 m',
      'Długość do czoła wideł': '4.78 m',
      'Długość całkowita z widałami': '5.98 m',
      'Szerokość całkowita': '2.27 m',
      'Prześwit': '0.42 m',
      'Rozstaw osi': '2.80 m',
      'Wysokość całkowita': '2.48 m',
      'Szerokość całkowita kabiny': '0.95 m',
      'Kąt wysypu': '114°',
      'Kąt nabierania': '13°',
      'Zewnętrzny promień skrętu (po krawędziach opon)': '3.78 m',
      'Długość wideł / Szerokość wideł / Przekrój wideł': '1200 mm x 100 mm / 50 mm',
      'Masa całkowita': '6930 kg'
    },
    'Wydajność': {
        'Podnoszenie': '5.90s',
        'Opuszczanie': '4.20s',
        'Wysuwanie teleskopu': '12.30s',
        'Wsuw teleskopu': '4.70s',
        'Nabieranie':'3.50s',
        'Wysyp':'3.60s'
    },
    'Silnik': {
        'Producent silnika': 'Deutz',
        'Norma emisji spalin': 'Stage V / Tier 4 Final',
        'Model silnika': 'TCD 3.6 L',
        'Liczba siłowników / Parametry siłowników': '4 - 2924 cm³',
        'Moc znamionowa silnika spalinowego / Moc': "75 Hp / 55.40 kW",
        'Max. moment obrotowy / Obroty silnika': '375 Nm @ 1400 rpm',
        'Siła uciągu (z obciążeniem)': "7220 daN"
    },
    'Transmisja': {
        'Typ przekładni': 'Przetwornik momentu obrotowego',
        'Liczba biegów (do przodu) / tyłu)': '4 / 4',
        'Prędkość maksymalna': '24.70 km/h',
        'Hamulec postojowy':'Automatyczny hamulec postojowy',        
        'Hamulec serisowy': 'Hydraulic servo brake',
    },
    'Hydrauliczny': {
        'Typ pompy hydraulicznej': 'Osiowo-tłokowa',
        'Ciśnienie hydrauliki': '260 bar'
    },
    'Pojemność zbiornika': {
        'Olej silnikowy': '8.50 l',
        'Olej hydrauliczny': '149 l',
        'Pojemność zbiornika paliwa': '137 l',
    }
  };

  const renderSpecsList = (specs) => (
    <ul className="specs-list">
      {Object.entries(specs).map(([key, value]) => (
        <li key={key}>{`${key}: ${value}`}</li>
      ))}
    </ul>
  );
  const AccordionSection = ({ title, children }) => {
    const [isOpen, setIsOpen] = useState(false);
  
    const toggleOpen = () => setIsOpen(!isOpen);
  
    return (
      <div className={`accordion-section ${isOpen ? 'is-open' : ''}`}>
        <button className="accordion-title" onClick={toggleOpen}>
          {title}
        </button>
        <div className="accordion-content">
          {children}
        </div>
      </div>
    );
  };
  
  const renderTableSection = (data) => (
    <div className="table-container">
      <table className="specs-table">
        <tbody>
          {Object.entries(data).map(([label, value]) => (
            <tr key={label}>
              <td>{label.replace(/([A-Z])/g, ' $1').replace(/_/g, ' ')}</td>
              <td>{value}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  const images = [
    zdj3,
    zdj2,
    zdj1,
  ];

  return (
    <div>
    <div className="product-container">
    <Navbar4 />
      <div className="product-images-info">
        <Carousel showThumbs={true} infiniteLoop autoPlay showStatus={false} className="carousel-root">
          {images.map((src, index) => (
            <img key={index} src={src} alt={`Zdjęcie ${index + 1}`} />
          ))}
        </Carousel>
        <div className="product-info">
          <h2>MANITOU</h2>
          <h1>MT 735 75D ST5</h1>
          <h3>Ładowarki teleskopowe budowlane</h3>
          <table className="info-table">
            <tbody>
            <tr>
                <td>Udźwig max.:</td>
                <td>3500 kg</td>
            </tr>
            <tr>
                <td>Wysięg max.:</td>
                <td>4 m</td>
            </tr>
            <tr>
                <td>Max. wysokość podnoszenia:</td>
                <td>6.90 m</td>
            </tr>
            <tr>
                <td>Moc znamionowa silnika spalinowego:</td>
                <td>75 Hp</td>
            </tr>
            </tbody>
        </table>
        </div>
      </div>
      <div className="header-container">
        <h2 className="main-header">CHARAKTERYSTYKA</h2>
        <h2 className="accent-header">TECHNICZNA  </h2>
        <h1 className='arrow'> <CgArrowDownR /> </h1>

      </div>
      <div className="product-specifications">
        {Object.entries(specs).map(([sectionTitle, data], index) => (
          <AccordionSection key={index} title={sectionTitle}>
            {renderTableSection(data)}
          </AccordionSection>
        ))}
      </div>
    </div>
    <Stopa />
      </div>
  );
};

export default mt733;
