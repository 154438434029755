import React, { useState } from 'react';
import '../maszyny.css';
import { Carousel } from 'react-responsive-carousel';
import { CgArrowDownR } from "react-icons/cg";
import Navbar4 from '../../../compo/navbar4/navbar4';
import Stopa from '../../../compo/stopa/Stopa';

// AccordionSection component to handle collapsible sections
const AccordionSection = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => setIsOpen(!isOpen);

  return (
    <div className={`accordion-section ${isOpen ? 'is-open' : ''}`}>
      <button className="accordion-title" onClick={toggleOpen}>
        {title}
      </button>
      <div className="accordion-content">
        {children}
      </div>
    </div>
  );
};

// Function to render the table sections
const renderTableSection = (data) => (
  <div className="table-container">
    <table className="specs-table">
      <tbody>
        {Object.entries(data).map(([label, value]) => (
          <tr key={label}>
            <td>{label}</td>
            <td>{value}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);

const Mlt635140VPlus = () => {
  const [isDescriptionExpanded, setDescriptionExpanded] = useState(false); // State for toggling description

  const toggleDescription = () => {
    setDescriptionExpanded(!isDescriptionExpanded); // Toggle description state
  };

  const specs = {
    'Pojemność': {
      'Udźwig max.': '3500 kg',
      'Max. wysokość podnoszenia': '6.08 m',
      'Wysięg max.': '3.20 m',
      'Zasięg przy max. wysokości podnoszenia': '0.60 m',
      'Siła skrawania z łyżką': '5441 daN'
    },
    'Waga i wymiary': {
      'Masa bez ładunku (z widłami) z kabiną': '7600 kg',
      'Prześwit': '0.42 m',
      'Rozstaw osi': '2.60 m',
      'Długość całkowita do karetki (z zaczepem)': '4.66 m',
      'Długość całkowita do karetki (bez zaczepu)': '4.50 m',
      'Zwis przedni': '1.11 m',
      'Szerokość całkowita': '2.39 m',
      'Szerokość całkowita kabiny': '0.95 m',
      'Wysokość całkowita': '2.38 m',
      'Kąt wysypu': '134°',
      'Kąt nabierania': '12°',
      'Zewnętrzny promień skrętu (po krawędziach opon)': '3.77 m',
      'Ogumienie standardowe': 'Alliance - A580 - 460/70 R24 159A8',
      'Koła napędzane (przód / tył)': '2 / 2',
      'Koła skrętne (przód / tył)': '2 / 2'
    },
    'Wydajność': {
      'Podnoszenie': '6.80 s',
      'Opuszczanie': '6.80 s',
      'Wysuwanie teleskopu': '4.50 s',
      'Wsuw teleskopu': '4.70 s',
      'Nabieranie': '2.90 s',
      'Wysyp': '2.50 s'
    },
    'Silnik': {
      'Producent silnika': 'Deutz',
      'Norma emisji spalin': 'Stage V / Tier 4 Final',
      'Model silnika': 'TCD 3.6 L4',
      'Liczba siłowników / Parametry siłowników': '4 - 3621 cm³',
      'Moc znamionowa silnika spalinowego / Moc': '136 Hp / 100 kW',
      'Max. moment obrotowy / Obroty silnika': '500 Nm @1600 rpm',
      'Siła uciągu (z obciążeniem)': '6600 daN',
      'Wentylator zwrotny': 'Standard',
      'Układ chłodzenia silnika': '4 chłodnice: woda, intercooler, oleju hydraulicznego i oleju przekładniowego',
      'Zatwierdzony przez HVO (zgodnie z normą EN 15940)': 'Tak'
    },
    'Transmisja': {
      'Typ przekładni': 'M-Vario Plus',
      'Prędkość max. (może się różnić w zależności od obowiązujących przepisów)': '40 km/h',
      'Blokada dyferencjału': 'Mechanizm różnicowy o ograniczonym poślizgu na osi przedniej',
      'Hamulec postojowy': 'Automatyczny hamulec postojowy (negatywny)',
      'Hamulec serwisowy': 'Tarcze hamulcowe w kąpieli olejowej na osi przedniej i tylnej'
    },
    'Hydrauliczny': {
      'Typ pompy hydraulicznej': 'Pompa wielotłoczkowa o zmiennym wydatku (LSU)',
      'Przepływ / Ciśnienie hydrauliki': '170 l/min - 270 bar',
      'Rozdzielacz z dzielnikiem przepływu': 'Standard'
    },
    'Pojemność zbiornika': {
      'Pojemność zbiornika paliwa': '120 l',
      'Płyn DEF (typ AdBlue®)': '10 l'
    },
    'Hałas i wibracje': {
      'Hałas na stanowisku operatora (LpA)': '72 dB',
      'Hałas na zewnątrz (LwA)': '105 dB',
      'Wibracje przenoszone na ręce/ramiona': '<2.50 m/s²'
    },
    'Pozostałe': {
      'Traktorowa homologacja bezpieczeństwa': 'Traktorowa homologacja bezpieczeństwa',
      'Homologacja bezpieczeństwa kabiny': 'ROPS - FOPS level 2 cab',
      'Rodzaj sterowania': 'JSM'
    }
  };

  const images = [
    "https://views.manitou-group.com/tessa/main_visual_agriculture_telehandler_mlt_635_140_v_plus_manitou.png/no-locale?channel=medium",
    "https://views.manitou-group.com/tessa/studio_agriculture_telehandler_mlt_635_manitou_001.png/no-locale?channel=large",
    "https://views.manitou-group.com/tessa/studio_agriculture_telehandler_mlt_635_manitou_002.png/no-locale?channel=large",
    "https://views.manitou-group.com/tessa/studio_agriculture_telehandler_mlt_635_manitou_003.png/no-locale?channel=large"
  ];

  return (
    <div>
    <div className="product-container">
      <Navbar4 />
      <div className="product-images-info">
        <Carousel showThumbs={true} infiniteLoop autoPlay showStatus={false} className="carousel-root">
          {images.map((src, index) => (
            <img key={index} src={src} alt={`Zdjęcie ${index + 1}`} />
          ))}
        </Carousel>
        <div className="product-info">
          <h2>MANITOU</h2>
          <h1>MLT 635-140 V+</h1>
          <h3>Ładowarki teleskopowe rolnicze</h3>
          <table className="info-table">
            <tbody>
              <tr>
                <td>Udźwig max.:</td>
                <td>3500 kg</td>
              </tr>
              <tr>
                <td>Max. wysokość podnoszenia:</td>
                <td>6.08 m</td>
              </tr>
              <tr>
                <td>Siła skrawania z łyżką:</td>
                <td>5441 daN</td>
              </tr>
              <tr>
                <td>Prześwit:</td>
                <td>0.42 m</td>
              </tr>
            </tbody>
          </table>
          <div className="product-description">
            <p>
              {isDescriptionExpanded 
                ? `Szukasz wygodnej, efektywnej i opłacalnej maszyny? W takim razie model MLT 635-140 V + jest dla Ciebie! Jest on specjalnie przeznaczony do pracy w rolnictwie i charakteryzuje się wysokością podnoszenia 6,08 m i udźwigiem 3,5 t.
Przede wszystkim komfort: maszyna ta oferuje kabinę operatora o łatwym dostępie, optymalnej widoczności, system sterowania JSM® (Joystick Switch & Move) na regulowanym oparciu oraz system DSB (Double Switch Buttons).
Przede wszystkim efektywność: MLT 635-140 V + ma wyjątkowe możliwości dzięki silnikowi Deutz o mocy 136 KM i przekładni M-Vario Plus. Mechanizm różnicowy o ograniczonym poślizgu wewnętrznym, prześwit o wysokości 42 cm i chłodnice z automatycznym systemem czyszczącym idealnie nadają się do pracy w branży rolniczej. Dostępna jest również szeroka gama opon, umożliwiajacych maksymalną efektywność w każdym środowisku roboczym. Nowe funkcje hydrauliczne i pompa o zmiennym wydatku 170 l / min zapewniają wysoki poziom produktywności.
Przede wszystkim opłacalność: wózek MLT 635-140 V + zosał zaprojektowany specjalnie z myślą o ograniczeniu całkowitego kosztu posiadania (TCO), w tym optymalizacji zużycia paliwa i kosztów konserwacji maszyny (np. centralna lokalizacja punktów smarowania ...). Manitou umożliwia również łączność z systemem Easy Manager który zarządza, optymalizuje i ochrania maszynę. Wszystkie te cechy umożliwiają maksymalizację wartości rezydualnej Twojego wózka teleskopowego.`
                : `Szukasz wygodnej, efektywnej i opłacalnej maszyny? W takim razie model MLT 635-140 V + jest dla Ciebie! Jest on specjalnie przeznaczony do pracy w rolnictwie i charakteryzuje się wysokością podnoszenia 6,08 m i udźwigiem 3,5 t.

Przede wszystkim komfort: maszyna ta oferuje kabinę operatora o łatwym dostępie, optymalnej widoczności, system sterowania JSM® (Joystick Switch & Move) na regulowanym oparciu oraz system DSB (Double Switch Buttons).`
              }
            </p>
            <button className="see-more" onClick={toggleDescription}>
              {isDescriptionExpanded ? 'Pokaż mniej' : 'Zobacz więcej'}
            </button>
          </div>
          <div className="product-buttons">
          <a href="https://views.manitou-group.com/machines/mlt_635_140V+_newag_st5?format=pdf&amp;unit=metric&amp;locale=pl-PL" className="btn download-button">
          Pobierz kartę danych technicznych
        </a>          </div>
        </div>
      </div>
      <div className="header-container">
        <h2 className="main-header">CHARAKTERYSTYKA TECHNICZNA</h2>
        <CgArrowDownR className='arrow' />
      </div>
      <div className="product-specifications">
        {Object.entries(specs).map(([sectionTitle, data], index) => (
          <AccordionSection key={index} title={sectionTitle}>
            {renderTableSection(data)}
          </AccordionSection>
        ))}
      </div>
      <div className="download-section">

      </div>

    </div>
    <Stopa />
  </div>
  );
};

export default Mlt635140VPlus;
