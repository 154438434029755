import React, { useState } from 'react';
import '../maszyny.css';
import { Carousel } from 'react-responsive-carousel';
import zdj1 from './zdj/mht790/mht_790_manitou_001.png';
import zdj2 from './zdj/mht790/mht_790_manitou_002.png';
import zdj3 from './zdj/mht790/mht_790_manitou_004.png';
import { CgArrowDownR } from "react-icons/cg";
import Navbar4 from '../../../compo/navbar4/navbar4';

const rt1050 = () => {
  const specs = {
    'Pojemność': {
      'Masa robocza': '2141 kg',
      'Masa własna': '1928 kg',
      'Udźwig roboczy przy 35% obciążenia destabilizującego': '476 kg',
      'Udźwig roboczy przy 50% obciążenia destabilizującego': '680 kg',
      'Obciążenie destabilizujące': '1361 kg',
    },
    'Waga i wymiary': {
      'Całkowita wysokość robocza - pełne podniesienie': '3650 mm',
      'Wysokość do sworznia karetki - ramię całkowicie podniesione': '2769 mm',
      'Zasięg wysypu przy maks. wysokości': '673.10 mm',
      'Kąt wysypu przy pełnej wysokości': '38°',
      'Wysokość wysypu przy maks. podnoszeniu': '2134 mm',
      'Maksymalny kąt nabierania przy maks. podnoszeniu': '102°',
      'Całkowita wysokość do górnej krawędzi ROPS': '1816 mm',
      'Całkowita długość z łyżką': '2985 mm',
      'Długość całkowita bez łyżki': '2258 mm',
      'Określona wysokość': '1466 mm',
      'Zasięg przy określonej wysokości': '991 mm',
      'Kąt wysypu przy określonej wysokości': '72°',
      'Maksymalny kąt nabierania na poziomie terenu': '32°',
      'Pozycja transportowa': '127 mm',
      'Maksymalny kąt nabierania w pozycji transportowej': '32°',
      'Pozycja wykopowa': '0 mm',
      'Kąt zejścia ze standardową przeciwwagą': '35°',
      'Prześwit': '191 mm',
      'Rozmiar gąsienic': '1039 mm',
      'Szerokość płyty gąsienicowej': '250 mm',
      'Rozstaw gąsienic': '1283 mm',
      'Całkowita szerokość bez łyżki': '1290 mm',
      'Szerokość łyżki': '1372 mm',
      'Promień skrętu - do przodu z łyżką': '1854 mm',
      'Średnica zachodzenia tyłu': '1290 mm',
      'Maksymalny kąt nabierania przy określonej wysokości': '72°',
      'Kąt natarcia': '90°',
      'Wysokość ostrogi gąsienicy': '25 mm',
      'Typ gąsienic / Rolki gąsienic / Typ rolek': 'Rubber / 4 / Steel',
    },
    'Wydajność': {
      'Prędkość jazdy - wersja jednobiegowa': '10.10 km/h',
      'Uciąg / Siła trakcji': '1996 kg',
      'Siła odspajania - przy zamykaniu łyżki': '1346 kg',
      'Siła odspajania - przy podnoszeniu': '1376 kg',
    },
    'Silnik': {
      'Producent silnika': 'Yanmar',
      'Model silnika': '3TNV88C-KMSV',
      'Typ silnika': 'Radial Piston',
      'Moc maksymalna / maksymalna': '25.50 kW @ 2800 rpm',
      'Moc znamionowa / znamionowa': '23.70 kW @ 2800 rpm',
      'Max. moment obrotowy': '109.40 Nm',
      'Moc znamionowa silnika spalinowego': '34.20 Hp',
      'Akumulator': '12 V',
      'Cold Cranking Amps at Temperature (CCA)': '800 A',
      'Alternator - napięcie / natężenie prądu': '12 V / 55 A',
    },
    'Hydrauliczny': {
      'Układ hydrauliki dodatkowej': '55 l/min',
    },
    'Pojemność zbiornika': {
      'Pojemność miski olejowej': '5.87 l',
      'Pojemność zbiornika oleju hydraulicznego': '30 l',
      'Pojemność zbiornika paliwa': '36 l',
      'Pojemność układu chłodzenia': '6.62 l',
      'Pojemność skokowa / Liczba siłowników': '1.64 l / 3',
    },
    'Hałas i wibracje': {
      'Hałas na zewnątrz (LwA)': '101 dB',
      'Hałas na stanowisku operatora (LpA)': '85.80 dB',
    },
    'Pozostałe': {
      'Nacisk na podłoże': '0.33 bar',
    }
  };

  const renderTableSection = (data) => (
    <div className="table-container">
      <table className="specs-table">
        <tbody>
          {Object.entries(data).map(([label, value]) => (
            <tr key={label}>
              <td>{label.replace(/([A-Z])/g, ' $1').replace(/_/g, ' ')}</td>
              <td>{value}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  const AccordionSection = ({ title, children }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleOpen = () => setIsOpen(!isOpen);

    return (
      <div className={`accordion-section ${isOpen ? 'is-open' : ''}`}>
        <button className="accordion-title" onClick={toggleOpen}>
          {title}
        </button>
        <div className="accordion-content">
          {children}
        </div>
      </div>
    );
  };

  const images = [
    zdj3,
    zdj2,
    zdj1,
  ];

  return (
    <div className="product-container">
      <Navbar4 />
      <div className="product-images-info">
        <Carousel showThumbs={true} infiniteLoop autoPlay showStatus={false} className="carousel-root">
          {images.map((src, index) => (
            <img key={index} src={src} alt={`Zdjęcie ${index + 1}`} />
          ))}
        </Carousel>
        <div className="product-info">
          <table className="info-table">
            <tbody>
              <tr>
                <td>Całkowita wysokość robocza - pełne podniesienie:</td>
                <td>3650 mm</td>
              </tr>
              <tr>
                <td>Zasięg wysypu przy maks. wysokości:</td>
                <td>673.10 mm</td>
              </tr>
              <tr>
                <td>Całkowita długość z łyżką:</td>
                <td>2985 mm</td>
              </tr>
              <tr>
                <td>Całkowita szerokość bez łyżki:</td>
                <td>1290 mm</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="header-container">
        <h2 className="main-header">CHARAKTERYSTYKA</h2>
        <h2 className="accent-header">TECHNICZNA</h2>
        <h1 className="arrow"> <CgArrowDownR /> </h1>
      </div>
      <div className="product-specifications">
        {Object.entries(specs).map(([sectionTitle, data], index) => (
          <AccordionSection key={index} title={sectionTitle}>
            {renderTableSection(data)}
          </AccordionSection>
        ))}
      </div>
    </div>
  );
};

export default rt1050;
