import React, { useState } from 'react';
import '../maszyny.css';
import { Carousel } from 'react-responsive-carousel';
import { CgArrowDownR } from "react-icons/cg";
import Navbar4 from '../../../compo/navbar4/navbar4';
import Stopa from '../../../compo/stopa/Stopa';

const AccordionSection = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => setIsOpen(!isOpen);

  return (
    <div className={`accordion-section ${isOpen ? 'is-open' : ''}`}>
      <button className="accordion-title" onClick={toggleOpen}>
        {title}
      </button>
      <div className="accordion-content">
        {children}
      </div>
    </div>
  );
};

const renderTableSection = (data) => (
  <div className="table-container">
    <table className="specs-table">
      <tbody>
        {Object.entries(data).map(([label, value]) => (
          <tr key={label}>
            <td>{label}</td>
            <td>{value}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);

const MlaT533145VpSt5 = () => {
  const [isDescriptionExpanded, setDescriptionExpanded] = useState(false);

  const toggleDescription = () => {
    setDescriptionExpanded(!isDescriptionExpanded);
  };

  const specs = {
    'Pojemność': {
      'Statyczne obciążenie destabilizujące z widłami (na wyprost)': '4978 kg',
      'Statyczne obciążenie destabilizujące z widłami (pełny skręt)': '4147 kg',
      'Max. ładowność z widłami wg EN 474-3 (80%)': '3300 kg',
      'Max. wysokość podnoszenia (pod widłami)': '5.20 m',
      'Wysięg max.': '3.30 m',
      'Zasięg przy max. wysokości podnoszenia': '1.20 m',
      'Siła skrawania z łyżką': '6730 daN'
    },
    'Waga i wymiary': {
      'Masa bez ładunku (z widłami) z dachem na 2 słupkach': '2.29 kg',
      'Masa bez ładunku (z widłami) z dachem na 4 słupkach': '8430 kg',
      'Masa bez ładunku (z widłami) z kabiną': '8430 kg',
      'Długość całkowita do karetki (z zaczepem)': '5.72 m',
      'Rozstaw osi': '2.50 m',
      'Wysokość całkowita z kabiną': '2.70 m',
      'Kąt nabierania': '9°',
      'Kąt wysypu': '156°',
      'Zewnętrzny promień skrętu (po krawędziach opon)': '4.20 m',
      'Kąt przegubu': '45°',
      'Całkowita szerokość bez łyżki': '2.29 m',
      'Całkowita długość - bez łyżki': '5.72 m',
      'Ogumienie standardowe': 'Alliance - A580 - 460/70 R24 159A8',
      'Prześwit': '0.39 m'
    },
    'Wydajność': {
      'Nabieranie': '3.50 s',
      'Wysyp': '2.70 s'
    },
    'Silnik': {
      'Producent silnika': 'Deutz',
      'Model silnika': 'TCD_4_1_L4',
      'Norma emisji spalin': 'Stage V',
      'Liczba siłowników / Parametry siłowników': '4 - 4038 cm³',
      'Moc znamionowa silnika spalinowego / Moc': '143 Hp / 105 kW',
      'Max. moment obrotowy / Obroty silnika': '550 Nm / 1600 rpm',
      'Układ chłodzenia silnika': '3 chłodnice: wody, intercooler i oleju hydraulicznego'
    },
    'Transmisja': {
      'Typ przekładni': 'M-Varioshift',
      'Prędkość max. (może się różnić w zależności od obowiązujących przepisów)': '40 km/h',
      'Blokada dyferencjału': 'Mechanizm różnicowy o ograniczonym poślizgu (LSD) na osi przedniej i tylnej',
      'Hamulec postojowy': 'Manualny'
    },
    'Hydrauliczny': {
      'Typ pompy hydraulicznej': 'Pompa wielotłoczkowa o zmiennym wydatku (LSU)',
      'Przepływ - Ciśnienie hydrauliki': '158 l/min / 270 bar'
    },
    'Pojemność zbiornika': {
      'Olej hydrauliczny': '150 l',
      'Pojemność zbiornika paliwa': '150 l',
      'Płyn DEF (typ AdBlue®)': '10 l'
    },
    'Hałas i wibracje': {
      'Hałas na stanowisku operatora (LpA)': '72 dB',
      'Hałas na zewnątrz (LwA)': '107 dB',
      'Wibracje przenoszone na ręce/ramiona': '<2.50 m/s²'
    },
    'Pozostałe': {
      'Homologacja bezpieczeństwa kabiny': 'ROPS - FOPS level 2 cab'
    }
  };

  const images = [
    "https://views.manitou-group.com/tessa/studio_articulated_loader_mla_t_533_145_vp_manitou_008.png/no-locale?channel=medium",
    "https://views.manitou-group.com/tessa/studio_articulated_loader_mla_t_533_145_vp_manitou_001.png/no-locale?channel=large",
    "https://views.manitou-group.com/tessa/studio_articulated_loader_mla_t_533_145_vp_manitou_003.png/no-locale?channel=large",
    "https://views.manitou-group.com/tessa/studio_articulated_loader_mla_t_533_145_vp_manitou_004.png/no-locale?channel=large"
  ];

  const galleryImages = [
    "https://views.manitou-group.com/tessa/application_articulated_loader_mla_t_533_145_vp_manitou_001.jpg/no-locale?channel=medium",
    "https://views.manitou-group.com/tessa/application_articulated_loader_mla_t_533_145_vp_manitou_003.jpg/no-locale?channel=medium",
    "https://views.manitou-group.com/tessa/application_articulated_loader_mla_t_533_145_vp_manitou_005.jpg/no-locale?channel=medium"
  ];

  return (
    <div>
    <div className="product-container">
      <Navbar4 />
      <div className="product-images-info">
        <Carousel showThumbs={true} infiniteLoop autoPlay showStatus={false} className="carousel-root">
          {images.map((src, index) => (
            <img key={index} src={src} alt={`Zdjęcie ${index + 1}`} />
          ))}
        </Carousel>
        <div className="product-info">
          <h2>MANITOU</h2>
          <h1>MLA-T 533-145 V+ - ST5</h1>
          <h3>Ładowarki przegubowe</h3>
          <table className="info-table">
            <tbody>
              <tr>
                <td>Statyczne obciążenie destabilizujące z widłami (na wyprost):</td>
                <td>4978 kg</td>
              </tr>
              <tr>
                <td>Moc znamionowa silnika spalinowego:</td>
                <td>143 Hp</td>
              </tr>
              <tr>
                <td>Masa bez ładunku (z widłami) z dachem na 4 słupkach:</td>
                <td>8430 kg</td>
              </tr>
            </tbody>
          </table>
          <div className="product-description">
            <p>
              {isDescriptionExpanded 
                ? `MLA-T 533-145 V + to najnowsza ładowarka przegubowa z ramieniem teleskopowym zaprojektowana przez Manitou. Jest ona wyposażona w wysięgnik teleskopowy, który tłumi drgania podczas jazdy z ładunkiem lub bez ładunku, co ułatwia wszelkie codziennie wykonywane zadania. Ładowarka 533-145 V + została wyposażona w innowacyjne na rynku rolniczym funkcje, takie jak: 4 koła napędzane i skrętne, przegub o maksymalnym wygięciu 45 stopni, system telematyki, możliwość wymiany osprzętu w zaledwie kilka sekund (łyżki, chwytaki, widły ...) w celu ogólnej poprawy produktywności w miejscu pracy. Zdolna do pracy w każdym terenie ładowarka z ramieniem teleskopowym MLA T-533-145 V+ spełnia wszystkie Twoje wymagania jako narzędzie do efektywnej pomocy podczas wszelkiego rodzaju zadań przy transporcie ładunków.`
                : `MLA-T 533-145 V + to najnowsza ładowarka przegubowa z ramieniem teleskopowym zaprojektowana przez Manitou. Jest ona wyposażona w wysięgnik teleskopowy, który tłumi drgania podczas jazdy z ładunkiem lub bez ładunku, co ułatwia wszelkie codziennie wykonywane zadania. Ładowarka 533-145 V + została wyposażona w innowacyjne na rynku rolniczym funkcje, takie jak: 4 koła napędzane`
              }
            </p>
            <button className="see-more" onClick={toggleDescription}>
              {isDescriptionExpanded ? 'Pokaż mniej' : 'Zobacz więcej'}
            </button>
          </div>
          <div className="product-buttons">

          <a href="https://views.manitou-group.com/machines/mla_t_533_145_v+_st5?format=pdf&unit=metric&locale=pl-PL" className="btn download-button">
          Pobierz kartę danych technicznych
        </a>    
          </div>
        </div>
      </div>

      <div className="header-container">
        <h2 className="main-header">CHARAKTERYSTYKA TECHNICZNA</h2>
        <CgArrowDownR className='arrow' />
      </div>
      <div className="product-specifications">
        {Object.entries(specs).map(([sectionTitle, data], index) => (
          <AccordionSection key={index} title={sectionTitle}>
            {renderTableSection(data)}
          </AccordionSection>
        ))}
      </div>
      <div className="download-section">

      </div>
    </div>
    <Stopa/>
    </div>
  );
};

export default MlaT533145VpSt5;
