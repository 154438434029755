import React, { useState } from 'react';
import '../maszyny.css'; 
import { Carousel } from 'react-responsive-carousel'; 
import { CgArrowDownR } from "react-icons/cg";
import Navbar4 from '../../../compo/navbar4/navbar4';
import Stopa from '../../../compo/stopa/Stopa';

// Komponent AccordionSection do obsługi sekcji z możliwością rozwijania
const AccordionSection = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => setIsOpen(!isOpen);

  return (
    <div className={`accordion-section ${isOpen ? 'is-open' : ''}`}>
      <button className="accordion-title" onClick={toggleOpen}>
        {title}
      </button>
      <div className="accordion-content">
        {children}
      </div>
    </div>
  );
};

// Funkcja do renderowania sekcji tabeli
const renderTableSection = (data) => (
  <div className="table-container">
    <table className="specs-table">
      <tbody>
        {Object.entries(data).map(([label, value]) => (
          <tr key={label}>
            <td>{label}</td>
            <td>{value}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);

const Mrt2260 = () => {
  const [isDescriptionExpanded, setDescriptionExpanded] = useState(false); // Stan do przełączania opisu

  const toggleDescription = () => {
    setDescriptionExpanded(!isDescriptionExpanded); // Przełączanie stanu opisu
  };

  const specs = {
    'Pojemność': {
      'Udźwig max.': '6000 kg',
      'Max. wysokość podnoszenia': '21.80 m',
      'Wysięg max.': '18.20 m'
    },
    'Waga i wymiary': {
      'Długość całkowita (z widłami)': '8.17 m',
      'Długość do czoła wideł': '6.97 m',
      'Szerokość całkowita': '2.50 m',
      'Wysokość całkowita': '3.10 m',
      'Szerokość całkowita kabiny': '0.96 m',
      'Prześwit': '0.38 m',
      'Rozstaw osi': '3.05 m',
      'Kąt nabierania': '12°',
      'Kąt wysypu': '110°',
      'Obrót wieżyczki': '360°',
      'Masa całkowita': '18000 kg',
      'Długość wideł / Szerokość wideł / Przekrój wideł': '1200 mm x 100 mm x 60 mm'
    },
    'Koła': {
      'Ogumienie standardowe': '445/65 R22,5',
      'Koła napędzane (przód / tył)': '2 / 2',
      'Tryb sterowania': '2 koła skrętne, 4 koła skrętne, Tryb "kraba"'
    },
    'Stabilizatory': {
      'Typ stabilizatorów': 'Teleskopowy podwójny',
      'Sterowanie ze stabilizatorami': 'Sterowanie stabilizatorami: pojedyncze lub jednoczesne'
    },
    'Silnik': {
      'Producent silnika': 'Yanmar',
      'Norma emisji spalin': 'Stage V / Tier 4',
      'Model silnika': '4TN107FHT-6SMU1',
      'Moc znamionowa silnika spalinowego / Moc': '156 Hp / 115 kW',
      'Max. moment obrotowy / Obroty silnika': '602 Nm @ 1500 rpm',
      'Liczba siłowników / Parametry siłowników': '4 - 4567 cm³',
      'Układ chłodzenia silnika': 'Chłodzenie wodą'
    },
    'Obwód elektryczny': {
      'Liczba akumulatorów / Akumulator': '2 x 12 V',
      '12V Pojemność baterii': '120 Ah',
      'Battery starting current': '(EN)850 A'
    },
    'Transmisja': {
      'Typ przekładni': 'Hydrostatyczny',
      'Skrzynia biegów / Liczba biegów (do przodu) / Liczba biegów (do tyłu)': 'Speedshift / 2 / 2',
      'Prędkość maksymalna': '40 km/h',
      'Siła uciągu (z obciążeniem)': '8600 daN',
      'Hamulec postojowy': 'Automatyczny hamulec postojowy (negatywny)',
      'Hamulec serwisowy': 'Tarcze hamulcowe w kąpieli olejowej na osi przedniej i tylnej'
    },
    'Wydajność': {
      'Zdolność pokonywania wzniesień - z obciążeniem / bez obciążenia': '40.40% / 45.50%'
    },
    'Hydrauliczny': {
      'Typ pompy hydraulicznej': 'Pompa wielotłoczkowa o zmiennym wydatku (LSU)',
      'Przepływ': '185 l/min',
      'Ciśnienie hydrauliki': '350 bar'
    },
    'Pojemność zbiornika': {
      'Olej silnikowy': '13 l',
      'Pojemność zbiornika paliwa': '270 l',
      'Płyn DEF (typ AdBlue®)': '24 l'
    },
    'Hałas i wibracje': {
      'Hałas na stanowisku operatora (LpA)': '68 dB',
      'Hałas na zewnątrz (LwA)': '108 dB',
      'Wibracje przenoszone na ręce/ramiona': '<2.50 m/s²'
    },
    'Pozostałe': {
      'Koła skrętne (przód / tył)': '2 / 2',
      'Rodzaj sterowania': '2 "joysticki"',
      'Homologacja bezpieczeństwa kabiny': 'ROPS - FOPS level 2 cab',
      'System rozpoznawania osprzętu (E-Reco)': 'Standard'
    }
  };

  const images = [
    "https://views.manitou-group.com/tessa/studio_rotating_telehandler_mrt_2260_manitou_003.png/no-locale?channel=medium",
    "https://views.manitou-group.com/tessa/studio_rotating_telehandler_mrt_2260_manitou_001.png/no-locale?channel=large",
    "https://views.manitou-group.com/tessa/studio_rotating_telehandler_mrt_2260_manitou_002.png/no-locale?channel=large",
    "https://views.manitou-group.com/tessa/studio_rotating_telehandler_mrt_2260_manitou_003.png/no-locale?channel=large"
  ];

  return (
    <div>
    <div className="product-container">
      <Navbar4 />
      <div className="product-images-info">
        <Carousel showThumbs={true} infiniteLoop autoPlay showStatus={false} className="carousel-root">
          {images.map((src, index) => (
            <img key={index} src={src} alt={`Zdjęcie ${index + 1}`} />
          ))}
        </Carousel>
        <div className="product-info">
          <h2>MANITOU</h2>
          <h1>MRT 2260</h1>
          <h3>Ładowarki teleskopowe obrotowe</h3>
          <table className="info-table">
            <tbody>
              <tr>
                <td>Udźwig max.:</td>
                <td>6000 kg</td>
              </tr>
              <tr>
                <td>Max. wysokość podnoszenia:</td>
                <td>21.80 m</td>
              </tr>
              <tr>
                <td>Wysięg max.:</td>
                <td>18.20 m</td>
              </tr>
              <tr>
                <td>Moc znamionowa silnika spalinowego:</td>
                <td>156 Hp</td>
              </tr>
            </tbody>
          </table>
          <div className="product-description">
            <p>
              {isDescriptionExpanded 
                ? `MRT 2260 to wyjątkowo wydajna, obrotowa ładowarka teleskopowa do pracy w każdym terenie. Ta maszyna to prawdziwe 3 w 1 - ładowarka teleskopowa, dźwig i podnośnik koszowy z wieloma kompatybilnymi osprzętami, zapewniającymi wszechstronność i wydajność na budowach i w przemyśle. Obrót głowicy o 360° i doskonała widoczność z kabiny pozwala na łatwiejsze wykonywanie każdej operacji, bez konieczności przemieszczania maszyny. Pięciokątny przekrój wysięgnika pozwala na precyzyjny, stabilny ruch, co jest wspomagane poprzez pracę automatycznie pozioujące się stabilizatory. Wyposażony w inteligentne funkcje optymalizujące obsługę i bezpieczeństwo, MRT 2260 jest narzędziem, bez którego nie będziesz mógł się obejść!`
                : `MRT 2260 to wyjątkowo wydajna, obrotowa ładowarka teleskopowa do pracy w każdym terenie. Ta maszyna to prawdziwe 3 w 1 - ładowarka teleskopowa, dźwig i podnośnik koszowy z wieloma kompatybilnymi osprzętami, zapewniającymi wszechstronność i wydajność na budowach i w przemyśle.`
              }
            </p>
            <button className="see-more" onClick={toggleDescription}>
              {isDescriptionExpanded ? 'Pokaż mniej' : 'Zobacz więcej'}
            </button>
          </div>
          <div className="product-buttons">
            <a href="https://views.manitou-group.com/machines/MRT_2260?format=pdf&unit=metric&locale=pl-PL" className="btn download-button">
          Pobierz kartę danych technicznych
        </a>          </div>
        </div>
      </div>
      <div className="header-container">
        <h2 className="main-header">CHARAKTERYSTYKA TECHNICZNA</h2>
        <CgArrowDownR className='arrow' />
      </div>
      <div className="product-specifications">
        {Object.entries(specs).map(([sectionTitle, data], index) => (
          <AccordionSection key={index} title={sectionTitle}>
            {renderTableSection(data)}
          </AccordionSection>
        ))}
      </div>

    </div>
    <Stopa />

    </div>
  );
};

export default Mrt2260;
