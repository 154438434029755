import React, { useState } from 'react';
import '../maszyny.css';
import { Carousel } from 'react-responsive-carousel';
import zdj1 from './zdj/mht790/mht_790_manitou_001.png';
import zdj2 from './zdj/mht790/mht_790_manitou_002.png';
import zdj3 from './zdj/mht790/mht_790_manitou_004.png';
import { CgArrowDownR } from "react-icons/cg";
import Navbar4 from '../../../compo/navbar4/navbar4';

const rt1650 = () => {
  const specs = {
    'Pojemność': {
      'Masa robocza': '3638 kg',
      'Masa własna': '3638 kg',
      'Udźwig roboczy przy 35% obciążenia destabilizującego': '748 kg',
      'Udźwig roboczy przy 50% obciążenia destabilizującego': '1069 kg',
      'Obciążenie destabilizujące': '2139 kg',
    },
    'Waga i wymiary': {
      'Całkowita wysokość robocza - pełne podniesienie': '4008 mm',
      'Wysokość do sworznia karetki - ramię całkowicie podniesione': '3038 mm',
      'Zasięg wysypu przy maks. wysokości': '641 mm',
      'Kąt wysypu przy pełnej wysokości': '39°',
      'Wysokość wysypu przy maks. podnoszeniu': '2329 mm',
      'Maksymalny kąt nabierania przy maks. podnoszeniu': '98°',
      'Całkowita wysokość do górnej krawędzi ROPS': '1969 mm',
      'Całkowita długość z łyżką': '3205 mm',
      'Długość całkowita bez łyżki': '2393 mm',
      'Określona wysokość': '1638 mm',
      'Zasięg przy określonej wysokości': '559 mm',
      'Kąt wysypu przy określonej wysokości': '73°',
      'Maksymalny kąt nabierania na poziomie terenu': '28°',
      'Pozycja transportowa': '198 mm',
      'Maksymalny kąt nabierania w pozycji transportowej': '28°',
      'Pozycja wykopowa': '53 mm',
      'Kąt zejścia ze standardową przeciwwagą': '28°',
      'Prześwit': '185 mm',
      'Rozmiar gąsienic': '1356 mm',
      'Szerokość płyty gąsienicowej': '320 mm',
      'Rozstaw gąsienic': '1265 mm',
      'Całkowita szerokość bez łyżki': '1676 mm',
      'Szerokość łyżki': '1676 mm',
      'Promień skrętu - do przodu z łyżką': '1979 mm',
      'Średnica zachodzenia tyłu': '1440 mm',
      'Maksymalny kąt nabierania przy określonej wysokości': '62°',
      'Kąt natarcia': '90°',
      'Wysokość ostrogi gąsienicy': '25 mm',
      'Typ gąsienic / Rolki gąsienic / Typ rolek': 'Rubber / 3 / Steel',
    },
    'Wydajność': {
      'Prędkość jazdy - wersja jednobiegowa': '10.50 km/h',
      'Prędkość jazdy - wersja dwubiegowa': '16.10 km/h',
      'Uciąg / Siła trakcji': '4345 kg',
      'Siła odspajania - przy zamykaniu łyżki': '1919 kg',
      'Siła odspajania - przy podnoszeniu': '2295 kg',
    },
    'Silnik': {
      'Producent silnika': 'Yanmar',
      'Model silnika': '4TNV98C-NMS2',
      'Typ silnika': 'Radial Piston',
      'Moc maksymalna / maksymalna': '51.70 kW @ 2500 rpm',
      'Moc znamionowa silnika spalinowego': '69.30 Hp',
      'Akumulator': '12 V',
      'Cold Cranking Amps at Temperature (CCA)': '950 A',
      'Alternator - napięcie / natężenie prądu': '12 V / 100 A',
    },
    'Hydrauliczny': {
      'Układ hydrauliki dodatkowej': '71 l/min',
    },
    'Pojemność zbiornika': {
      'Pojemność miski olejowej': '9 l',
      'Pojemność zbiornika oleju hydraulicznego': '39 l',
      'Pojemność zbiornika paliwa': '62.50 l',
      'Pojemność układu chłodzenia': '12.90 l',
      'Pojemność skokowa / Liczba siłowników': '3.30 l / 4',
    },
    'Pozostałe': {
      'Nacisk na podłoże': '0.45 bar',
    },
  };

  const renderTableSection = (data) => (
    <div className="table-container">
      <table className="specs-table">
        <tbody>
          {Object.entries(data).map(([label, value]) => (
            <tr key={label}>
              <td>{label.replace(/([A-Z])/g, ' $1').replace(/_/g, ' ')}</td>
              <td>{value}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  const AccordionSection = ({ title, children }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleOpen = () => setIsOpen(!isOpen);

    return (
      <div className={`accordion-section ${isOpen ? 'is-open' : ''}`}>
        <button className="accordion-title" onClick={toggleOpen}>
          {title}
        </button>
        <div className="accordion-content">
          {children}
        </div>
      </div>
    );
  };

  const images = [
    zdj3,
    zdj2,
    zdj1,
  ];

  return (
    <div className="product-container">
      <Navbar4 />
      <div className="product-images-info">
        <Carousel showThumbs={true} infiniteLoop autoPlay showStatus={false} className="carousel-root">
          {images.map((src, index) => (
            <img key={index} src={src} alt={`Zdjęcie ${index + 1}`} />
          ))}
        </Carousel>
        <div className="product-info">
          <table className="info-table">
            <tbody>
              <tr>
                <td>Całkowita wysokość robocza - pełne podniesienie:</td>
                <td>4008 mm</td>
              </tr>
              <tr>
                <td>Zasięg wysypu przy maks. wysokości:</td>
                <td>641 mm</td>
              </tr>
              <tr>
                <td>Całkowita długość z łyżką:</td>
                <td>3205 mm</td>
              </tr>
              <tr>
                <td>Całkowita szerokość bez łyżki:</td>
                <td>1676 mm</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="header-container">
        <h2 className="main-header">CHARAKTERYSTYKA</h2>
        <h2 className="accent-header">TECHNICZNA</h2>
        <h1 className="arrow"> <CgArrowDownR /> </h1>
      </div>
      <div className="product-specifications">
        {Object.entries(specs).map(([sectionTitle, data], index) => (
          <AccordionSection key={index} title={sectionTitle}>
            {renderTableSection(data)}
          </AccordionSection>
        ))}
      </div>
    </div>
  );
};

export default rt1650;
