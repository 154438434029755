/** @format */

import React from 'react';
import './Stopa.css';
import { FaFacebook } from "react-icons/fa";
import { IoLocationSharp } from "react-icons/io5";
const Stopa = () => {
    return (
        <footer id='kontakts' className='stopa'>
            <div className='kontaktMain'>
                <h1>Kontakt</h1>
                <h4>Czekamy na twoją wiadomość!</h4>
            </div>
            <div className='kontakt'>
                <h3>Gdzie nas znajdziesz</h3>
                <a className='icon-link' href='https://www.facebook.com/Hitmasz/'>
                    <FaFacebook /> Facebook
                </a>
                <a className='icon-link' href='https://www.google.com/maps/place/52.991201,18.6530122'>
                    <IoLocationSharp /> Łódzka 152a Toruń
                </a>
                <p>Telefon: +48 665 669 755</p>
                <p>Email: biuro@hitmasz.pl</p>
                <p>Przedstawiciele handlowi:</p>
                <p>Henryk 607 290 300</p>
                <p>Mateusz 665 669 755</p>
            </div>
            <p>© 2024 Wszelkie prawa zastrzeżone</p>
        </footer>
    );
};

export default Stopa;

