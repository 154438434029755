import React, { useState } from 'react';
import '../maszyny.css'; 
import { Carousel } from 'react-responsive-carousel'; 
import { CgArrowDownR } from "react-icons/cg";
import Navbar4 from '../../../compo/navbar4/navbar4';
import Stopa from '../../../compo/stopa/Stopa';

const AccordionSection = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => setIsOpen(!isOpen);

  return (
    <div className={`accordion-section ${isOpen ? 'is-open' : ''}`}>
      <button className="accordion-title" onClick={toggleOpen}>
        {title}
      </button>
      <div className="accordion-content">
        {children}
      </div>
    </div>
  );
};

const renderTableSection = (data) => (
  <div className="table-container">
    <table className="specs-table">
      <tbody>
        {Object.entries(data).map(([label, value]) => (
          <tr key={label}>
            <td>{label}</td>
            <td>{value}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);

const Mrt3060 = () => {
  const [isDescriptionExpanded, setDescriptionExpanded] = useState(false);

  const toggleDescription = () => {
    setDescriptionExpanded(!isDescriptionExpanded);
  };

  const specs = {
    'Pojemność': {
      'Udźwig max.': '6000 kg',
      'Max. wysokość podnoszenia': '29.90 m',
      'Wysięg max.': '25.90 m'
    },
    'Waga i wymiary': {
      'Długość całkowita (z widłami)': '9.14 m',
      'Długość do czoła wideł': '7.94 m',
      'Szerokość całkowita': '2.50 m',
      'Wysokość całkowita': '3.10 m',
      'Szerokość całkowita kabiny': '0.96 m',
      'Prześwit': '0.38 m',
      'Rozstaw osi': '3.25 m',
      'Kąt nabierania': '12°',
      'Kąt wysypu': '110°',
      'Obrót wieżyczki (maks.)': '360°',
      'Masa całkowita': '21800 kg',
      'Długość wideł / Szerokość wideł / Przekrój wideł': '1200 mm x 100 mm x 60 mm'
    },
    'Koła': {
      'Ogumienie standardowe': '445/65 R22,5',
      'Koła napędzane (przód / tył)': '2 / 2',
      'Tryb sterowania': '2 koła skrętne, 4 koła skrętne, Tryb "kraba"'
    },
    'Stabilizatory': {
      'Typ stabilizatorów': 'Teleskopowy podwójny',
      'Sterowanie ze stabilizatorami': 'Sterowanie stabilizatorami: pojedyncze lub jednoczesne'
    },
    'Silnik': {
      'Producent silnika': 'Yanmar',
      'Norma emisji spalin': 'Stage V / Tier 4',
      'Model silnika': '4TN107FTT-6SMU1',
      'Moc znamionowa silnika spalinowego / Moc': '173 Hp / 127 kW',
      'Max. moment obrotowy / Obroty silnika': '805 Nm @ 1500 rpm',
      'Liczba siłowników / Parametry siłowników': '4 - 4567 cm³'
    },
    'Obwód elektryczny': {
      'Liczba akumulatorów / Akumulator': '2 x 12 V',
      '12V Pojemność baterii': '120 Ah',
      'Battery starting current': '(EN)850 A'
    },
    'Transmisja': {
      'Typ przekładni': 'Hydrostatyczny',
      'Skrzynia biegów / Liczba biegów (do przodu) / Liczba biegów (do tyłu)': 'Speedshift / 2 / 2',
      'Prędkość maksymalna': '40 km/h',
      'Siła uciągu (z obciążeniem)': '9200 daN',
      'Hamulec postojowy': 'Automatyczny hamulec postojowy (negatywny)',
      'Hamulec serwisowy': 'Tarcze hamulcowe w kąpieli olejowej na osi przedniej i tylnej'
    },
    'Wydajność': {
      'Zdolność pokonywania wzniesień - z obciążeniem / bez obciążenia': '32.40% / 41.40%'
    },
    'Hydrauliczny': {
      'Typ pompy hydraulicznej': 'Pompa wielotłoczkowa o zmiennym wydatku (LSU)',
      'Przepływ': '185 l/min',
      'Ciśnienie hydrauliki': '350 bar'
    },
    'Pojemność zbiornika': {
      'Olej silnikowy': '13 l',
      'Pojemność zbiornika paliwa': '320 l',
      'Płyn DEF (typ AdBlue®)': '24 l'
    },
    'Hałas i wibracje': {
      'Hałas na stanowisku operatora (LpA)': '67 dB',
      'Hałas na zewnątrz (LwA)': '108 dB',
      'Wibracje przenoszone na ręce/ramiona': '<2.50 m/s²'
    },
    'Pozostałe': {
      'Koła skrętne (przód / tył)': '2 / 2',
      'Rodzaj sterowania': '2 "joysticki"',
      'Homologacja bezpieczeństwa kabiny': 'ROPS - FOPS level 2 cab',
      'System rozpoznawania osprzętu (E-Reco)': 'Standard'
    }
  };

  const images = [
    "https://views.manitou-group.com/tessa/studio_rotating_telehandler_mrt_3060_manitou_003.png/no-locale?channel=medium",
    "https://views.manitou-group.com/tessa/studio_rotating_telehandler_mrt_3060_manitou_001.png/no-locale?channel=large",
    "https://views.manitou-group.com/tessa/studio_rotating_telehandler_mrt_3060_manitou_002.png/no-locale?channel=large",
    "https://views.manitou-group.com/tessa/studio_rotating_telehandler_mrt_3060_manitou_003.png/no-locale?channel=large"
  ];

  return (
    <div>
    <div className="product-container">
      <Navbar4 />
      <div className="product-images-info">
        <Carousel showThumbs={true} infiniteLoop autoPlay showStatus={false} className="carousel-root">
          {images.map((src, index) => (
            <img key={index} src={src} alt={`Zdjęcie ${index + 1}`} />
          ))}
        </Carousel>
        <div className="product-info">
          <h2>MANITOU</h2>
          <h1>MRT 3060</h1>
          <h3>Ładowarki teleskopowe obrotowe</h3>
          <table className="info-table">
            <tbody>
              <tr>
                <td>Udźwig max.:</td>
                <td>6000 kg</td>
              </tr>
              <tr>
                <td>Max. wysokość podnoszenia:</td>
                <td>29.90 m</td>
              </tr>
              <tr>
                <td>Wysięg max.:</td>
                <td>25.90 m</td>
              </tr>
              <tr>
                <td>Moc znamionowa silnika spalinowego:</td>
                <td>173 Hp</td>
              </tr>
            </tbody>
          </table>
          <div className="product-description">
            <p>
              {isDescriptionExpanded 
                ? `Dzięki połączeniu możliwości trzech maszyn w jednej (ładowarki teleskopowej, dźwigu i podnośnika koszowego) MRT 3060 stanowi prawdziwie wszechstronne narzędzie do Waszej pracy. MRT 3060 może podnosić do 6 ton, zachowując przy tym bardzo precyzyjną kontrolę nad ładunkami dzięki pięciokątnej części wysięgnika i wyjątkowej widoczności. Możliwość obrotu o 360° pozwala na wykonywanie różnych operacji bez konieczności przestawiania maszyny. Maszyna może efektywnie pracować zarówno na oponach jak i pneumatycznych stabilizatorach, które zapewniają większą powierzchnię podparcia dla bezpiecznej pracy. Inteligentny, ergonomiczny i wygodny MRT 3060 jest idealną maszyną do zastosowań w budownictwie i przemyśle. To potężne i precyzyjne narzędzie, to wyjątkowa jakość, bez której nie będziesz chciał się obejść!`
                : `Dzięki połączeniu możliwości trzech maszyn w jednej (ładowarki teleskopowej, dźwigu i podnośnika koszowego) MRT 3060 stanowi prawdziwie wszechstronne narzędzie do Waszej pracy. MRT 3060 może podnosić do 6 ton, zachowując przy tym bardzo precyzyjną kontrolę nad ładunkami dzięki pięciokątnej części wysięgnika i wyjątkowej widoczności`
              }
            </p>
            <button className="see-more" onClick={toggleDescription}>
              {isDescriptionExpanded ? 'Pokaż mniej' : 'Zobacz więcej'}
            </button>
          </div>
          <div className="product-buttons">
            <a href="https://views.manitou-group.com/machines/MRT_3060?format=pdf&unit=metric&locale=pl-PL" className="btn download-button">
          Pobierz kartę danych technicznych
        </a>
          </div>
        </div>
      </div>
      <div className="header-container">
        <h2 className="main-header">CHARAKTERYSTYKA TECHNICZNA</h2>
        <CgArrowDownR className='arrow' />
      </div>
      <div className="product-specifications">
        {Object.entries(specs).map(([sectionTitle, data], index) => (
          <AccordionSection key={index} title={sectionTitle}>
            {renderTableSection(data)}
          </AccordionSection>
        ))}
      </div>

    </div>
    <Stopa />
  </div>
  );
};

export default Mrt3060;
