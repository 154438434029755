import React, { useState } from 'react';
import '../maszyny.css';
import { Carousel } from 'react-responsive-carousel';
import zdj1 from './zdj/mht790/mht_790_manitou_001.png';
import zdj2 from './zdj/mht790/mht_790_manitou_002.png';
import zdj3 from './zdj/mht790/mht_790_manitou_004.png';
import { CgArrowDownR } from "react-icons/cg";
import Navbar4 from '../../../compo/navbar4/navbar4';

const r1050 = () => {
  const specs = {
    'Pojemność': {
      'Udźwig roboczy': '476 kg',
      'Udźwig roboczy z opcjonalną przeciwwagą': '533 kg',
      'Masa własna': '1905 kg',
    },
    'Waga i wymiary': {
      'Całkowita wysokość robocza - pełne podniesienie': '3546 mm',
      'Wysokość do sworznia karetki - ramię całkowicie podniesione': '2746 mm',
      'Całkowita wysokość do górnej krawędzi ROPS': '1786 mm',
      'Kąt wysypu przy pełnej wysokości': '42°',
      'Wysokość wysypu': '2146 mm',
      'Całkowita długość z łyżką': '2896 mm',
      'Zasięg wysypu przy maks. wysokości': '579 mm',
      'Przechył łyżki na podłożu': '29°',
      'Wysokość siedziska nad podłożem': '828 mm',
      'Rozstaw osi': '876 mm',
      'Całkowita szerokość bez łyżki': '1229 mm',
      'Szerokość łyżki': '1404 mm',
      'Prześwit': '152 mm',
      'Całkowita długość - bez łyżki': '2258 mm',
      'Kąt zejścia': '26°',
      'Promień skrętu - do przodu z łyżką': '1763 mm',
      'Promień skrętu - karetki bez łyżki': '1120 mm',
      'Średnica zachodzenia tyłu': '1374 mm',
    },
    'Wydajność': {
      'Prędkość jazdy (bez obciążenia)': '9.50 km/h',
    },
    'Koła': {
      'Ogumienie standardowe': '27x8.5x15 HD',
    },
    'Silnik': {
      'Producent silnika': 'Yanmar',
      'Model silnika': '3TNV88C-KMSV',
      'Norma emisji spalin': 'Stage V, Tier 4',
      'Moc maksymalna': '25.50 kW',
      'Moc znamionowa': '24.70 kW',
      'Max. moment obrotowy / Obroty silnika': '108 Nm / 2800 rpm',
      'Źródło zasilania': 'Diesel',
      'Moc znamionowa silnika spalinowego': '34.20 Hp',
      'Akumulator': '12 V',
      'Alternator': '40 kW',
      'Rozrusznik': '2.30 kW',
    },
    'Hydrauliczny': {
      'Układ hydrauliki dodatkowej': '55.10 l/min',
      'Ciśnienie układu hydrauliki dodatkowej': '189.60 bar',
    },
    'Pojemność zbiornika': {
      'Pojemność zbiornika paliwa': '39.40 l',
      'Pojemność zbiornika oleju hydraulicznego': '30.30 l',
      'Pojemność skokowa': '1.60 l',
    },
    'Hałas i wibracje': {
      'Hałas na zewnątrz (LwA)': '101 dB',
      'Hałas na stanowisku operatora (LpA)': '80 dB',
      'Drgania przenoszone na ciało (ISO 2631-1)': '0.81 m/s²',
      'Wibracje przenoszone na ręce/ramiona': '<0.93 m/s²',
    }
  };

  const renderTableSection = (data) => (
    <div className="table-container">
      <table className="specs-table">
        <tbody>
          {Object.entries(data).map(([label, value]) => (
            <tr key={label}>
              <td>{label.replace(/([A-Z])/g, ' $1').replace(/_/g, ' ')}</td>
              <td>{value}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  const AccordionSection = ({ title, children }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleOpen = () => setIsOpen(!isOpen);

    return (
      <div className={`accordion-section ${isOpen ? 'is-open' : ''}`}>
        <button className="accordion-title" onClick={toggleOpen}>
          {title}
        </button>
        <div className="accordion-content">
          {children}
        </div>
      </div>
    );
  };

  const images = [
    zdj3,
    zdj2,
    zdj1,
  ];

  return (
    <div className="product-container">
      <Navbar4 />
      <div className="product-images-info">
        <Carousel showThumbs={true} infiniteLoop autoPlay showStatus={false} className="carousel-root">
          {images.map((src, index) => (
            <img key={index} src={src} alt={`Zdjęcie ${index + 1}`} />
          ))}
        </Carousel>
        <div className="product-info">
          <table className="info-table">
            <tbody>
              <tr>
                <td>Udźwig roboczy:</td>
                <td>476 kg</td>
              </tr>
              <tr>
                <td>Całkowita wysokość robocza - pełne podniesienie:</td>
                <td>3546 mm</td>
              </tr>
              <tr>
                <td>Zasięg wysypu przy maks. wysokości:</td>
                <td>579 mm</td>
              </tr>
              <tr>
                <td>Całkowita długość z łyżką:</td>
                <td>2896 mm</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="header-container">
        <h2 className="main-header">CHARAKTERYSTYKA</h2>
        <h2 className="accent-header">TECHNICZNA</h2>
        <h1 className="arrow"> <CgArrowDownR /> </h1>
      </div>
      <div className="product-specifications">
        {Object.entries(specs).map(([sectionTitle, data], index) => (
          <AccordionSection key={index} title={sectionTitle}>
            {renderTableSection(data)}
          </AccordionSection>
        ))}
      </div>
    </div>
  );
};

export default r1050;
