import React, { useState } from 'react';
import '../maszyny.css';
import { Carousel } from 'react-responsive-carousel';
import { CgArrowDownR } from "react-icons/cg";
import Navbar4 from '../../../compo/navbar4/navbar4';
import Stopa from '../../../compo/stopa/Stopa';

// AccordionSection component to handle collapsible sections
const AccordionSection = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => setIsOpen(!isOpen);

  return (
    <div className={`accordion-section ${isOpen ? 'is-open' : ''}`}>
      <button className="accordion-title" onClick={toggleOpen}>
        {title}
      </button>
      <div className="accordion-content">
        {children}
      </div>
    </div>
  );
};

// Function to render the table sections
const renderTableSection = (data) => (
  <div className="table-container">
    <table className="specs-table">
      <tbody>
        {Object.entries(data).map(([label, value]) => (
          <tr key={label}>
            <td>{label}</td>
            <td>{value}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);

const Mlt62575h = () => {
  const [isDescriptionExpanded, setDescriptionExpanded] = useState(false); // State for toggling description

  const toggleDescription = () => {
    setDescriptionExpanded(!isDescriptionExpanded); // Toggle description state
  };

  const specs = {
    'Pojemność': {
      'Udźwig max.': '2500 kg',
      'Max. wysokość podnoszenia':'5.90 m',
      'Wysięg max.': '3.30 m',
      'Zasięg przy max. wysokości podnoszenia': '0.50 m',
      'Siła skrawania z łyżką': '4205 daN'
    },
    'Waga i wymiary': {
      'Masa bez ładunku (z widłami) z kabiną': '4922 kg',
      'Prześwit': '0.38 m',
      'Rozstaw osi': '2.30 m',
      'Długość całkowita do karetki (z zaczepem)': '3.90 m',
      'Długość całkowita do karetki (bez zaczepu)': '3.90 m',
      'Zwis przedni': '0.99 m',
      'Szerokość całkowita': '1.81 m',
      'Szerokość całkowita kabiny': '0.80 m',
      'Wysokość całkowita': '2 m',
      'Kąt wysypu': '117°',
      'Kąt nabierania': '12°',
      'Zewnętrzny promień skrętu (po krawędziach opon)': '3.31 m',
      'Ogumienie standardowe': 'Alliance - A580 - 300/75 R18 142A8',
      'Koła napędzane (przód / tył)': '2 / 2',
      'Koła skrętne (przód / tył)': '2 / 2'
    },
    'Wydajność': {
      'Podnoszenie': '7.30 s',
      'Opuszczanie': '4.95 s',
      'Wysuwanie teleskopu': '5.20 s',
      'Wsuw teleskopu': '3.70 s',
      'Nabieranie': '3.20 s',
      'Wysyp': '3.20 s'
    },
    'Silnik': {
      'Producent silnika': 'Kubota',
      'Norma emisji spalin': 'Stage V',
      'Model silnika': 'V3307-CR-T-E5B',
      'Liczba siłowników / Parametry siłowników': '4 - 3331 cm³',
      'Moc znamionowa silnika spalinowego / Moc': "75 Hp / 55.40 kW",
      'Max. moment obrotowy / Obroty silnika': '265 Nm @1400 rpm',
      'Siła uciągu (z obciążeniem)': "3240 daN",
      'Wentylator zwrotny': 'Standard',
      'Układ chłodzenia silnika': '2 chłodnice: wody i oleju hydraulicznego'
    },
    'Transmisja': {
      'Typ przekładni': 'Hydrostatyczny',
      'Liczba biegów (do przodu / tyłu)': '2 / 2',
      'Prędkość max. (może się różnić w zależności od obowiązujących przepisów)': '25 km/h',
      'Blokada dyferencjału': 'Mechanizm różnicowy o ograniczonym poślizgu na osi przedniej',
      'Hamulec postojowy': 'Automatyczny hamulec postojowy (negatywny)'
    },
    'Hydrauliczny': {
      'Typ pompy hydraulicznej': 'Pompa zębata',
      'Przepływ / Ciśnienie hydrauliki': '86 l/min - 235 bar'
    },
    'Pojemność zbiornika': {
      'Pojemność zbiornika paliwa': '63 l'
    },
    'Hałas i wibracje': {
      'Hałas na stanowisku operatora (LpA)': '76 dB',
      'Hałas na zewnątrz (LwA)': '104 dB',
      'Wibracje przenoszone na ręce/ramiona': '<2.50 m/s²'
    },
    'Pozostałe': {
      'Homologacja bezpieczeństwa kabiny': 'ROPS - FOPS level 1 cab',
      'Rodzaj sterowania': 'JSM'
    }
  };

  const images = [
    "https://views.manitou-group.com/tessa/main_visual_agriculture_telehandler_mlt_x_625_75_h_manitou.png/no-locale?channel=medium",
    "https://views.manitou-group.com/tessa/studio_agriculture_telehandler_mlt_625_manitou_001.png/no-locale?channel=large",
    "https://views.manitou-group.com/tessa/studio_agriculture_telehandler_mlt_625_manitou_002.png/no-locale?channel=large",
    "https://views.manitou-group.com/tessa/studio_agriculture_telehandler_mlt_625_manitou_003.png/no-locale?channel=large"
  ];

  return (
    <div>
    <div className="product-container">
      <Navbar4 />
      <div className="product-images-info">
        <Carousel showThumbs={true} infiniteLoop autoPlay showStatus={false} className="carousel-root">
          {images.map((src, index) => (
            <img key={index} src={src} alt={`Zdjęcie ${index + 1}`} />
          ))}
        </Carousel>
        <div className="product-info">
          <h2>MANITOU</h2>
          <h1>MLT 625-75 H</h1>
          <h3>Ładowarki teleskopowe rolnicze</h3>
          <table className="info-table">
            <tbody>
              <tr>
                <td>Udźwig max.:</td>
                <td>2500 kg</td>
              </tr>
              <tr>
                <td>Max. wysokość podnoszenia:</td>
                <td>5.90 m</td>
              </tr>
              <tr>
                <td>Siła skrawania z łyżką:</td>
                <td>4205 daN</td>
              </tr>
              <tr>
                <td>Prześwit:</td>
                <td>0.38 m</td>
              </tr>
            </tbody>
          </table>
          <div className="product-description">
            <p>
              {isDescriptionExpanded 
                ? `Wózek teleskopowy MLT 625-75 H jest bardzo kompaktową maszyną przeznaczoną specjalnie do zastosowań w rolnictwie i pomoże Ci we wszystkich pracach rolniczych. Niewielkie wymiary maszyny zapewniają niezwykłą zwrotność oraz umożliwiają wykonywanie prac w budynkach i ciasnych miejscach. Komfort w kabinie, wygłuszenie kabiny (76 dB max), widoczność 360 stopni, joystick JSM (Joystick Switch & Move) to cechy które zapewnią bezpieczną pracę na co dzień przy minimalnym wysiłku. System ECS (Easy Connect System) ułatwia wymianę osprzętu na głowicy wysięgnika, a cały zestaw kompatybilnego osprzętu zapewni Ci wszechstronność wymaganą przez Twoje gospodarstwo. Precyzyjny, płynny ruch układu hydraulicznego oraz 4 koła napędzane i skrętne w połączeniu z 3 trybami jazdy zapewniają elastyczność pracy w każdej sytuacji.`
                : `Wózek teleskopowy MLT 625-75 H jest bardzo kompaktową maszyną przeznaczoną specjalnie do zastosowań w rolnictwie i pomoże Ci we wszystkich pracach rolniczych. Niewielkie wymiary maszyny zapewniają niezwykłą zwrotność oraz umożliwiają wykonywanie prac w budynkach i ciasnych miejscach. Komfort w kabinie, wygłuszenie kabiny (76 dB max), widoczność 360 stopni, joystick JSM (Joystick `
              }
            </p>
            <button className="see-more" onClick={toggleDescription}>
              {isDescriptionExpanded ? 'Pokaż mniej' : 'Zobacz więcej'}
            </button>
          </div>
          <div className="product-buttons">
          <a href="https://views.manitou-group.com/machines/WykuUSYAACgAvXL0?format=pdf&amp;unit=metric&amp;locale=pl-PL" className="btn download-button">
          Pobierz kartę danych technicznych
        </a>          </div>
        </div>
      </div>
      <div className="header-container">
        <h2 className="main-header">CHARAKTERYSTYKA TECHNICZNA</h2>
        <CgArrowDownR className='arrow' />
      </div>
      <div className="product-specifications">
        {Object.entries(specs).map(([sectionTitle, data], index) => (
          <AccordionSection key={index} title={sectionTitle}>
            {renderTableSection(data)}
          </AccordionSection>
        ))}
      </div>
      <div className="download-section">

      </div>
    
    </div>
    <Stopa />

    </div>
  );
};

export default Mlt62575h;
