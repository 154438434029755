import React, { useState } from 'react';
import '../maszyny.css';
import { Carousel } from 'react-responsive-carousel';
import zdj1 from './zdj/mht790/mht_790_manitou_001.png';
import zdj2 from './zdj/mht790/mht_790_manitou_002.png';
import zdj3 from './zdj/mht790/mht_790_manitou_004.png';
import { CgArrowDownR } from "react-icons/cg";
import Navbar4 from '../../../compo/navbar4/navbar4';

const rt1350 = () => {
  const specs = {
    'Pojemność': {
      'Masa robocza': '2953 kg',
      'Masa własna': '2953 kg',
      'Udźwig roboczy przy 35% obciążenia destabilizującego': '612 kg',
      'Udźwig roboczy przy 50% obciążenia destabilizującego': '875 kg',
      'Obciążenie destabilizujący': '1750 kg',
    },
    'Waga i wymiary': {
      'Całkowita wysokość robocza - pełne podniesienie': '3670 mm',
      'Wysokość do sworznia karetki - ramię całkowicie podniesione': '2794 mm',
      'Zasięg wysypu przy maks. wysokości': '592 mm',
      'Kąt wysypu przy pełnej wysokości': '40°',
      'Wysokość wysypu przy maks. podnoszeniu': '2123 mm',
      'Maksymalny kąt nabierania przy maks. podnoszeniu': '98°',
      'Całkowita wysokość do górnej krawędzi ROPS': '1930 mm',
      'Całkowita długość z łyżką': '3076 mm',
      'Długość całkowita bez łyżki': '2360 mm',
      'Określona wysokość': '1494 mm',
      'Zasięg przy określonej wysokości': '771 mm',
      'Kąt wysypu przy określonej wysokości': '75°',
      'Maksymalny kąt nabierania na poziomie terenu': '28°',
      'Pozycja transportowa': '165 mm',
      'Maksymalny kąt nabierania w pozycji transportowej': '28°',
      'Pozycja wykopowa': '18 mm',
      'Kąt zejścia ze standardową przeciwwagą': '28°',
      'Prześwit': '213 mm',
      'Rozmiar gąsienic': '1148 mm',
      'Szerokość płyty gąsienicowej': '300 mm',
      'Rozstaw gąsienic': '1168 mm',
      'Całkowita szerokość bez łyżki': '1448 mm',
      'Szerokość łyżki': '1524 mm',
      'Promień skrętu - do przodu z łyżką': '1918 mm',
      'Średnica zachodzenia tyłu': '1440 mm',
      'Typ gąsienic / Rolki gąsienic / Typ rolek': 'Rubber / 4 / Steel',
    },
    'Wydajność': {
      'Prędkość jazdy - wersja jednobiegowa': '10 km/h',
      'Uciąg / Siła trakcji': '3248 kg',
      'Siła odspajania - przy zamykaniu łyżki': '1588 kg',
      'Siła odspajania - przy podnoszeniu': '1692 kg',
    },
    'Silnik': {
      'Producent silnika': 'Yanmar',
      'Model silnika': '4TNV88C-KMSV',
      'Typ silnika': 'Radial Piston',
      'Moc maksymalna / maksymalna': '34.30 kW @ 2500 rpm',
      'Moc znamionowa silnika spalinowego': '46 Hp',
      'Akumulator': '12 V',
      'Cold Cranking Amps at Temperature (CCA)': '800 A',
      'Alternator - napięcie / natężenie prądu': '12 V / 100 A',
    },
    'Hydrauliczny': {
      'Układ hydrauliki dodatkowej': '63.60 l/min',
    },
    'Pojemność zbiornika': {
      'Pojemność miski olejowej': '6.40 l',
      'Pojemność zbiornika oleju hydraulicznego': '34.80 l',
      'Pojemność zbiornika paliwa': '59.40 l',
      'Pojemność skokowa / Liczba siłowników': '7.90 l / 4',
    }
  };

  const renderTableSection = (data) => (
    <div className="table-container">
      <table className="specs-table">
        <tbody>
          {Object.entries(data).map(([label, value]) => (
            <tr key={label}>
              <td>{label.replace(/([A-Z])/g, ' $1').replace(/_/g, ' ')}</td>
              <td>{value}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  const AccordionSection = ({ title, children }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleOpen = () => setIsOpen(!isOpen);

    return (
      <div className={`accordion-section ${isOpen ? 'is-open' : ''}`}>
        <button className="accordion-title" onClick={toggleOpen}>
          {title}
        </button>
        <div className="accordion-content">
          {children}
        </div>
      </div>
    );
  };

  const images = [
    zdj3,
    zdj2,
    zdj1,
  ];

  return (
    <div className="product-container">
      <Navbar4 />
      <div className="product-images-info">
        <Carousel showThumbs={true} infiniteLoop autoPlay showStatus={false} className="carousel-root">
          {images.map((src, index) => (
            <img key={index} src={src} alt={`Zdjęcie ${index + 1}`} />
          ))}
        </Carousel>
        <div className="product-info">
          <table className="info-table">
            <tbody>
              <tr>
                <td>Całkowita wysokość robocza - pełne podniesienie:</td>
                <td>3670 mm</td>
              </tr>
              <tr>
                <td>Zasięg wysypu przy maks. wysokości:</td>
                <td>592 mm</td>
              </tr>
              <tr>
                <td>Całkowita długość z łyżką:</td>
                <td>3076 mm</td>
              </tr>
              <tr>
                <td>Całkowita szerokość bez łyżki:</td>
                <td>1448 mm</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="header-container">
        <h2 className="main-header">CHARAKTERYSTYKA</h2>
        <h2 className="accent-header">TECHNICZNA</h2>
        <h1 className="arrow"> <CgArrowDownR /> </h1>
      </div>
      <div className="product-specifications">
        {Object.entries(specs).map(([sectionTitle, data], index) => (
          <AccordionSection key={index} title={sectionTitle}>
            {renderTableSection(data)}
          </AccordionSection>
        ))}
      </div>
    </div>
  );
};

export default rt1350;
