import React, { useState } from 'react';
import '../maszyny.css';
import { Carousel } from 'react-responsive-carousel';
import { CgArrowDownR } from "react-icons/cg";
import Navbar4 from '../../../compo/navbar4/navbar4';
import Stopa from '../../../compo/stopa/Stopa';

const AccordionSection = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => setIsOpen(!isOpen);

  return (
    <div className={`accordion-section ${isOpen ? 'is-open' : ''}`}>
      <button className="accordion-title" onClick={toggleOpen}>
        {title}
      </button>
      <div className="accordion-content">
        {children}
      </div>
    </div>
  );
};

const renderTableSection = (data) => (
  <div className="table-container">
    <table className="specs-table">
      <tbody>
        {Object.entries(data).map(([label, value]) => (
          <tr key={label}>
            <td>{label}</td>
            <td>{value}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);

const Mla325h = () => {
  const [isDescriptionExpanded, setDescriptionExpanded] = useState(false);

  const toggleDescription = () => {
    setDescriptionExpanded(!isDescriptionExpanded);
  };

  const specs = {
    'Pojemność': {
      'Statyczne obciążenie destabilizujące z łyżką (na wyprost)': '1270 kg',
      'Statyczne obciążenie destabilizujące z łyżką (pełny skręt)': '1115 kg',
      'Statyczne obciążenie destabilizujące z widłami (na wyprost)': '965 kg',
      'Statyczne obciążenie destabilizujące z widłami (pełny skręt)': '845 kg',
      'Max. wysokość punktu obrotu łyżki': '2836 mm'
    },
    'Waga i wymiary': {
      'Masa bez ładunku (z widłami) z dachem na 4 słupkach': '2170 kg',
      'Rozstaw osi': '1650 mm',
      'Szerokość całkowita kabiny': '991 mm',
      'Wysokość całkowita z dachem na 4 słupkach': '2258 mm',
      'Zasięg wysypu przy maks. wysokości': '479 mm',
      'Wysokość pokrywy silnika': '1405 mm',
      'Całkowita wysokość robocza - pełne podniesienie': '3696 mm',
      'Kąt przegubu': '45°',
      'Maximum oscillation angle': '10°',
      'Całkowita długość z łyżką': '4154 mm',
      'Całkowita szerokość bez łyżki': '1100 mm',
      'Całkowita długość - bez łyżki': '3458 mm',
      'Zwis tylny': '995 mm',
      'Kąt wysypu przy maks. podnoszeniu': '42°',
      'Wysokość wysypu przy maks. podnoszeniu': '2126 mm',
      'Maksymalny kąt nabierania przy maks. podnoszeniu': '48°',
      'Maksymalny kąt nabierania na poziomie terenu': '49°',
      'Pozycja wykopowa': '-79 mm',
      'Prześwit': '320 mm',
      'Promień skrętu - do przodu z łyżką': '3246 mm',
      'Clearance Radius - Less bucket': '2767 mm',
      'Wysokość od podłoża do krawędzi łyżki': '2655 mm',
      'Tread width (wheel center)': '836 mm',
      'Luz przegłubu osprzętu': '103 mm',
      'Height from ground to axle center': '214 mm',
      'Szerokość łyżki': '1520 mm',
      'Odległość od środka przedniego koła do sworznia obrotu': '669 mm',
      'Wysokość do sworznia karetki - ramię całkowicie podniesione': '2520 mm'
    },
    'Silnik': {
      'Producent silnika': 'Perkins',
      'Model silnika': '403J-17',
      'Norma emisji spalin': 'Stage V',
      'Liczba siłowników': '3',
      'Moc znamionowa silnika spalinowego / Moc': '24.70 Hp / 18.40 kW',
      'Max. moment obrotowy / Obroty silnika': '85 Nm / 1800 rpm',
      'Układ chłodzenia silnika': 'Woda'
    },
    'Transmisja': {
      'Typ przekładni': 'Hydrostatyczny',
      'Liczba biegów (do przodu) / tyłu)': '2 / 2',
      'Prędkość max. (może się różnić w zależności od obowiązujących przepisów)': '20 km/h',
      'Hamulec serisowy': 'Inching Brake'
    },
    'Hydrauliczny': {
      'Typ pompy hydraulicznej': 'Pompa zębata',
      'Przepływ - Ciśnienie hydrauliki': '30 l/min / 207 bar',
      'Opcjonalna hydraulika wysokiego przepływu (l/min)': '30 l/min'
    },
    'Pojemność zbiornika': {
      'Olej hydrauliczny': '24 l',
      'Pojemność zbiornika paliwa': '45 l'
    }
  };

  const images = [
    "https://views.manitou-group.com/tessa/studio_articulated_loader_mla_3_25_h_cabin_manitou_004.png/no-locale?channel=medium",
    "https://views.manitou-group.com/tessa/studio_articulated_loader_mla_3_25_h_cabin_manitou_001.png/no-locale?channel=large",
    "https://views.manitou-group.com/tessa/studio_articulated_loader_mla_3_25_h_cabin_manitou_002.png/no-locale?channel=large",
    "https://views.manitou-group.com/tessa/studio_articulated_loader_mla_3_25_h_cabin_manitou_003.png/no-locale?channel=large"
  ];

  return (
    <div>
    <div className="product-container">
      <Navbar4 />
      <div className="product-images-info">
        <Carousel showThumbs={true} infiniteLoop autoPlay showStatus={false} className="carousel-root">
          {images.map((src, index) => (
            <img key={index} src={src} alt={`Zdjęcie ${index + 1}`} />
          ))}
        </Carousel>
        <div className="product-info">
          <h2>MANITOU</h2>
          <h1>MLA 3-25 H</h1>
          <h3>Ładowarki przegubowe</h3>
          <table className="info-table">
            <tbody>
              <tr>
                <td>Statyczne obciążenie destabilizujące z widłami (na wyprost):</td>
                <td>965 kg</td>
              </tr>
              <tr>
                <td>Statyczne obciążenie destabilizujące z łyżką (na wyprost):</td>
                <td>1270 kg</td>
              </tr>
              <tr>
                <td>Max. wysokość punktu obrotu łyżki:</td>
                <td>2836 mm</td>
              </tr>
              <tr>
                <td>Moc znamionowa silnika spalinowego:</td>
                <td>24.70 Hp</td>
              </tr>
            </tbody>
          </table>
          <div className="product-description">
            <p>
              {isDescriptionExpanded 
                ? `Small but mighty, the MLA 3-25 H packs a punch in a small space. Perfect for navigating those jobs in tight spaces, while bringing the power you need to get the job done. Transport to various job sites easily with its compact size and low transport weight. The narrow width of this machine allows access to tight, confined spaces that most skid loaders with comparable operating capacities can't access. With just 25 HP, this machine will amaze you with its strenght and tractive efforts.`
                : `Small but mighty, the MLA 3-25 H packs a punch in a small space. Perfect for navigating those jobs in tight spaces, while bringing the power you need to get the job done. Transport to various job sites easily with its compact size and low transport weight.`
              }
            </p>
            <button className="see-more" onClick={toggleDescription}>
              {isDescriptionExpanded ? 'Pokaż mniej' : 'Zobacz więcej'}
            </button>
          </div>
          <div className="product-buttons">
          <a href="https://views.manitou-group.com/machines/mla_3_25_h?format=pdf&unit=metric&locale=pl-PL" className="btn download-button">
          Pobierz kartę danych technicznych
        </a>          </div>
        </div>
      </div>
      <div className="header-container">
        <h2 className="main-header">CHARAKTERYSTYKA TECHNICZNA</h2>
        <CgArrowDownR className='arrow' />
      </div>
      <div className="product-specifications">
        {Object.entries(specs).map(([sectionTitle, data], index) => (
          <AccordionSection key={index} title={sectionTitle}>
            {renderTableSection(data)}
          </AccordionSection>
        ))}
      </div>
      <div className="download-section">

      </div>
    </div>
    <Stopa/>
    </div>
  );
};

export default Mla325h;
