import React, { useState } from 'react';
import '../maszyny.css'; 
import { Carousel } from 'react-responsive-carousel'; 
import { CgArrowDownR } from "react-icons/cg";
import Navbar4 from '../../../compo/navbar4/navbar4';
import Stopa from '../../../compo/stopa/Stopa';

// AccordionSection component to handle collapsible sections
const AccordionSection = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => setIsOpen(!isOpen);

  return (
    <div className={`accordion-section ${isOpen ? 'is-open' : ''}`}>
      <button className="accordion-title" onClick={toggleOpen}>
        {title}
      </button>
      <div className="accordion-content">
        {children}
      </div>
    </div>
  );
};

// Function to render the table sections
const renderTableSection = (data) => (
  <div className="table-container">
    <table className="specs-table">
      <tbody>
        {Object.entries(data).map(([label, value]) => (
          <tr key={label}>
            <td>{label}</td>
            <td>{value}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);

const Mh10160 = () => {
  const [isDescriptionExpanded, setDescriptionExpanded] = useState(false); // State for toggling description

  const toggleDescription = () => {
    setDescriptionExpanded(!isDescriptionExpanded); // Toggle description state
  };

  const specs = {
    'Pojemność': {
      'Udźwig max.': '16000 kg',
      'Środek ciężkości ładunku': '600 mm',
      'Max. wysokość podnoszenia':'9.60 m',
      'Wysięg max.': '5.20 m'
    },
    'Waga i wymiary': {
      'Długość całkowita': '7.35 m',
      'Szerokość całkowita': '2.48 m',
      'Prześwit': '0.43 m',
      'Rozstaw osi': '3.37 m',
      'Długość do czoła wideł': '6.15 m',
      'Wysokość całkowita': '2.99 m',
      'Szerokość całkowita kabiny': '0.95 m',
      'Kąt wysypu': '104°',
      'Kąt nabierania': '12°',
      'Zewnętrzny promień skrętu (po krawędziach opon)': '5.20 m',
      'Długość wideł / Szerokość wideł / Przekrój wideł': '1200 mm x 180 mm / 75 mm',
      'Masa bez ładunku (z widłami) z kabiną': '21500 kg'
    },
    'Koła': {
        'Liczba kół przednich / tylnych': '2/2',
        'Koła napędzane (przód / tył)': '2/2',
        'Tryb sterowania': '2 koła skrętne, 4 koła skrętne, Tryb "kraba"',
        'Ogumienie standardowe': '17,5-R25'
    },
    'Silnik': {
        'Producent silnika': 'Yanmar',
        'Norma emisji spalin': 'Stage V / Tier 4',
        'Model silnika': '4TN107FTT-6SMU1',
        'Liczba siłowników / Parametry siłowników': '4 - 4567 cm³',
        'Moc znamionowa silnika spalinowego / Moc': "173 Hp / 127 kW",
        'Max. moment obrotowy / Obroty silnika': '805 Nm@1500 rpm',
        'Układ chłodzenia silnika': 'Woda',
        'Akumulator': '12 V',
        'Siła uciągu (z obciążeniem)': "11770 daN"
    },
    'Transmisja': {
        'Typ przekładni': 'Hydrostatyczny',
        'Liczba biegów (do przodu) / tyłu)': '2/2',
        'Prędkość maksymalna': '25 km/h',
        'Hamulec postojowy': 'Automatyczny hamulec postojowy (negatywny)',
        'Hamulec serwisowy': 'Tarcze hamulcowe w kąpieli olejowej na osi przedniej i tylnej',
        'Zdolność pokonywania wzniesień - z obciążeniem / bez obciążenia': '32.40 % / 55.40 %'
    },
    'Hydrauliczny': {
        'Typ pompy hydraulicznej': 'Pompa wielotłoczkowa o zmiennym wydatku (LSU)',
        'Przepływ - Ciśnienie hydrauliki': '185 l/min - 350 bar'
    },
    'Pojemność zbiornika': {
        'Olej silnikowy': '13 l',
        'Olej hydrauliczny': '290 l',
        'Pojemność zbiornika paliwa': '315 l',
        'Płyn DEF (typ AdBlue®)': '24 l'
    },
    'Hałas i wibracje': {
        'Hałas na stanowisku operatora (LpA)': '75 dB',
        'Hałas na zewnątrz (LwA)': '108 dB',
        'Wibracje przenoszone na ręce/ramiona': '<2.50 m/s²'
    },
    'Pozostałe': {
        'Homologacja bezpieczeństwa kabiny': 'ROPS - FOPS level 2 cab',
        'Rodzaj sterowania': 'JSM',
        'System rozpoznawania osprzętu (E-Reco)': 'Standard'
    }
  };

  const images = [
    "https://views.manitou-group.com/tessa/main_visual_construction_telehandler_mht_10160_manitou.PNG/no-locale?channel=medium",
    "https://views.manitou-group.com/tessa/studio_construction_telehandler_mht_10160_manitou_001.PNG/no-locale?channel=large",
    "https://views.manitou-group.com/tessa/studio_construction_telehandler_mht_10160_manitou_002.PNG/no-locale?channel=large",
    "https://views.manitou-group.com/tessa/studio_construction_telehandler_mht_10160_manitou_003.PNG/no-locale?channel=large"
  ];

  return (
    <div >
    <div className="product-container">
      <Navbar4 />
      <div className="product-images-info">
        <Carousel showThumbs={true} infiniteLoop autoPlay showStatus={false} className="carousel-root">
          {images.map((src, index) => (
            <img key={index} src={src} alt={`Zdjęcie ${index + 1}`} />
          ))}
        </Carousel>
        <div className="product-info">
          <h2>MANITOU</h2>
          <h1>MHT 10160 ST5</h1>
          <h3>Ładowarki teleskopowe budowlane</h3>
          <table className="info-table">
            <tbody>
              <tr>
                <td>Udźwig max.:</td>
                <td>16000 kg</td>
              </tr>
              <tr>
                <td>Max. wysokość podnoszenia:</td>
                <td>9.60 m</td>
              </tr>
              <tr>
                <td>Wysięg max.:</td>
                <td>5.20 m</td>
              </tr>
              <tr>
                <td>Moc znamionowa silnika spalinowego:</td>
                <td>173 Hp</td>
              </tr>
            </tbody>
          </table>
          <div className="product-description">
            <p>
              {isDescriptionExpanded 
                ? `MHT 10160 to ładowarka teleskopowa o udźwigu 16 ton i maksymalnej wysokości podnoszenia 10 metrów. Ładowarka teleskopowa MHT 10160, wyposażona w silnik o mocy 173 KM i przekładnię hydrostatyczną, dzięki 4 kołom napędowym i skrętnym, służy do przenoszenia ciężkich i nieporęcznych ładunków w sposób całkowicie bezpieczny i na każdym terenie. Można go wyposażyć w szeroką gamę osprzętu: widły, pozycjoner, hak i wciągarkę, łopatę, kosz człowieka oraz chwytaki do opon, cylindrów i rur. Dla maksymalnego komfortu maszyna ta jest wyposażona w panoramiczną, ergonomiczną kabinę, dostęp Easy Step, joystick JSM i kolorowy ekran dotykowy.`
                : `MHT 10160 to ładowarka teleskopowa o udźwigu 16 ton i maksymalnej wysokości podnoszenia 10 metrów. Ładowarka teleskopowa MHT 10160, wyposażona w silnik o mocy 173 KM  `
              }
            </p>
            <button className="see-more" onClick={toggleDescription}>
              {isDescriptionExpanded ? 'Pokaż mniej' : 'Zobacz więcej'}
            </button>
          </div>
          <div className="product-buttons">
          <a href="https://views.manitou-group.com/machines/MHT_10160_ST5?format=pdf&amp;unit=metric&amp;locale=pl-PL" className="btn download-button">
          Pobierz kartę danych technicznych
        </a>          </div>
        </div>
      </div>
      <div className="header-container">
        <h2 className="main-header">CHARAKTERYSTYKA TECHNICZNA</h2>
        <CgArrowDownR className='arrow' />
      </div>
      <div className="product-specifications">
        {Object.entries(specs).map(([sectionTitle, data], index) => (
          <AccordionSection key={index} title={sectionTitle}>
            {renderTableSection(data)}
          </AccordionSection>
        ))}
      </div>
      <div className="download-section">

      </div>
    </div>
    <Stopa />

    </div>
  );
};

export default Mh10160;
