import React, { useState } from 'react';
import '../maszyny.css';
import { Carousel } from 'react-responsive-carousel';
import zdj1 from './zdj/mht790/mht_790_manitou_001.png';
import zdj2 from './zdj/mht790/mht_790_manitou_002.png';
import zdj3 from './zdj/mht790/mht_790_manitou_004.png';
import { CgArrowDownR } from "react-icons/cg";
import Navbar4 from '../../../compo/navbar4/navbar4';

const m30 = () => {
  const specs = {
    'Charakterystyka techniczna': {
      'Producent': 'MANITOU',
      'Nazwa modelu': 'M 30-2 D ST5',
      'Źródło zasilania': 'Diesel',
      'Typ obsługi': 'Siedzący',
      'Udźwig max.': '3000 kg',
      'Środek ciężkości ładunku': '500 mm',
      'Odległość ładunku, od środka osi napędzanej do wideł': '747 mm',
      'Rozstaw osi': '1995 mm',
    },
    'Weight': {
      'Masa własna': '5895 kg',
      'Nacisk na przednią oś (z obciążeniem) / tylną oś (z obciążeniem)': '7490 kg / 1405 kg',
      'Nacisk na oś przednią - bez obciążenia / tylną - bez obciążenia': '2155 kg / 3740 kg',
    },
    'Koła': {
      'Typ ogumienia': 'Pneumatyczne',
      'Wymiary kół przednich': '14,5R20 18PR MPT80',
      'Wymiary kół tylnych': '10R17,5 G291 GY',
      'Liczba kół przednich / tylnych': '2 / 2',
      'Ilość kół napędzanych': '2',
      'Rozstaw kół przednich': '1550 mm',
      'Rozstaw kół tylnych': '1644 mm',
    },
    'Dimensions': {
      'Wysokość osłony operatora (kabiny)': '2450 mm',
      'Wysokość fotela': '1420 mm',
      'Długość całkowita': '4701 mm',
      'Długość do czoła wideł': '3501 mm',
      'Szerokość całkowita': '1923 mm',
      'Przekrój wideł x Szerokość wideł x Długość wideł': '40 mm x 125 mm / 1200 mm',
      'Karetka nośna wideł według normy DIN 15173 A/B': '3A',
      'Szerokość karetki wideł': '1470 mm',
      'Prześwit pod masztem': '406 mm',
      'Prześwit pośrodku rozstawu osi': '390 mm',
      'Szerokość korytarza roboczego z paletą 1000 x 1200 w poprzek': '5360 mm',
      'Korytarz roboczy z paletą 800 x 1200 wzdłuż': '5360 mm',
      'Promień skrętu': '3220 mm',
    },
    'Wydajność': {
      'Prędkość jazdy (z obciążeniem / bez obciążenia)': '22 km/h / 22 km/h',
      'Prędkość podnoszenia (z obciążeniem / bez obciążenia)': '0.60 m/s / 0.60 m/s',
      'Prędkość opuszczania (z obciążeniem / bez obciążenia)': '0.40 m/s / 0.40 m/s',
      'Siła uciągu (z obciążeniem)': '5600 daN',
      'Zdolność pokonywania wzniesień - z obciążeniem': '62 %',
      'Hamulec serisowy': 'Hydrauliczny',
    },
    'Silnik': {
      'Moc silnika wg ISO 1585': '55 kW',
      'Producent silnika / Model silnika': 'Deutz / TCD 2,9',
      'Prędkość znamionowe': '2300 rpm',
      'Liczba siłowników / Parametry siłowników': '4 - 2925 cm³',
    },
    'Pozostałe': {
      'Ciśnienie hydrauliczne robocze dla osprzętu': '185 bar',
      'Przepływ oleju do osprzętu': '97 l/min',
      'Poziom hałasu przy uchu kierowcy wg DIN 12 053': '78 dB',
    },
  };

  const renderTableSection = (data) => (
    <div className="table-container">
      <table className="specs-table">
        <tbody>
          {Object.entries(data).map(([label, value]) => (
            <tr key={label}>
              <td>{label.replace(/([A-Z])/g, ' $1').replace(/_/g, ' ')}</td>
              <td>{value}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  const AccordionSection = ({ title, children }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleOpen = () => setIsOpen(!isOpen);

    return (
      <div className={`accordion-section ${isOpen ? 'is-open' : ''}`}>
        <button className="accordion-title" onClick={toggleOpen}>
          {title}
        </button>
        <div className="accordion-content">
          {children}
        </div>
      </div>
    );
  };

  const images = [
    zdj3,
    zdj2,
    zdj1,
  ];

  return (
    <div className="product-container">
      <Navbar4 />
      <div className="product-images-info">
        <Carousel showThumbs={true} infiniteLoop autoPlay showStatus={false} className="carousel-root">
          {images.map((src, index) => (
            <img key={index} src={src} alt={`Zdjęcie ${index + 1}`} />
          ))}
        </Carousel>
        <div className="product-info">
          <table className="info-table">
            <tbody>
              <tr>
                <td>Udźwig max.:</td>
                <td>3000 kg</td>
              </tr>
              <tr>
                <td>Środek ciężkości ładunku:</td>
                <td>500 mm</td>
              </tr>
              <tr>
                <td>Ilość kół napędzanych:</td>
                <td>2</td>
              </tr>
              <tr>
                <td>Długość do czoła wideł:</td>
                <td>3501 mm</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="header-container">
        <h2 className="main-header">CHARAKTERYSTYKA</h2>
        <h2 className="accent-header">TECHNICZNA</h2>
        <h1 className="arrow"> <CgArrowDownR /> </h1>
      </div>
      <div className="product-specifications">
        {Object.entries(specs).map(([sectionTitle, data], index) => (
          <AccordionSection key={index} title={sectionTitle}>
            {renderTableSection(data)}
          </AccordionSection>
        ))}
      </div>
    </div>
  );
};

export default m30;
