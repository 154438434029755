import React, { useState } from 'react';
import '../maszyny.css'; 
import { Carousel } from 'react-responsive-carousel'; 
import zdj1 from './zdj/mht790/mht_790_manitou_001.png';
import zdj2 from './zdj/mht790/mht_790_manitou_002.png';
import zdj3 from './zdj/mht790/mht_790_manitou_004.png';
import { CgArrowDownR } from "react-icons/cg";
import Navbar4 from '../../../compo/navbar4/navbar4';
import Stopa from '../../../compo/stopa/Stopa';

const AccordionSection = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => setIsOpen(!isOpen);

  return (
    <div className={`accordion-section ${isOpen ? 'is-open' : ''}`}>
      <button className="accordion-title" onClick={toggleOpen}>
        {title}
      </button>
      <div className="accordion-content">
        {children}
      </div>
    </div>
  );
};

const renderTableSection = (data) => (
  <div className="table-container">
    <table className="specs-table">
      <tbody>
        {Object.entries(data).map(([label, value]) => (
          <tr key={label}>
            <td>{label}</td>
            <td>{value}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);

const Mh790 = () => {
  const [isDescriptionExpanded, setDescriptionExpanded] = useState(false);

  const toggleDescription = () => {
    setDescriptionExpanded(!isDescriptionExpanded);
  };

  const specs = {
    'Pojemność': {
      'Udźwig max.': '9000 kg',
      'Środek ciężkości ładunku': '600 mm',
      'Max. wysokość podnoszenia':'6.80 m',
      'Wysięg max.': '3.70 m'
    },
    'Waga i wymiary': {
      'Długość całkowita': '6.41 m',
      'Szerokość całkowita': '2.47 m',
      'Prześwit': '0.44 m',
      'Rozstaw osi': '2.87 m',
      'Długość do czoła wideł': '5.21 m',
      'Wysokość całkowita': '2.54 m',
      'Szerokość całkowita kabiny': '0.93 m',
      'Kąt wysypu': '109°',
      'Kąt nabierania': '15°',
      'Zewnętrzny promień skrętu (po krawędziach opon)': '4.72 m',
      'Długość wideł / Szerokość wideł / Przekrój wideł': '1200 mm x 200 mm / 60 mm',
      'Masa bez ładunku (z widłami) z kabiną': '13160 kg'
    },
    'Koła': {
        'Liczba kół przednich / tylnych': '2/2',
        'Koła napędzane (przód / tył)': '2/2',
        'Tryb sterowania': '2 koła skrętne, 4 koła skrętne, Tryb "kraba"'
    },
    'Silnik': {
        'Producent silnika': 'Yanmar',
        'Norma emisji spalin': 'Stage V / Tier 4',
        'Model silnika': '4TN107FHT-5SMUF',
        'Liczba siłowników / Parametry siłowników': '4 - 4567 cm³',
        'Moc znamionowa silnika spalinowego / Moc': "143 Hp / 105 kW",
        'Max. moment obrotowy / Obroty silnika': '602 Nm@1500 rpm',
        'Układ chłodzenia silnika': 'woda',
        'Akumulator': '12',
        'Siła uciągu (z obciążeniem)': "10050 daN"
    },
    'Transmisja': {
        'Typ przekładni': 'Hydrostatyczny',
        'Liczba biegów (do przodu) / tyłu)': '2/2',
        'Prędkość maksymalna': '35 km/h',
        'Hamulec postojowy': 'Automatyczny hamulec postojowy (negatywny)',
        'Hamulec serisowy': 'Tarcze hamulcowe w kąpieli olejowej na osi przedniej i tylnej',
        'Zdolność pokonywania wzniesień - z obciążeniem / bez obciążenia': '48.10 % / 37.40 %'
    },
    'Hydrauliczny': {
        'Typ pompy hydraulicznej': 'Pompa wielotłoczkowa o zmiennym wydatku (LSU)',
        'Przepływ - Ciśnienie hydrauliki': '185 l/min - 270 bar'
    },
    'Pojemność zbiornika': {
        'Olej silnikowy': '13 l',
        'Olej hydrauliczny': '151 l',
        'Pojemność zbiornika paliwa': '165 l',
        'Płyn DEF (typ AdBlue®)': '20 l'
    }
  };

  const images = [zdj3, zdj2, zdj1];

  return (
    <div className='all12'>
    <div className="product-container">
      <Navbar4 />
      <div className="product-images-info">
        <Carousel showThumbs={true} infiniteLoop autoPlay showStatus={false} className="carousel-root">
          {images.map((src, index) => (
            <img key={index} src={src} alt={`Zdjęcie ${index + 1}`} />
          ))}
        </Carousel>
        <div className="product-info">
          <h2>MANITOU</h2>
          <h1>MHT 790 ST5</h1>
          <h3>Ładowarki teleskopowe budowlane</h3>
          <table className="info-table">
            <tbody>
              <tr>
                <td>Udźwig max.:</td>
                <td>9000 kg</td>
              </tr>
              <tr>
                <td>Max. wysokość podnoszenia:</td>
                <td>6.80 m</td>
              </tr>
              <tr>
                <td>Wysięg max.:</td>
                <td>3.70 m</td>
              </tr>
              <tr>
                <td>Moc znamionowa silnika spalinowego:</td>
                <td>143 Hp</td>
              </tr>
            </tbody>
          </table>
          <div className="product-description">
            <p>
              {isDescriptionExpanded 
                ? `MHT 790 to ładowarka teleskopowa o udźwigu 9 ton i maksymalnej wysokości podnoszenia 7 metrów. Wyposażony jest w silnik o mocy 145 KM oraz przekładnię hydrostatyczną, która sprawia, że ruchy są niezwykle precyzyjne. Dzięki 4 kołom jezdnym i skrętnym można łatwo i bezpiecznie manewrować w każdym terenie. Ładowarka teleskopowa MHT 790 znajduje zastosowanie w budownictwie, kamieniołomach i przemyśle. Można go wyposażyć w szeroką gamę osprzętu: widły, pozycjoner, hak i wciągarkę, łopatę, kosz człowieka oraz chwytaki do opon, cylindrów i rur. Dla maksymalnego komfortu maszyna ta jest wyposażona w panoramiczną, ergonomiczną kabinę, dostęp Easy Step, joystick JSM i kolorowy ekran dotykowy.`
                : `MHT 790 to ładowarka teleskopowa o udźwigu 9 ton i maksymalnej wysokości podnoszenia 7 metrów. Wyposażony jest w silnik o mocy 145 KM oraz przekładnię hydrostatyczną, która sprawia, że ruchy są...`
              }
            </p>
            <button className="see-more" onClick={toggleDescription}>
              {isDescriptionExpanded ? 'Pokaż mniej' : 'Zobacz więcej'}
            </button>
          </div>
          <div className="product-buttons">
          <a href="https://views.manitou-group.com/machines/MHT_790?format=pdf&amp;unit=metric&amp;locale=pl-PL" className="btn download-button">
          Pobierz kartę danych technicznych
        </a>          </div>
        </div>
      </div>
      <div className="header-container">
        <h2 className="main-header">CHARAKTERYSTYKA TECHNICZNA</h2>
        <CgArrowDownR className='arrow' />
      </div>
      <div className="product-specifications">
        {Object.entries(specs).map(([sectionTitle, data], index) => (
          <AccordionSection key={index} title={sectionTitle}>
            {renderTableSection(data)}
          </AccordionSection>
        ))}
      </div>
      <div className="download-section">

      </div>

    </div>
    <Stopa />
    </div>
  );
};

export default Mh790;
