import React, { useState } from 'react';
import '../maszyny.css'; 
import { Carousel } from 'react-responsive-carousel'; 
import { CgArrowDownR } from "react-icons/cg";
import Navbar4 from '../../../compo/navbar4/navbar4';
import Stopa from '../../../compo/stopa/Stopa';

// AccordionSection component to handle collapsible sections
const AccordionSection = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => setIsOpen(!isOpen);

  return (
    <div className={`accordion-section ${isOpen ? 'is-open' : ''}`}>
      <button className="accordion-title" onClick={toggleOpen}>
        {title}
      </button>
      <div className="accordion-content">
        {children}
      </div>
    </div>
  );
};

// Function to render the table sections
const renderTableSection = (data) => (
  <div className="table-container">
    <table className="specs-table">
      <tbody>
        {Object.entries(data).map(([label, value]) => (
          <tr key={label}>
            <td>{label}</td>
            <td>{value}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);

const Mt625h = () => {
  const [isDescriptionExpanded, setDescriptionExpanded] = useState(false); // State for toggling description

  const toggleDescription = () => {
    setDescriptionExpanded(!isDescriptionExpanded); // Toggle description state
  };

  const specs = {
    'Pojemność': {
      'Udźwig max.': '2500 kg',
      'Max. wysokość podnoszenia':'5.85 m',
      'Wysięg max.': '3.40 m',
      'Siła skrawania z łyżką': '3427 daN'
    },
    'Waga i wymiary': {
      'Długość całkowita do karetki': '3.83 m',
      'Długość do czoła wideł': '3.90 m',
      'Szerokość całkowita': '1.81 m',
      'Wysokość całkowita': '1.92 m',
      'Rozstaw osi': '2.30 m',
      'Prześwit': '0.33 m',
      'Szerokość całkowita kabiny': '0.79 m',
      'Kąt nabierania': '12°',
      'Kąt wysypu': '117°',
      'Zewnętrzny promień skrętu (po krawędziach opon)': '3.31 m',
      'Masa całkowita': '4800 kg',
      'Typ ogumienia': 'Pneumatyczne',
      'Ogumienie standardowe': 'CAMSO SKS532 12-16.5',
      'Długość wideł / Szerokość wideł / Przekrój wideł': '1200 mm x 125 mm x 45 mm'
    },
    'Wydajność': {
      'Podnoszenie': '8 s',
      'Opuszczanie': '5.40 s',
      'Wysuwanie teleskopu': '5.60 s',
      'Wsuw teleskopu': '4.30 s',
      'Nabieranie': '3.50 s',
      'Wysyp': '3.60 s'
    },
    'Silnik': {
      'Producent silnika': 'Kubota',
      'Norma emisji spalin': 'Stage V',
      'Model silnika': 'V3307-CR-TE5B',
      'Liczba siłowników / Parametry siłowników': '4 - 3331 cm³',
      'Moc znamionowa silnika spalinowego / Moc': "75 Hp / 55.40 kW",
      'Max. moment obrotowy / Obroty silnika': '265 Nm @ 1400 rpm',
      'Siła uciągu (z obciążeniem)': "3550 daN"
    },
    'Transmisja': {
      'Typ przekładni': 'Hydrostatyczny',
      'Liczba biegów (do przodu) / tyłu)': '2/2',
      'Prędkość maksymalna': '24.90 km/h',
      'Hamulec postojowy': 'Automatyczny hamulec postojowy (negatywny)',
      'Hamulec serwisowy': 'Tarcze hamulcowe w kąpieli olejowej na osi przedniej'
    },
    'Hydrauliczny': {
      'Typ pompy hydraulicznej': 'Pompa zębata',
      'Ciśnienie hydrauliki': '235 bar'
    },
    'Pojemność zbiornika': {
      'Olej silnikowy': '11.20 l',
      'Olej hydrauliczny': '115 l',
      'Pojemność zbiornika paliwa': '63 l'
    },
    'Hałas i wibracje': {
      'Hałas na stanowisku operatora (LpA)': '76 dB',
      'Hałas na zewnątrz (LwA)': '104 dB',
      'Wibracje przenoszone na ręce/ramiona': '<2.50 m/s²'
    },
    'Pozostałe': {
      'Koła skrętne (przód / tył)': '2 / 2',
      'Koła napędzane (przód / tył)': '2 / 2',
      'Bezpieczeństwo / Homologacja bezpieczeństwa kabiny': 'Standard EN 15000 / ROPS - FOPS level 1 cab',
      'Rodzaj sterowania': 'JSM'
    }
  };

  const images = [
    "https://views.manitou-group.com/tessa/studio_construction_telehandler_MT_625_manitou_004.png/no-locale?channel=medium",
    "https://views.manitou-group.com/tessa/studio_construction_telehandler_MT_625_manitou_001.png/no-locale?channel=large",
    "https://views.manitou-group.com/tessa/studio_construction_telehandler_MT_625_manitou_002.png/no-locale?channel=large",
    "https://views.manitou-group.com/tessa/studio_construction_telehandler_MT_625_manitou_003.png/no-locale?channel=large"
  ];

  return (
    <div className='all12' >
    <div className="product-container">
      <Navbar4 />
      <div className="product-images-info">
        <Carousel showThumbs={true} infiniteLoop autoPlay showStatus={false} className="carousel-root">
          {images.map((src, index) => (
            <img key={index} src={src} alt={`Zdjęcie ${index + 1}`} />
          ))}
        </Carousel>
        <div className="product-info">
          <h2>MANITOU</h2>
          <h1>MT 625 H</h1>
          <h3>Ładowarki teleskopowe budowlane</h3>
          <table className="info-table">
            <tbody>
              <tr>
                <td>Udźwig max.:</td>
                <td>2500 kg</td>
              </tr>
              <tr>
                <td>Max. wysokość podnoszenia:</td>
                <td>5.85 m</td>
              </tr>
              <tr>
                <td>Wysięg max.:</td>
                <td>3.40 m</td>
              </tr>
              <tr>
                <td>Moc znamionowa silnika spalinowego:</td>
                <td>75 Hp</td>
              </tr>
            </tbody>
          </table>
          <div className="product-description">
            <p>
              {isDescriptionExpanded 
                ? `Wózek teleskopowy MT 625 H przeznaczony jest dla budów i prac remontowych na niedużych wysokościach. Kompaktowa konstrukcja o szerokości 1,80 m i wysokości mniejszej od 2 m umożliwia łatwy przejazd przez drzwi oraz manewrowanie na zatłoczonych budowach. Promień skrętu 3,30 m oraz 3 tryby jazdy zapewniają doskonałą manewrowalność. Ten łatwy w użyciu wózek wyposażony jest w ergonomiczny pulpit sterowania i joystick JSM zapewniający łatwość manewrowania. Wózek teleskopowy MT 625 H charakteryzuje się udźwigiem do 2,5 t i wysokością podnoszenia 5,85 m. Jego silnik o mocy 75 KM umożliwia rozwijanie prędkości 25 km/h. Łatwość konserwacji zapewnia duże otwarcie maski silnika. Pompa zębata 90 l umożliwia jednoczesne wykonywanie ruchów wysięgnikiem i teleskopem, co powoduje zwiększenie produktywności.`
                : `Wózek teleskopowy MT 625 H przeznaczony jest dla budów i prac remontowych na niedużych wysokościach. Kompaktowa konstrukcja o szerokości 1,80 m`
              }
            </p>
            <button className="see-more" onClick={toggleDescription}>
              {isDescriptionExpanded ? 'Pokaż mniej' : 'Zobacz więcej'}
            </button>
          </div>
          <div className="product-buttons">
          <a href="https://views.manitou-group.com/machines/52001176?format=pdf&amp;unit=metric&amp;locale=pl-PL" className="btn download-button">
          Pobierz kartę danych technicznych
        </a>          </div>
        </div>
      </div>
      <div className="header-container">
        <h2 className="main-header">CHARAKTERYSTYKA TECHNICZNA</h2>
        <CgArrowDownR className='arrow' />
      </div>
      <div className="product-specifications">
        {Object.entries(specs).map(([sectionTitle, data], index) => (
          <AccordionSection key={index} title={sectionTitle}>
            {renderTableSection(data)}
          </AccordionSection>
        ))}
      </div>
      <div className="download-section">

      </div>
    </div>
    <Stopa />
    </div>
  );
};

export default Mt625h;
