import React, { useState } from 'react';
import '../maszyny.css'; 
import { Carousel } from 'react-responsive-carousel'; 
import { CgArrowDownR } from "react-icons/cg";
import Navbar4 from '../../../compo/navbar4/navbar4';

// Komponent AccordionSection do obsługi sekcji z możliwością rozwijania
const AccordionSection = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => setIsOpen(!isOpen);

  return (
    <div className={`accordion-section ${isOpen ? 'is-open' : ''}`}>
      <button className="accordion-title" onClick={toggleOpen}>
        {title}
      </button>
      <div className="accordion-content">
        {children}
      </div>
    </div>
  );
};

// Funkcja do renderowania sekcji tabeli
const renderTableSection = (data) => (
  <div className="table-container">
    <table className="specs-table">
      <tbody>
        {Object.entries(data).map(([label, value]) => (
          <tr key={label}>
            <td>{label}</td>
            <td>{value}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);

const Mlt741130PSPlus = () => {
  const [isDescriptionExpanded, setDescriptionExpanded] = useState(false); // Stan do przełączania opisu

  const toggleDescription = () => {
    setDescriptionExpanded(!isDescriptionExpanded); // Przełączanie stanu opisu
  };

  const specs = {
    'Pojemność': {
      'Udźwig max.': '4100 kg',
      'Max. wysokość podnoszenia': '6.90 m',
      'Wysięg max.': '3.90 m',
      'Zasięg przy max. wysokości podnoszenia': '1 m',
      'Siła skrawania z łyżką': '6116 daN'
    },
    'Waga i wymiary': {
      'Masa bez ładunku (z widłami) z kabiną': '7965 kg',
      'Prześwit': '0.41 m',
      'Rozstaw osi': '2.81 m',
      'Długość całkowita do karetki (z zaczepem)': '4.97 m',
      'Długość całkowita do karetki (bez zaczepu)': '4.82 m',
      'Zwis przedni': '1.21 m',
      'Szerokość całkowita': '2.39 m',
      'Szerokość całkowita kabiny': '0.95 m',
      'Wysokość całkowita': '2.38 m',
      'Kąt wysypu': '134°',
      'Kąt nabierania': '12°',
      'Zewnętrzny promień skrętu (po krawędziach opon)': '3.93 m',
      'Ogumienie standardowe': 'Alliance - A580 - 460/70 R24 159A8',
      'Koła napędzane (przód / tył)': '2 / 2',
      'Koła skrętne (przód / tył)': '2 / 2'
    },
    'Wydajność': {
      'Podnoszenie': '7 s',
      'Opuszczanie': '6 s',
      'Wysuwanie teleskopu': '6.30 s',
      'Wsuw teleskopu': '5.60 s',
      'Nabieranie': '3.20 s',
      'Wysyp': '2.80 s'
    },
    'Silnik': {
      'Producent silnika': 'Deutz',
      'Norma emisji spalin': 'Stage V / Tier 4 Final',
      'Model silnika': 'TCD 3.6 L4',
      'Liczba siłowników / Parametry siłowników': '4 - 3621 cm³',
      'Moc znamionowa silnika spalinowego / Moc': '129 Hp / 95 kW',
      'Max. moment obrotowy / Obroty silnika': '500 Nm @1600 rpm',
      'Siła uciągu (z obciążeniem)': '7800 daN',
      'Wentylator zwrotny': 'Standard',
      'Układ chłodzenia silnika': '4 chłodnice: woda, intercooler, oleju hydraulicznego i oleju przekładniowego',
      'Zatwierdzony przez HVO (zgodnie z normą EN 15940)': 'Tak'
    },
    'Transmisja': {
      'Typ przekładni': 'Przetwornik momentu obrotowego',
      'Skrzynia biegów': 'Powershift',
      'Liczba biegów (do przodu / tyłu)': '6 / 3',
      'Prędkość max. (może się różnić w zależności od obowiązujących przepisów)': '40 km/h',
      'Blokada dyferencjału': 'Mechanizm różnicowy o ograniczonym poślizgu na osi przedniej',
      'Hamulec postojowy': 'Automatyczny hamulec postojowy (negatywny)',
      'Hamulec serwisowy': 'Tarcze hamulcowe w kąpieli olejowej na osi przedniej i tylnej'
    },
    'Hydrauliczny': {
      'Typ pompy hydraulicznej': 'Pompa wielotłoczkowa o zmiennym wydatku (LSU)',
      'Przepływ / Ciśnienie hydrauliki': '150 l/min - 270 bar',
      'Rozdzielacz z dzielnikiem przepływu': 'Standard'
    },
    'Pojemność zbiornika': {
      'Pojemność zbiornika paliwa': '120 l',
      'Płyn DEF (typ AdBlue®)': '10 l'
    },
    'Hałas i wibracje': {
      'Hałas na stanowisku operatora (LpA)': '72 dB',
      'Hałas na zewnątrz (LwA)': '105 dB',
      'Wibracje przenoszone na ręce/ramiona': '<2.50 m/s²'
    },
    'Pozostałe': {
      'Traktorowa homologacja bezpieczeństwa': 'Traktorowa homologacja bezpieczeństwa',
      'Homologacja bezpieczeństwa kabiny': 'ROPS - FOPS level 2 cab',
      'Rodzaj sterowania': 'JSM'
    }
  };

  const images = [
    "https://views.manitou-group.com/tessa/main_visual_agriculture_telehandler_mlt_741_130_ps_plus_manitou.png/no-locale?channel=medium",
    "https://views.manitou-group.com/tessa/studio_agriculture_telehandler_mlt_741_manitou_001.png/no-locale?channel=large",
    "https://views.manitou-group.com/tessa/studio_agriculture_telehandler_mlt_741_manitou_002.png/no-locale?channel=large",
    "https://views.manitou-group.com/tessa/studio_agriculture_telehandler_mlt_741_manitou_003.png/no-locale?channel=large"
  ];

  return (
    <div className="product-container">
      <Navbar4 />
      <div className="product-images-info">
        <Carousel showThumbs={true} infiniteLoop autoPlay showStatus={false} className="carousel-root">
          {images.map((src, index) => (
            <img key={index} src={src} alt={`Zdjęcie ${index + 1}`} />
          ))}
        </Carousel>
        <div className="product-info">
          <h2>MANITOU</h2>
          <h1>MLT 741-130 PS+</h1>
          <h3>Ładowarki teleskopowe rolnicze</h3>
          <table className="info-table">
            <tbody>
              <tr>
                <td>Udźwig max.:</td>
                <td>4100 kg</td>
              </tr>
              <tr>
                <td>Max. wysokość podnoszenia:</td>
                <td>6.90 m</td>
              </tr>
              <tr>
                <td>Siła skrawania z łyżką:</td>
                <td>6116 daN</td>
              </tr>
              <tr>
                <td>Prześwit:</td>
                <td>0.41 m</td>
              </tr>
            </tbody>
          </table>
          <div className={`product-description ${isDescriptionExpanded ? 'expanded' : ''}`}>
            <p>
              MLT 741-130 PS+ ma wysokość podnoszenia 7 m, maksymalną pojemność 4,1 t oraz przekładnię Powershift. Łączy w sobie komfort, wydajność i ekonomiczność.
            </p>
            <p>
              Dla Twojego komfortu, maszyna jest standardowo wyposażona w kabinę "easy step", kratkę o wysokiej widoczności, automatyczny hamulec postojowy, uchwyt na kubek, pełną gamę schowków...
            </p>
            <p>
              Ponieważ MLT 741 jest zaprojektowany do rolnictwa, ma wstępny filtr powietrza i wentylator z automatycznym odwracaniem. Te funkcje są idealnie dostosowane do zakurzonych środowisk. Pompa z czujnikiem obciążenia i opcjonalny pakiet Intelligent Hydraulics odpowiadają na potrzeby rolników w zakresie wydajności.
            </p>
            <p>
              Pod względem rentowności, MLT 741 łączy łatwość konserwacji (centralne położenie punktów smarowania, samooczyszczający się wstępny filtr powietrza, połączona maszyna) oraz optymalizację zużycia paliwa (dzięki opcjom Ecostop i Regenerative Hydraulics).
            </p>
            <button className="see-more" onClick={toggleDescription}>
              {isDescriptionExpanded ? 'Pokaż mniej' : 'Zobacz więcej'}
            </button>
          </div>
          <div className="product-buttons">
            <button className="btn btn-primary">Zapytaj o ofertę</button>
            <button className="btn btn-secondary">Znajdź dealera</button>
          </div>
        </div>
      </div>
      <div className="header-container">
        <h2 className="main-header">MANITOU na zdjęciach</h2>
        <h2 className="main-header">CHARAKTERYSTYKA TECHNICZNA</h2>
        <CgArrowDownR className='arrow' />
      </div>
      <div className="product-specifications">
        {Object.entries(specs).map(([sectionTitle, data], index) => (
          <AccordionSection key={index} title={sectionTitle}>
            {renderTableSection(data)}
          </AccordionSection>
        ))}
      </div>
      <div className="download-section">
        <a href="https://views.manitou-group.com/machines/mlt741_130_ps_plus?format=pdf&unit=metric&locale=pl-PL" className="btn download-button">
          Pobierz kartę danych technicznych
        </a>
      </div>
    </div>
  );
};

export default Mlt741130PSPlus;
