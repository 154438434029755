import React, { useState } from 'react';
import '../maszyny.css';
import { Carousel } from 'react-responsive-carousel';
import zdj1 from './zdj/mht790/mht_790_manitou_001.png';
import zdj2 from './zdj/mht790/mht_790_manitou_002.png';
import zdj3 from './zdj/mht790/mht_790_manitou_004.png';
import { CgArrowDownR } from "react-icons/cg";
import Navbar4 from '../../../compo/navbar4/navbar4';

const mlt737 = () => {
  const specs = {
    'Pojemność': {
      'Udźwig roboczy': '386 kg',
      'Masa własna': '1352 kg',
    },
    'Waga i wymiary': {
      'Całkowita wysokość robocza - pełne podniesienie': '3167 mm',
      'Wysokość do sworznia karetki - ramię całkowicie podniesione': '2438 mm',
      'Całkowita wysokość do górnej krawędzi ROPS': '1897 mm',
      'Kąt wysypu przy pełnej wysokości': '46°',
      'Wysokość wysypu': '1836 mm',
      'Całkowita długość z łyżką': '2576 mm',
      'Zasięg wysypu przy maks. wysokości': '376 mm',
      'Przechył łyżki na podłożu': '23°',
      'Wysokość siedziska nad podłożem': '879 mm',
      'Rozstaw osi': '775 mm',
      'Całkowita szerokość bez łyżki': '909 mm',
      'Szerokość łyżki': '914 mm',
      'Prześwit': '150 mm',
      'Całkowita długość - bez łyżki': '1905 mm',
      'Kąt zejścia': '30°',
      'Promień skrętu - do przodu z łyżką': '1473 mm',
      'Promień skrętu - karetki bez łyżki': '833 mm',
      'Średnica zachodzenia tyłu': '1097 mm',
    },
    'Wydajność': {
      'Prędkość jazdy (bez obciążenia)': '8.90 km/h',
    },
    'Koła': {
      'Ogumienie standardowe': '5.70x12',
    },
    'Silnik': {
      'Producent silnika': 'Yanmar',
      'Model silnika': '3TNV82A-BPMSR',
      'Norma emisji spalin': 'Stage V',
      'Moc maksymalna': '18.10 kW',
      'Moc znamionowa': '17.80 kW',
      'Max. moment obrotowy / Obroty silnika': '86 Nm / 2400 rpm',
      'Źródło zasilania': 'Diesel',
      'Akumulator': '12 V',
      'Alternator': '40 kW',
      'Rozrusznik': '1.70 kW',
    },
    'Hydrauliczny': {
      'Układ hydrauliki dodatkowej': '38.20 l/min',
      'Ciśnienie układu hydrauliki dodatkowej': '145 bar',
    },
    'Pojemność zbiornika': {
      'Pojemność zbiornika paliwa': '29 l',
      'Pojemność zbiornika oleju hydraulicznego': '27.30 l',
      'Pojemność skokowa': '1.30 l',
    },
    'Hałas i wibracje': {
      'Hałas na zewnątrz (LwA)': '101 dB',
      'Hałas na stanowisku operatora (LpA)': '85 dB',
      'Drgania przenoszone na ciało (ISO 2631-1)': '1.05 m/s²',
      'Wibracje przenoszone na ręce/ramiona': '<1.53 m/s²',
    }
  };

  const renderTableSection = (data) => (
    <div className="table-container">
      <table className="specs-table">
        <tbody>
          {Object.entries(data).map(([label, value]) => (
            <tr key={label}>
              <td>{label.replace(/([A-Z])/g, ' $1').replace(/_/g, ' ')}</td>
              <td>{value}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  const AccordionSection = ({ title, children }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleOpen = () => setIsOpen(!isOpen);

    return (
      <div className={`accordion-section ${isOpen ? 'is-open' : ''}`}>
        <button className="accordion-title" onClick={toggleOpen}>
          {title}
        </button>
        <div className="accordion-content">
          {children}
        </div>
      </div>
    );
  };

  const images = [
    zdj3,
    zdj2,
    zdj1,
  ];

  return (
    <div className="product-container">
      <Navbar4 />
      <div className="product-images-info">
        <Carousel showThumbs={true} infiniteLoop autoPlay showStatus={false} className="carousel-root">
          {images.map((src, index) => (
            <img key={index} src={src} alt={`Zdjęcie ${index + 1}`} />
          ))}
        </Carousel>
        <div className="product-info">
          <h2>MANITOU</h2>
          <h1>MHT 790 ST5</h1>
          <h3>Ładowarki teleskopowe budowlane</h3>
          <table className="info-table">
            <tbody>
              <tr>
                <td>Udźwig roboczy:</td>
                <td>386 kg</td>
              </tr>
              <tr>
                <td>Całkowita wysokość robocza - pełne podniesienie:</td>
                <td>3167 mm</td>
              </tr>
              <tr>
                <td>Zasięg wysypu przy maks. wysokości:</td>
                <td>376 mm</td>
              </tr>
              <tr>
                <td>Całkowita długość z łyżką:</td>
                <td>2576 mm</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="header-container">
        <h2 className="main-header">CHARAKTERYSTYKA</h2>
        <h2 className="accent-header">TECHNICZNA</h2>
        <h1 className="arrow"> <CgArrowDownR /> </h1>
      </div>
      <div className="product-specifications">
        {Object.entries(specs).map(([sectionTitle, data], index) => (
          <AccordionSection key={index} title={sectionTitle}>
            {renderTableSection(data)}
          </AccordionSection>
        ))}
      </div>
    </div>
  );
};

export default mlt737;
