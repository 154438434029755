import React, { useState } from 'react';
import '../maszyny.css';
import { Carousel } from 'react-responsive-carousel';
import { CgArrowDownR } from "react-icons/cg";
import Navbar4 from '../../../compo/navbar4/navbar4';
import Stopa from '../../../compo/stopa/Stopa';

const AccordionSection = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => setIsOpen(!isOpen);

  return (
    <div className={`accordion-section ${isOpen ? 'is-open' : ''}`}>
      <button className="accordion-title" onClick={toggleOpen}>
        {title}
      </button>
      <div className="accordion-content">
        {children}
      </div>
    </div>
  );
};

const renderTableSection = (data) => (
  <div className="table-container">
    <table className="specs-table">
      <tbody>
        {Object.entries(data).map(([label, value]) => (
          <tr key={label}>
            <td>{label}</td>
            <td>{value}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);

const Mla775hz = () => {
  const [isDescriptionExpanded, setDescriptionExpanded] = useState(false);

  const toggleDescription = () => {
    setDescriptionExpanded(!isDescriptionExpanded);
  };

  const specs = {
    'Pojemność': {
      'Statyczne obciążenie destabilizujące z łyżką (na wyprost)': '3378 kg',
      'Statyczne obciążenie destabilizujące z łyżką (pełny skręt)': '2621 kg',
      'Statyczne obciążenie destabilizujące z widłami (na wyprost)': '2741 kg',
      'Statyczne obciążenie destabilizujące z widłami (pełny skręt)': '2174 kg',
      'Max. wysokość punktu obrotu łyżki': '3.47 m',
      'Max. wysokość podnoszenia (pod widłami)': '3.28 m',
      'Siła skrawania z łyżką': '5906 daN'
    },
    'Waga i wymiary': {
      'Masa bez ładunku (z widłami) z dachem na 4 słupkach': '4781 kg',
      'Masa bez ładunku (z widłami) z kabiną': '4953 kg',
      'Rozstaw osi': '2.06 m',
      'Szerokość całkowita dachu na 4 słupkach': '1.28 m',
      'Szerokość całkowita kabiny': '1.28 m',
      'Wysokość całkowita bez FOPS i złożoną ROPS': '1.99 m',
      'Wysokość całkowita z dachem na 4 słupkach': '2.48 m',
      'Wysokość całkowita z kabiną': '2.48 m',
      'Kąt nabierania': '45°',
      'Kąt wysypu': '45°',
      'Kąt przegubu': '45°',
      'Całkowita szerokość bez łyżki': '1.74 m',
      'Całkowita długość - bez łyżki': '4.44 m',
      'Prześwit': '0.31 m'
    },
    'Silnik': {
      'Producent silnika': 'Deutz',
      'Model silnika': 'TD_3_6_L4',
      'Norma emisji spalin': 'Stage IIIB, Stage V, Tier 4',
      'Liczba siłowników / Parametry siłowników': '4 - 3600 cm³',
      'Moc znamionowa silnika spalinowego / Moc': '74.30 Hp / 55.40 kW',
      'Max. moment obrotowy / Obroty silnika': '330 Nm / 1600 rpm',
      'Układ chłodzenia silnika': '2 chłodnice: wody i oleju hydraulicznego'
    },
    'Transmisja': {
      'Typ przekładni': 'Hydrostatyczny',
      'Liczba biegów (do przodu) / tyłu)': '2 / 2',
      'Prędkość max. (może się różnić w zależności od obowiązujących przepisów)': '30 km/h',
      'Prędkość jazdy (z obciążeniem)': '20 km/h',
      'Blokada dyferencjału': 'Blokada przód i tył',
      'Hamulec postojowy': 'Manualny'
    },
    'Hydrauliczny': {
      'Typ pompy hydraulicznej': 'Pompa zębata',
      'Przepływ - Ciśnienie hydrauliki': '70 l/min / 200 bar',
      'Opcjonalna hydraulika wysokiego przepływu (l/min)': '110 l/min',
      'Ciśnienie w hydraulicznym układzie napędowym': '380 bar'
    },
    'Pojemność zbiornika': {
      'Olej hydrauliczny': '74 l',
      'Pojemność zbiornika paliwa': '102 l'
    },
    'Hałas i wibracje': {
      'Wibracje przenoszone na ręce/ramiona': '<2.50 m/s²'
    },
    'Pozostałe': {
      'Homologacja bezpieczeństwa kabiny': 'ROPS - FOPS level 1 cab'
    }
  };

  const images = [
    "https://views.manitou-group.com/tessa/main_visual_articulated_loader_mla_7_75_h_z_manitou.png/no-locale?channel=medium",
    "https://views.manitou-group.com/tessa/studio_articulated_loader_mla7_75h_manitou_001.png/no-locale?channel=large",
    "https://views.manitou-group.com/tessa/studio_articulated_loader_mla7_75h_manitou_002.png/no-locale?channel=large",
    "https://views.manitou-group.com/tessa/studio_articulated_loader_mla7_75h_manitou_003.png/no-locale?channel=large"
  ];

  const galleryImages = [
    "https://views.manitou-group.com/tessa/application_articulated_loader_mla7_manitou_001.JPG/no-locale?channel=medium",
    "https://views.manitou-group.com/tessa/application_articulated_loader_mla7_manitou_012.JPG/no-locale?channel=medium",
    "https://views.manitou-group.com/tessa/application_articulated_loader_mla7_manitou_016.jpg/no-locale?channel=medium"
  ];

  return (
    <div>
    <div className="product-container">
      <Navbar4 />
      <div className="product-images-info">
        <Carousel showThumbs={true} infiniteLoop autoPlay showStatus={false} className="carousel-root">
          {images.map((src, index) => (
            <img key={index} src={src} alt={`Zdjęcie ${index + 1}`} />
          ))}
        </Carousel>
        <div className="product-info">
          <h2>MANITOU</h2>
          <h1>MLA 7-75 H-Z</h1>
          <h3>Ładowarki przegubowe</h3>
          <table className="info-table">
            <tbody>
              <tr>
                <td>Statyczne obciążenie destabilizujące z widłami (na wyprost):</td>
                <td>2741 kg</td>
              </tr>
              <tr>
                <td>Statyczne obciążenie destabilizujące z łyżką (na wyprost):</td>
                <td>3378 kg</td>
              </tr>
              <tr>
                <td>Max. wysokość punktu obrotu łyżki:</td>
                <td>3.47 m</td>
              </tr>
              <tr>
                <td>Moc znamionowa silnika spalinowego:</td>
                <td>74.30 Hp</td>
              </tr>
            </tbody>
          </table>
          <div className="product-description">
            <p>
              {isDescriptionExpanded 
                ? `Manitou MLA 7-75 jest największą ładowarką kołową tej serii wyposażoną w standardowe ramię nośne. Tak jak w przypadku mniejszych jednostek, ta maszyna również nie stosuje kompromisów pomiędzy komfortem a możliwościami! Przekładnia hydrostatyczna działa płynnie przy niskich prędkościach, a gdy zachodzi konieczność zapewnia dużą moc. Operator ma nad wszystkim kontrolę dzięki możliwości sterowania przekładnią i blokadą dyferencjału za pomocą joysticka. Dzięki opcjom prędkości maksymalnej 30 km/h i amortyzacji ramienia nośnego, ładowarka MLA 7-75 zawsze przewiezie Cię komfortowo z jednego obszaru roboczego do kolejnego. Zastosowanie kinematyki ramienia "Z" zapewnia doskonałą siłę odspajania, wyższą dokładność przy powrocie do ustalonego położenia i precyzyjne prowadzenie łyżki. Ta ładowarka kołowa spisuje się najlepiej w trudnych warunkach, zarówno w środowisku rolniczym jak i budowlanym, i nigdy Cię nie zawiedzie.`
                : `Manitou MLA 7-75 jest największą ładowarką kołową tej serii wyposażoną w standardowe ramię nośne. Tak jak w przypadku mniejszych jednostek, ta maszyna również nie stosuje kompromisów pomiędzy komfortem a możliwościami! Przekładnia hydrostatyczna działa płynnie przy niskich prędkościach, a gdy zachodzi konieczność zapewnia dużą moc.`
              }
            </p>
            <button className="see-more" onClick={toggleDescription}>
              {isDescriptionExpanded ? 'Pokaż mniej' : 'Zobacz więcej'}
            </button>
          </div>
          <div className="product-buttons">

          <a href="https://views.manitou-group.com/machines/R50841586?format=pdf&unit=metric&locale=pl-PL" className="btn download-button">
          Pobierz kartę danych technicznych
        </a>       
          </div>
        </div>
      </div>
      
      <div className="header-container">
        <h2 className="main-header">CHARAKTERYSTYKA TECHNICZNA</h2>
        <CgArrowDownR className='arrow' />
      </div>
      <div className="product-specifications">
        {Object.entries(specs).map(([sectionTitle, data], index) => (
          <AccordionSection key={index} title={sectionTitle}>
            {renderTableSection(data)}
          </AccordionSection>
        ))}
      </div>
      <div className="download-section">

      </div>
    </div>
    <Stopa/>
    </div>
  );
};

export default Mla775hz;
