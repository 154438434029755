import React, { useState } from 'react';
import '../maszyny.css';
import { Carousel } from 'react-responsive-carousel';
import zdj1 from './zdj/mht790/mht_790_manitou_001.png';
import zdj2 from './zdj/mht790/mht_790_manitou_002.png';
import zdj3 from './zdj/mht790/mht_790_manitou_004.png';
import { CgArrowDownR } from "react-icons/cg";
import Navbar4 from '../../../compo/navbar4/navbar4';

const rt2150 = () => {
  const specs = {
    'Pojemność': {
      'Udźwig max.': '2787 kg',
      'Masa robocza': '4491 kg',
      'Masa własna': '4491 kg',
      'Udźwig roboczy przy 35% obciążenia destabilizującego': '975 kg',
      'Udźwig roboczy przy 50% obciążenia destabilizującego': '1393 kg',
      'Obciążenie destabilizujący': '2787 kg',
    },
    'Waga i wymiary': {
      'Całkowita wysokość robocza - pełne podniesienie': '4267 mm',
      'Wysokość do sworznia karetki - ramię całkowicie podniesione': '3251 mm',
      'Zasięg wysypu przy maks. wysokości': '876 mm',
      'Kąt wysypu przy pełnej wysokości': '39°',
      'Wysokość wysypu przy maks. podnoszeniu': '2489 mm',
      'Maksymalny kąt nabierania przy maks. podnoszeniu': '102.50°',
      'Całkowita wysokość do górnej krawędzi ROPS': '2111 mm',
      'Całkowita długość z łyżką': '3769 mm',
      'Kąt zejścia': '29.20°',
      'Długość całkowita bez łyżki': '2959 mm',
      'Określona wysokiść': '1720 mm',
      'Zasięg przy określonej wysokości': '795 mm',
      'Kąt wysypu przy określonej wysokości': '75°',
      'Maksymalny kąt nabierania na poziomie terenu': '30°',
      'Pozycja transportowa': '208 mm',
      'Maksymalny kąt nabierania w pozycji transportowej': '31.60°',
      'Pozycja wykopowa': '16 mm',
      'Kąt zejścia ze standardową przeciwwagą': '29.20°',
      'Prześwit': '320 mm',
      'Rozmiar gąsienic': '1313 mm',
      'Szerokość płyty gąsienicowej': '450 mm',
      'Rozstaw gąsienic': '1483 mm',
      'Całkowita szerokość bez łyżki': '1765 mm',
      'Szerokość łyżki': '1877 mm',
      'Promień skrętu - do przodu z łyżką': '2403 mm',
      'Średnica zachodzenia tyłu': '1641 mm',
      'Maksymalny kąt nabierania przy określonej wysokości': '66.80°',
      'Kąt natarcia': '90°',
      'Wysokość ostrogi gąsienicy': '25 mm',
      'Typ gąsienic / Rolki gąsienic / Typ rolek': 'Rubber / 5 / Steel',
    },
    'Wydajność': {
      'Prędkość jazdy - wersja jednobiegowa': '9.50 km/h',
      'Prędkość jazdy - wersja dwubiegowa': '14.20 km/h',
      'Uciąg / Siła trakcji': '5503 kg',
      'Siła odspajania - przy zamykaniu łyżki': '3008 kg',
      'Siła odspajania - przy podnoszeniu': '2940 kg',
    },
    'Silnik': {
      'Producent silnika': 'Yanmar',
      'Model silnika': 'TNV98CT-NMSL',
      'Typ silnika': 'Axial Piston with Planetary Gear Box Reduction',
      'Moc maksymalna / maksymalna': '53.70 kW @ 2500 rpm',
      'Moc znamionowa / znamionowa': '52.70 kW / 2500 rpm',
      'Max. moment obrotowy': '294 Nm',
      'Moc znamionowa silnika spalinowego': '72 Hp',
      'Akumulator': '12 V',
      'Cold Cranking Amps at Temperature (CCA)': '850 A',
      'Alternator - napięcie / natężenie prądu': '14 V / 100 A',
    },
    'hydrauliczny': {
      'Układ hydrauliki dodatkowej': '82.51 l/min',
    },
    'Pojemność zbiornika': {
      'Pojemność miski olejowej': '10.40 l',
      'Pojemność zbiornika oleju hydraulicznego': '52.20 l',
      'Pojemność zbiornika paliwa': '91 l',
      'Pojemność układu chłodzenia': '14.40 l',
      'Pojemność skokowa / Liczba siłowników': '3.30 l / 4',
    },
    'Hałas i wibracje': {
      'Hałas na zewnątrz (LwA)': '103 dB',
      'Hałas na stanowisku operatora (LpA)': '83 dB',
    },
    'Pozostałe': {
      'Nacisk na podłoże': '0.33 bar',
    },
  };

  const renderTableSection = (data) => (
    <div className="table-container">
      <table className="specs-table">
        <tbody>
          {Object.entries(data).map(([label, value]) => (
            <tr key={label}>
              <td>{label.replace(/([A-Z])/g, ' $1').replace(/_/g, ' ')}</td>
              <td>{value}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  const AccordionSection = ({ title, children }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleOpen = () => setIsOpen(!isOpen);

    return (
      <div className={`accordion-section ${isOpen ? 'is-open' : ''}`}>
        <button className="accordion-title" onClick={toggleOpen}>
          {title}
        </button>
        <div className="accordion-content">
          {children}
        </div>
      </div>
    );
  };

  const images = [
    zdj3,
    zdj2,
    zdj1,
  ];

  return (
    <div className="product-container">
      <Navbar4 />
      <div className="product-images-info">
        <Carousel showThumbs={true} infiniteLoop autoPlay showStatus={false} className="carousel-root">
          {images.map((src, index) => (
            <img key={index} src={src} alt={`Zdjęcie ${index + 1}`} />
          ))}
        </Carousel>
        <div className="product-info">
          <table className="info-table">
            <tbody>
              <tr>
                <td>Całkowita wysokość robocza - pełne podniesienie:</td>
                <td>4267 mm</td>
              </tr>
              <tr>
                <td>Zasięg wysypu przy maks. wysokości:</td>
                <td>876 mm</td>
              </tr>
              <tr>
                <td>Całkowita długość z łyżką:</td>
                <td>3769 mm</td>
              </tr>
              <tr>
                <td>Całkowita szerokość bez łyżki:</td>
                <td>1765 mm</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="header-container">
        <h2 className="main-header">CHARAKTERYSTYKA</h2>
        <h2 className="accent-header">TECHNICZNA</h2>
        <h1 className="arrow"> <CgArrowDownR /> </h1>
      </div>
      <div className="product-specifications">
        {Object.entries(specs).map(([sectionTitle, data], index) => (
          <AccordionSection key={index} title={sectionTitle}>
            {renderTableSection(data)}
          </AccordionSection>
        ))}
      </div>
    </div>
  );
};

export default rt2150;
