import React, { useState } from 'react';
import '../maszyny.css';
import { Carousel } from 'react-responsive-carousel';
import zdj1 from './zdj/mht790/mht_790_manitou_001.png';
import zdj2 from './zdj/mht790/mht_790_manitou_002.png';
import zdj3 from './zdj/mht790/mht_790_manitou_004.png';
import { CgArrowDownR } from "react-icons/cg";
import Navbar4 from '../../../compo/navbar4/navbar4';

const m315 = () => {
  const specs = {
    'Charakterystyka techniczna': {
      'Producent': 'Manitou',
      'Nazwa modelu': 'ME 315',
      'Źródło zasilania': 'Elektryczny',
      'Typ obsługi': 'Siedzący',
      'Udźwig max.': '1500 kg',
      'Środek ciężkości ładunku': '500 mm',
      'Odległość ładunku, od środka osi napędzanej do wideł': '355 mm',
      'Rozstaw osi': '1250 mm',
    },
    'Weight': {
      'Masa własna': '2860 kg',
      'Nacisk na przednią oś (z obciążeniem) / tylną oś (z obciążeniem)': '3600 kg / 560 kg',
      'Nacisk na oś przednią - bez obciążenia / tylną - bez obciążenia': '1350 kg / 1510 kg',
    },
    'Koła': {
      'Typ ogumienia': 'Solid tires',
      'Wymiary kół przednich': '18 x 7 - 8',
      'Wymiary kół tylnych': '15 x 4.5 - 8',
      'Liczba kół przednich / tylnych': '2 / 1',
      'Ilość kół napędzanych': '2',
      'Rozstaw kół przednich': '910 mm',
      'Rozstaw kół tylnych': '175 mm',
    },
    'Dimensions': {
      'Wysokość osłony operatora (kabiny)': '2040 mm',
      'Wysokość fotela': '1000 mm',
      'Wysokość zaczepu': '500 mm',
      'Długość całkowita': '2860 mm',
      'Długość do czoła wideł': '1790 mm',
      'Szerokość całkowita': '1086 mm',
      'Przekrój wideł / Szerokość wideł / Długość wideł': '100 mm x 1150 mm x 35 mm',
      'Karetka nośna wideł według normy DIN 15173 A/B': '2A',
      'Szerokość karetki wideł': '1000 mm',
      'Prześwit pośrodku rozstawu osi': '100 mm',
      'Szerokość korytarza roboczego dla palet 1000 x 1200 poprzecznie': '3138 mm',
      'Korytarz roboczy z paletą 800 x 1200 wzdłuż': '3262 mm',
      'Promień skrętu': '1452 mm',
      'Wewnętrzny promień skrętu (po krawędziach opon)': '0 mm',
    },
    'Wydajność': {
      'Prędkość jazdy (z obciążeniem / bez obciążenia)': '16 km/h - 16 km/h',
      'Prędkość podnoszenia (z obciążeniem / bez obciążenia)': '0.40 m/s - 0.47 m/s',
      'Prędkość opuszczania (z obciążeniem / bez obciążenia)': '0.46 m/s - 0.45 m/s',
      'Zdolność pokonywania wzniesień - z obciążeniem / bez obciążenia': '16% / 18%',
      'Hamulec serisowy': 'Kąpiel olejowa',
    },
    'Silnik': {
      'Number of drive motor / Moc silnika jazdy (S2 60 min)': '2 / 4.50 kW',
      'Moc znamionowa silnika podnoszenia przy S3 15%': '8.60 kW',
      'Akumulator wg DIN 43531/35/36 A, B, C': 'DIN43531 A',
      'Akumulator / Pojemność baterii': '48 V / 460 Ah',
      'Zużycie energii': '4.30 kWh',
    },
    'Pozostałe': {
      'Typ sterowania napędem': 'Elektroniczny',
      'Ciśnienie hydrauliczne robocze dla osprzętu': '155 bar',
      'Przepływ oleju do osprzętu': '35 l/min',
      'Średni poziom akustyczny w uchu operatora pomierzony/gwarantowany': '<70 dB',
      'Poziom hałasu przy uchu kierowcy wg DIN 12 053': '70 dB',
    },
  };

  const renderTableSection = (data) => (
    <div className="table-container">
      <table className="specs-table">
        <tbody>
          {Object.entries(data).map(([label, value]) => (
            <tr key={label}>
              <td>{label.replace(/([A-Z])/g, ' $1').replace(/_/g, ' ')}</td>
              <td>{value}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  const AccordionSection = ({ title, children }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleOpen = () => setIsOpen(!isOpen);

    return (
      <div className={`accordion-section ${isOpen ? 'is-open' : ''}`}>
        <button className="accordion-title" onClick={toggleOpen}>
          {title}
        </button>
        <div className="accordion-content">
          {children}
        </div>
      </div>
    );
  };

  const images = [
    zdj3,
    zdj2,
    zdj1,
  ];

  return (
    <div className="product-container">
      <Navbar4 />
      <div className="product-images-info">
        <Carousel showThumbs={true} infiniteLoop autoPlay showStatus={false} className="carousel-root">
          {images.map((src, index) => (
            <img key={index} src={src} alt={`Zdjęcie ${index + 1}`} />
          ))}
        </Carousel>
        <div className="product-info">
          <table className="info-table">
            <tbody>
              <tr>
                <td>Udźwig max.:</td>
                <td>1500 kg</td>
              </tr>
              <tr>
                <td>Środek ciężkości ładunku:</td>
                <td>500 mm</td>
              </tr>
              <tr>
                <td>Max. wysokość podnoszenia:</td>
                <td>6500 mm</td>
              </tr>
              <tr>
                <td>Źródło zasilania:</td>
                <td>Elektryczny</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="header-container">
        <h2 className="main-header">CHARAKTERYSTYKA</h2>
        <h2 className="accent-header">TECHNICZNA</h2>
        <h1 className="arrow"> <CgArrowDownR /> </h1>
      </div>
      <div className="product-specifications">
        {Object.entries(specs).map(([sectionTitle, data], index) => (
          <AccordionSection key={index} title={sectionTitle}>
            {renderTableSection(data)}
          </AccordionSection>
        ))}
      </div>
    </div>
  );
};

export default m315;
