/** @format */
import React from 'react';
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './Strony/Home';
import Maszyny from './Strony/Rolnicze';
import MaszynyBud from './Strony/Budowlane';
import Obrotowe from './Strony/Obrotowe';
import Przegubowe from './Strony/Przegubowe';
import Platformy from './Strony/Platformy';
import Widlowe from './Strony/Widlowe';
import Kompaktowe from './Strony/Kompaktowe';
import Navbar from './compo/navbar/Navbar';
import axios from 'axios';
import { UserProvider } from './compo/auth/auth';
import Loginpage from './Strony/Loginpage';
import Registerpage from './Strony/Registerpage';
import { AuthProvider } from './context/AuthContext';
import Shop from './Strony/Shop';
import PrivateRoute from './utils/PrivateRoute';
import Activate from './Strony/Activate';
import ProductScreen from './Strony/ProductScreen';
import CartScreen from './Strony/CartScreen';
import { Provider } from 'react-redux'; // Import Provider from react-redux
import store from './store'; // Import your Redux store
import GuestCheckout from './Strony/GuestCheckout'
import Checkout from './Strony/Checkout';
import CompleteOrderForm from './Strony/OrderForm';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import UserProfile from './Strony/UserProfile';
import UserOrders from './Strony/UserOrders';
import UserDashboard from './Strony/UserDashboard';
import Mh790 from './Strony/Maszyny/Budowlane/Mh790';
import Mht625 from './Strony/Maszyny/Budowlane/mht625';
import Mht10160st5 from './Strony/Maszyny/Budowlane/mht10160st5';
import Mht10135st5 from './Strony/Maszyny/Budowlane/mht10135st5';
import Mt730 from './Strony/Maszyny/Budowlane/mt730';
import Mt733 from './Strony/Maszyny/Budowlane/mt733';
import Mt930h from './Strony/Maszyny/Budowlane/mt930h';
import Mt933easy from './Strony/Maszyny/Budowlane/mt933easy';
import Mlt420 from './Strony/Maszyny/Rolnicze/mlt420';
import Mlt625 from './Strony/Maszyny/Rolnicze/mlt625';
import Mlt630 from './Strony/Maszyny/Rolnicze/mlt630-115';
import Mlt630v from './Strony/Maszyny/Rolnicze/mlt630';
import Mlt630vcp from './Strony/Maszyny/Rolnicze/mlt630-115vcp';
import Mlt635130 from './Strony/Maszyny/Rolnicze/mlt635-130';
import Mlt635140 from './Strony/Maszyny/Rolnicze/mlt635-140V';
import Mlt730 from './Strony/Maszyny/Rolnicze/mlt730-115';
import Mlt737 from './Strony/Maszyny/Rolnicze/mlt737-130ps';
import Mlt741140 from './Strony/Maszyny/Rolnicze/mlt741-140v';
import Mlt741130ps from './Strony/Maszyny/Rolnicze/mlt741-130ps';
import R850r from './Strony/Maszyny/Kompaktowe/R850r';
import R1050r from './Strony/Maszyny/Kompaktowe/R1050r';
import R1050rt from './Strony/Maszyny/Kompaktowe/R1050rt';
import Rt1350 from './Strony/Maszyny/Kompaktowe/R1350rt';
import Rt1350rnxt from './Strony/Maszyny/Kompaktowe/R1350rnxt';
import R1650r from './Strony/Maszyny/Kompaktowe/R1650r';
import R1650rt from './Strony/Maszyny/Kompaktowe/R1650rt';
import R1850rt from './Strony/Maszyny/Kompaktowe/R1850rt';
import R1900rt from './Strony/Maszyny/Kompaktowe/R1900rt';
import R2150rt from './Strony/Maszyny/Kompaktowe/R2150rt';
import R2200 from './Strony/Maszyny/Kompaktowe/R2200r';
import R2600 from './Strony/Maszyny/Kompaktowe/R2600r';
import Mrt1645 from './Strony/Maszyny/Obrotowe/mrt1645';
import Mrt2145 from './Strony/Maszyny/Obrotowe/mrt2145';
import Mrt2260 from './Strony/Maszyny/Obrotowe/mrt2260';
import Mrt2660 from './Strony/Maszyny/Obrotowe/mrt2660';
import Mrt3060 from './Strony/Maszyny/Obrotowe/mrt3060';
import Mrt3570 from './Strony/Maszyny/Obrotowe/mrt3570';
import Mla225h from './Strony/Maszyny/Przegubowe/mla2-25h';
import Mla325h from './Strony/Maszyny/Przegubowe/mla3-25h';
import Mla450h from './Strony/Maszyny/Przegubowe/mla4-50h';
import Mla550h from './Strony/Maszyny/Przegubowe/mla5-50h';
import Mla665hz from './Strony/Maszyny/Przegubowe/mla6-65h-z';
import Mla775h from './Strony/Maszyny/Przegubowe/mla7-75h';
import Mla533 from './Strony/Maszyny/Przegubowe/mla533-145v';
import M26 from './Strony/Maszyny/widłowe/m26';
import M30 from './Strony/Maszyny/widłowe/m30';
import M40 from './Strony/Maszyny/widłowe/m40';
import M50 from './Strony/Maszyny/widłowe/m50';
import Me315 from './Strony/Maszyny/widłowe/me315';
import Me316 from './Strony/Maszyny/widłowe/me316';
import Me318 from './Strony/Maszyny/widłowe/me318';
import Me320 from './Strony/Maszyny/widłowe/me320';

axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';
axios.defaults.withCredentials = true;

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
  
function App() {
	return (
		<Provider store={store}> {/* Dodaj Provider z Redux store */}
		<UserProvider>
		  <BrowserRouter>
			<AuthProvider>
				<Elements stripe={stripePromise}>

					<Routes>
						<Route
							path='/'
							element={<Home />}
						/>
						<Route
							path='/maszyny-teleskopowe-rolnicze'
							element={<Maszyny />}
						/>
						<Route
							path='/maszyny-teleskopowe-budowlane'
							element={<MaszynyBud />}
						/>
						<Route
							path='/maszyny-teleskopowe-obrotowe'
							element={<Obrotowe />}
						/>
						<Route
							path='/ladowarki-przegubowe'
							element={<Przegubowe />}
						/>
						<Route
							path='/platformy-robocze'
							element={<Platformy />}
						/>
						<Route
							path='/wozki-widlowe'
							element={<Widlowe />}
						/>
						<Route
							path='/ladowarki-kompaktowe'
							element={<Kompaktowe />}
						/>
						
						<Route
							path='/rejestracja'
							element={<Registerpage />}
						/>
						<Route
							path='/login'
							element={<Loginpage />}
						/>
						<Route
							path='/sklep'
							element={<Shop />}
						/>
						<Route path="/product/:id" element={<ProductScreen />} exact />
						<Route path="/cart/:id?" element={<CartScreen />} exact />
						<Route path="/guest-checkout" element={ < GuestCheckout />} />
						<Route path="/checkout" element={ < Checkout />} />
						<Route path="/cart" element={<CartScreen />} />
       					<Route path="/complete-order/:userId" element={<CompleteOrderForm />} />	
               			<Route path="/activate/:token" component={Activate} />
						<Route path="/profile/:userId" element={<UserDashboard/>} />
						<Route path="/historia-zamowien/:userId" element={<UserOrders/>}/>
						<Route path="/Mh790" element={<Mh790/>}/>
						<Route path="/mht625" element={<Mht625/>}/>
						<Route path="/Mht10160st5" element={<Mht10160st5/>}/>
						<Route path="/Mht10135st5" element={<Mht10135st5/>}/>
						<Route path="/Mt730" element={<Mt730/>}/>
						<Route path="/Mt733" element={<Mt733/>}/>
						<Route path="/Mt930h" element={<Mt930h/>}/>
						<Route path="/Mt933easy" element={<Mt933easy/>}/>
						<Route path="/Mlt625" element={<Mlt625/>}/>
						<Route path="/Mlt630" element={<Mlt630/>}/>
						<Route path="/Mlt630v" element={<Mlt630v/>}/>
						<Route path="/Mlt630vcp" element={<Mlt630vcp/>}/>
						<Route path="/Mlt635130" element={<Mlt635130/>}/>
						<Route path="/Mlt635140" element={<Mlt635140/>}/>
						<Route path="/Mlt730" element={<Mlt730/>}/>
						<Route path="/Mlt741140" element={<Mlt741140/>}/>
						<Route path="/mlt420" element={<Mlt420/>}/>
						<Route path="/Mlt741130ps" element={<Mlt741130ps/>}/>
						<Route path="/R850r" element={<R850r/>}/>
						<Route path="/R1050r" element={<R1050r/>}/>
						<Route path="/R1050rt" element={<R1050rt/>}/>
						<Route path="/Rt1350" element={<Rt1350/>}/>
						<Route path="/Rt1350rnxt" element={<Rt1350rnxt/>}/>
						<Route path="/R1650r" element={<R1650r/>}/>
						<Route path="/R1650rt" element={<R1650rt/>}/>
						<Route path="/R1850rt" element={<R1850rt/>}/>
						<Route path="/R1900rt" element={<R1900rt/>}/>
						<Route path="/R2150rt" element={<R2150rt/>}/>
						<Route path="/R2200" element={<R2200/>}/>
						<Route path="/R2600" element={<R2600/>}/>
						<Route path="/Mrt1645" element={<Mrt1645/>}/>
						<Route path="/Mrt2145" element={<Mrt2145/>}/>
						<Route path="/Mrt2260" element={<Mrt2260/>}/>
						<Route path="/Mrt2660" element={<Mrt2660/>}/>
						<Route path="/Mrt3060" element={<Mrt3060/>}/>
						<Route path="/Mrt3570" element={<Mrt3570/>}/>
						<Route path="/Mla225h" element={<Mla225h/>}/>
						<Route path="/Mla325h" element={<Mla325h/>}/>
						<Route path="/Mla450h" element={<Mla450h/>}/>
						<Route path="/Mla550h" element={<Mla550h/>}/>
						<Route path="/Mla665hz" element={<Mla665hz/>}/>
						<Route path="/Mla775h" element={<Mla775h/>}/>
						<Route path="/Mla533" element={<Mla533/>}/>
						<Route path="/M26" element={<M26/>}/>
						<Route path="/M30" element={<M30/>}/>
						<Route path="/M40" element={<M40/>}/>
						<Route path="/M50" element={<M50/>}/>
						<Route path="/Me315" element={<Me315/>}/>
						<Route path="/Me316" element={<Me316/>}/>
						<Route path="/Me318" element={<Me318/>}/>
						<Route path="/Me320" element={<Me320/>}/>
						<Route path="/mlt420" element={<Mlt420/>}/>
						<Route path="/mlt737" element={<Mlt737/>}/>

						</Routes>
					</Elements>					

					</AuthProvider>
        </BrowserRouter>
      </UserProvider>
    </Provider>
	);
}

export default App;
