import React, { useState } from 'react';
import '../maszyny.css';
import { Carousel } from 'react-responsive-carousel';
import { CgArrowDownR } from "react-icons/cg";
import Navbar4 from '../../../compo/navbar4/navbar4';
import Stopa from '../../../compo/stopa/Stopa';

const AccordionSection = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => setIsOpen(!isOpen);

  return (
    <div className={`accordion-section ${isOpen ? 'is-open' : ''}`}>
      <button className="accordion-title" onClick={toggleOpen}>
        {title}
      </button>
      <div className="accordion-content">
        {children}
      </div>
    </div>
  );
};

const renderTableSection = (data) => (
  <div className="table-container">
    <table className="specs-table">
      <tbody>
        {Object.entries(data).map(([label, value]) => (
          <tr key={label}>
            <td>{label}</td>
            <td>{value}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);

const Mla550h = () => {
  const [isDescriptionExpanded, setDescriptionExpanded] = useState(false);

  const toggleDescription = () => {
    setDescriptionExpanded(!isDescriptionExpanded);
  };

  const specs = {
    'Pojemność': {
      'Statyczne obciążenie destabilizujące z łyżką (na wyprost)': '1880 kg',
      'Statyczne obciążenie destabilizujące z łyżką (pełny skręt)': '1650 kg',
      'Statyczne obciążenie destabilizujące z widłami (na wyprost)': '1470 kg',
      'Statyczne obciążenie destabilizujące z widłami (pełny skręt)': '1295 kg',
      'Max. wysokość punktu obrotu łyżki': '3070 mm'
    },
    'Waga i wymiary': {
      'Masa bez ładunku (z widłami) z dachem na 4 słupkach': '2678 kg',
      'Rozstaw osi': '1770 mm',
      'Szerokość całkowita kabiny': '991 mm',
      'Wysokość całkowita z dachem na 4 słupkach': '2290 mm',
      'Zasięg wysypu przy maks. wysokości': '458 mm',
      'Wysokość pokrywy silnika': '1469 mm',
      'Całkowita wysokość robocza - pełne podniesienie': '3870 mm',
      'Kąt przegubu': '40°',
      'Maximum oscillation angle': '10°',
      'Całkowita długość z łyżką': '4490 mm',
      'Całkowita szerokość bez łyżki': '1330 mm',
      'Całkowita długość - bez łyżki': '3792 mm',
      'Zwis tylny': '1146 mm',
      'Kąt wysypu przy maks. podnoszeniu': '47°',
      'Wysokość wysypu przy maks. podnoszeniu': '2301 mm',
      'Maksymalny kąt nabierania przy maks. podnoszeniu': '46°',
      'Maksymalny kąt nabierania na poziomie terenu': '50°',
      'Pozycja wykopowa': '-72 mm',
      'Prześwit': '356 mm',
      'Promień skrętu - do przodu z łyżką': '3361 mm',
      'Clearance Radius - Less bucket': '2888 mm',
      'Wysokość od podłoża do krawędzi łyżki': '2837 mm',
      'Tread width (wheel center)': '946 mm',
      'Luz przegłubu osprzętu': '168 mm',
      'Height from ground to axle center': '230 mm',
      'Szerokość łyżki': '1520 mm',
      'Odległość od środka przedniego koła do sworznia obrotu': '733 mm',
      'Wysokość do sworznia karetki - ramię całkowicie podniesione': '3070 mm'
    },
    'Silnik': {
      'Producent silnika': 'Perkins',
      'Model silnika': '403J-E17T',
      'Norma emisji spalin': 'Stage V',
      'Liczba siłowników': '3',
      'Moc znamionowa silnika spalinowego / Moc': '48 Hp / 36 kW',
      'Max. moment obrotowy / Obroty silnika': '166 Nm / 1600 rpm',
      'Układ chłodzenia silnika': 'Woda'
    },
    'Transmisja': {
      'Typ przekładni': 'Hydrostatyczny',
      'Liczba biegów (do przodu) / tyłu)': '2 / 2',
      'Prędkość max. (może się różnić w zależności od obowiązujących przepisów)': '20 km/h',
      'Hamulec serisowy': 'Inching Brake'
    },
    'Hydrauliczny': {
      'Typ pompy hydraulicznej': 'Pompa zębata',
      'Przepływ - Ciśnienie hydrauliki': '60 l/min / 207 bar',
      'Opcjonalna hydraulika wysokiego przepływu (l/min)': '60 l/min'
    },
    'Pojemność zbiornika': {
      'Olej hydrauliczny': '50 l',
      'Pojemność zbiornika paliwa': '65 l'
    }
  };

  const images = [
    "https://views.manitou-group.com/tessa/studio_articulated_loader_mla_5_50_manitou_002.png/no-locale?channel=medium",
    "https://views.manitou-group.com/tessa/studio_articulated_loader_mla_5_50_h_cabin_manitou_001.png/no-locale?channel=large",
    "https://views.manitou-group.com/tessa/studio_articulated_loader_mla_5_50_h_cabin_manitou_003.png/no-locale?channel=large",
    "https://views.manitou-group.com/tessa/studio_articulated_loader_mla_5_50_h_cabin_manitou_004.png/no-locale?channel=large"
  ];

  return (
    <div>
    <div className="product-container">
      <Navbar4 />
      <div className="product-images-info">
        <Carousel showThumbs={true} infiniteLoop autoPlay showStatus={false} className="carousel-root">
          {images.map((src, index) => (
            <img key={index} src={src} alt={`Zdjęcie ${index + 1}`} />
          ))}
        </Carousel>
        <div className="product-info">
          <h2>MANITOU</h2>
          <h1>MLA 5-50 H</h1>
          <h3>Ładowarki przegubowe</h3>
          <table className="info-table">
            <tbody>
              <tr>
                <td>Statyczne obciążenie destabilizujące z widłami (na wyprost):</td>
                <td>1470 kg</td>
              </tr>
              <tr>
                <td>Statyczne obciążenie destabilizujące z łyżką (na wyprost):</td>
                <td>1880 kg</td>
              </tr>
              <tr>
                <td>Max. wysokość punktu obrotu łyżki:</td>
                <td>3070 mm</td>
              </tr>
              <tr>
                <td>Moc znamionowa silnika spalinowego:</td>
                <td>48 Hp</td>
              </tr>
            </tbody>
          </table>
          <div className="product-description">
            <p>
              {isDescriptionExpanded 
                ? `MLA 5-50 H to największy z naszych małych modeli ze standardowym równoległym ramieniem ładującym. Prawdziwe połączenie dużego udźwigu z dużą wysokością podnoszenia. Ta bestia jest dostępna z otwartym zadaszeniem lub zamkniętą kabiną dla dodatkowego komfortu. Oprócz bogatego wyposażenia standardowego, dostępna jest lista opcji, dzięki której można uzyskać idealną konfigurację dla swoich potrzeb w zakresie komfortu lub wydajności. Mała maszyna, duża moc. MLA 5-50 H jest dla Ciebie... wytrzymały, twardy i odporny.`
                : `MLA 5-50 H to największy z naszych małych modeli ze standardowym równoległym ramieniem ładującym. Prawdziwe połączenie dużego udźwigu z dużą wysokością podnoszenia. Ta bestia jest dostępna z otwartym zadaszeniem lub zamkniętą kabiną dla dodatkowego komfortu.`
              }
            </p>
            <button className="see-more" onClick={toggleDescription}>
              {isDescriptionExpanded ? 'Pokaż mniej' : 'Zobacz więcej'}
            </button>
          </div>
          <div className="product-buttons">
          <a href="https://views.manitou-group.com/machines/MLA_5-50_H?format=pdf&unit=metric&locale=pl-PL" className="btn download-button">
          Pobierz kartę danych technicznych
        </a>          </div>
        </div>
      </div>
      
      <div className="header-container">
        <h2 className="main-header">CHARAKTERYSTYKA TECHNICZNA</h2>
        <CgArrowDownR className='arrow' />
      </div>
      <div className="product-specifications">
        {Object.entries(specs).map(([sectionTitle, data], index) => (
          <AccordionSection key={index} title={sectionTitle}>
            {renderTableSection(data)}
          </AccordionSection>
        ))}
      </div>
      <div className="download-section">

      </div>
    </div>
    <Stopa/>
    </div>
  );
};

export default Mla550h;
