import React, { useState } from 'react';
import '../maszyny.css'; 
import { Carousel } from 'react-responsive-carousel'; 
import { CgArrowDownR } from "react-icons/cg";
import Navbar4 from '../../../compo/navbar4/navbar4';
import Stopa from '../../../compo/stopa/Stopa';

// Komponent AccordionSection do obsługi sekcji z możliwością rozwijania
const AccordionSection = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => setIsOpen(!isOpen);

  return (
    <div className={`accordion-section ${isOpen ? 'is-open' : ''}`}>
      <button className="accordion-title" onClick={toggleOpen}>
        {title}
      </button>
      <div className="accordion-content">
        {children}
      </div>
    </div>
  );
};

// Funkcja do renderowania sekcji tabeli
const renderTableSection = (data) => (
  <div className="table-container">
    <table className="specs-table">
      <tbody>
        {Object.entries(data).map(([label, value]) => (
          <tr key={label}>
            <td>{label}</td>
            <td>{value}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);

const Mrt164575 = () => {
  const [isDescriptionExpanded, setDescriptionExpanded] = useState(false); // Stan do przełączania opisu

  const toggleDescription = () => {
    setDescriptionExpanded(!isDescriptionExpanded); // Przełączanie stanu opisu
  };

  const specs = {
    'Pojemność': {
      'Udźwig max.': '4500 kg',
      'Max. wysokość podnoszenia': '15.80 m',
      'Wysięg max.': '13.50 m'
    },
    'Waga i wymiary': {
      'Długość całkowita (z widłami)': '7.58 m',
      'Długość do czoła wideł': '6.38 m',
      'Szerokość całkowita': '2.42 m',
      'Wysokość całkowita': '3.04 m',
      'Szerokość całkowita kabiny': '0.96 m',
      'Prześwit': '0.37 m',
      'Rozstaw osi': '2.43 m',
      'Kąt nabierania': '12°',
      'Kąt wysypu': '105°',
      'Obrót wieżyczki': '400°',
      'Masa całkowita': '13305 kg',
      'Długość wideł / Szerokość wideł / Przekrój wideł': '1200 mm x 125 mm x 50 mm'
    },
    'Koła': {
      'Ogumienie standardowe': '405/70-20',
      'Koła napędzane (przód / tył)': '2 / 2',
      'Tryb sterowania': '2 koła skrętne, 4 koła skrętne, Tryb "kraba"'
    },
    'Stabilizatory': {
      'Typ stabilizatorów': 'Skrzydłowy',
      'Sterowanie ze stabilizatorami': 'Sterowanie stabilizatorami: pojedyncze lub jednoczesne'
    },
    'Silnik': {
      'Producent silnika': 'Deutz',
      'Norma emisji spalin': 'Stage V / Tier 4',
      'Model silnika': 'TCD 3.6 L4',
      'Moc znamionowa silnika spalinowego / Moc': '75 Hp / 55.40 kW',
      'Max. moment obrotowy / Obroty silnika': '405 Nm @ 1300 rpm',
      'Liczba siłowników / Parametry siłowników': '4 - 3620 cm³',
      'Układ chłodzenia silnika': 'Chłodzenie wodą'
    },
    'Obwód elektryczny': {
      'Liczba akumulatorów / Akumulator': '2 x 12 V',
      '12V Pojemność baterii': '120 Ah',
      'Battery starting current': '(EN)850 A'
    },
    'Transmisja': {
      'Typ przekładni': 'Hydrostatyczny',
      'Liczba biegów (do przodu) / tyłu)': '2 / 2',
      'Prędkość maksymalna': '25 km/h',
      'Siła uciągu (z obciążeniem)': '9400 daN',
      'Hamulec postojowy': 'Automatyczny hamulec postojowy (negatywny)',
      'Hamulec serwisowy': 'Tarcze hamulcowe w kąpieli olejowej na osi przedniej i tylnej'
    },
    'Wydajność': {
      'Zdolność pokonywania wzniesień - z obciążeniem / bez obciążenia': '44.50% / 44.50%'
    },
    'Hydrauliczny': {
      'Typ pompy hydraulicznej': 'Pompa wielotłoczkowa o zmiennym wydatku (LSU)',
      'Przepływ': '116 l/min',
      'Ciśnienie hydrauliki': '275 bar'
    },
    'Pojemność zbiornika': {
      'Olej silnikowy': '11 l',
      'Pojemność zbiornika paliwa': '130 l'
    },
    'Hałas i wibracje': {
      'Hałas na stanowisku operatora (LpA)': '68 dB',
      'Hałas na zewnątrz (LwA)': '106 dB',
      'Wibracje przenoszone na ręce/ramiona': '<2.50 m/s²'
    },
    'Pozostałe': {
      'Koła skrętne (przód / tył)': '2 / 2',
      'Rodzaj sterowania': '2 "joysticki"',
      'Homologacja bezpieczeństwa kabiny': 'ROPS - FOPS level 2 cab',
      'System rozpoznawania osprzętu (E-Reco)': 'Standard'
    }
  };

  const images = [
    "https://views.manitou-group.com/tessa/studio_rotating_telehandler_mrt_1645_manitou_003.png/no-locale?channel=medium",
    "https://views.manitou-group.com/tessa/studio_rotating_telehandler_mrt_1645_manitou_001.png/no-locale?channel=large",
    "https://views.manitou-group.com/tessa/studio_rotating_telehandler_mrt_1645_manitou_002.png/no-locale?channel=large",
    "https://views.manitou-group.com/tessa/studio_rotating_telehandler_mrt_1645_manitou_003.png/no-locale?channel=large"
  ];

  return (
    <div>
    <div className="product-container">
      <Navbar4 />
      <div className="product-images-info">
        <Carousel showThumbs={true} infiniteLoop autoPlay showStatus={false} className="carousel-root">
          {images.map((src, index) => (
            <img key={index} src={src} alt={`Zdjęcie ${index + 1}`} />
          ))}
        </Carousel>
        <div className="product-info">
          <h2>MANITOU</h2>
          <h1>MRT 1645 75</h1>
          <h3>Ładowarki teleskopowe obrotowe</h3>
          <table className="info-table">
            <tbody>
              <tr>
                <td>Udźwig max.:</td>
                <td>4500 kg</td>
              </tr>
              <tr>
                <td>Max. wysokość podnoszenia:</td>
                <td>15.80 m</td>
              </tr>
              <tr>
                <td>Wysięg max.:</td>
                <td>13.50 m</td>
              </tr>
              <tr>
                <td>Moc znamionowa silnika spalinowego:</td>
                <td>75 Hp</td>
              </tr>
            </tbody>
          </table>
          <div className="product-description">
            <p>
              {isDescriptionExpanded 
                ? `MRT 1645 75 to potężna, obrotowa ładowarka teleskopowa do pracy w każdym terenie. Dźwig, ładowarka teleskopowa i podest do pracy na wysokości w jednym! Ta maszyna to prawdziwy scyzoryk szwajcarski, idealny jako pierwszy sprzęt na twojej budowie. Wyróżniający się, dynamiczny design dla maksymalnej widoczności i produktywności. Inteligentne i bezpieczne sterowanie sprawia, że ładowarka jest łatwa i intuicyjna w obsłudze. Wysuwane podporory zapewniają stabilność i bezpieczeństwo podczas pracy. Dzięki różnym trybom kierowania i skrzyni hydrostatycznej MRT 1645 75 oferuje Państwu doskonałą zwrotność, niezależnie od wykonywanego zadania. Niezrównany komfort i ergonomia, które sprawią że nigdy nie będziesz chciał opuścić Twojego MRT 1645 75.`
                : `MRT 1645 75 to potężna, obrotowa ładowarka teleskopowa do pracy w każdym terenie. Dźwig, ładowarka teleskopowa i podest do pracy na wysokości w jednym! Ta maszyna to prawdziwy scyzoryk szwajcarski, idealny jako pierwszy sprzęt na twojej budowie.`
              }
            </p>
            <button className="see-more" onClick={toggleDescription}>
              {isDescriptionExpanded ? 'Pokaż mniej' : 'Zobacz więcej'}
            </button>
          </div>
          <div className="product-buttons">
          <a href="https://views.manitou-group.com/machines/mrt_1645_400_75?format=pdf&unit=metric&locale=pl-PL" className="btn download-button">
          Pobierz kartę danych technicznych
        </a>          </div>
        </div>
      </div>
      <div className="header-container">
        <h2 className="main-header">CHARAKTERYSTYKA TECHNICZNA</h2>
        <CgArrowDownR className='arrow' />
      </div>
      <div className="product-specifications">
        {Object.entries(specs).map(([sectionTitle, data], index) => (
          <AccordionSection key={index} title={sectionTitle}>
            {renderTableSection(data)}
          </AccordionSection>
        ))}
      </div>
      <div className="download-section">

      </div>
    </div>
    <Stopa />
   </div>
  );
};

export default Mrt164575;
