import React, { useState } from 'react';
import '../maszyny.css';
import { Carousel } from 'react-responsive-carousel';
import zdj1 from './zdj/mht790/mht_790_manitou_001.png';
import zdj2 from './zdj/mht790/mht_790_manitou_002.png';
import zdj3 from './zdj/mht790/mht_790_manitou_004.png';
import { CgArrowDownR } from "react-icons/cg";
import Navbar4 from '../../../compo/navbar4/navbar4';

const rt1850 = () => {
  const specs = {
    'Pojemność': {
      'Udźwig max.': '2398 kg',
      'Masa robocza': '4128 kg',
      'Masa własna': '4128 kg',
      'Udźwig roboczy przy 35% obciążenia destabilizującego': '839 kg',
      'Udźwig roboczy przy 50% obciążenia destabilizującego': '1199 kg',
      'Obciążenie destabilizujące': '2398 kg',
    },
    'Waga i wymiary': {
      'Całkowita wysokość robocza - pełne podniesienie': '4267 mm',
      'Wysokość do sworznia karetki - ramię całkowicie podniesione': '3251 mm',
      'Zasięg wysypu przy maks. wysokości': '876 mm',
      'Kąt wysypu przy pełnej wysokości': '40.20°',
      'Wysokość wysypu przy maks. podnoszeniu': '2489 mm',
      'Maksymalny kąt nabierania przy maks. podnoszeniu': '102.50°',
      'Całkowita wysokość do górnej krawędzi ROPS': '2103 mm',
      'Całkowita długość z łyżką': '3754 mm',
      'Długość całkowita bez łyżki': '2921 mm',
      'Określona wysokość': '1715 mm',
      'Zasięg przy określonej wysokości': '790 mm',
      'Kąt wysypu przy określonej wysokości': '66.80°',
      'Maksymalny kąt nabierania na poziomie terenu': '30°',
      'Pozycja transportowa': '208 mm',
      'Maksymalny kąt nabierania w pozycji transportowej': '31.60°',
      'Pozycja wykopowa': '23 mm',
      'Kąt zejścia ze standardową przeciwwagą': '30.40°',
      'Prześwit': '318 mm',
      'Rozmiar gąsienic': '1313 mm',
      'Szerokość płyty gąsienicowej': '320 mm',
      'Rozstaw gąsienic': '1392 mm',
      'Całkowita szerokość bez łyżki': '1636 mm',
      'Szerokość łyżki': '1674 mm',
      'Promień skrętu - do przodu z łyżką': '2322 mm',
      'Średnica zachodzenia tyłu': '1666 mm',
      'Maksymalny kąt nabierania przy określonej wysokości': '66.80°',
      'Kąt natarcia': '90°',
      'Wysokość ostrogi gąsienicy': '25 mm',
      'Typ gąsienic / Rolki gąsienic / Typ rolek': 'Rubber / 4 / Steel',
    },
    'Wydajność': {
      'Prędkość jazdy - wersja jednobiegowa': '9.50 km/h',
      'Prędkość jazdy - wersja dwubiegowa': '14.20 km/h',
      'Uciąg / Siła trakcji': '4635 kg',
      'Siła odspajania - przy zamykaniu łyżki': '2429 kg',
      'Siła odspajania - przy podnoszeniu': '2275 kg',
    },
    'Silnik': {
      'Producent silnika': 'Yanmar',
      'Model silnika': '4TNV98C-NMSL',
      'Typ silnika': 'Axial Piston with Planetary Gear Box Reduction',
      'Moc maksymalna / maksymalna': '51.70 kW @ 2500 rpm',
      'Moc znamionowa silnika spalinowego': '69.30 Hp',
      'Akumulator': '12 V',
      'Cold Cranking Amps at Temperature (CCA)': '850 A',
      'Alternator - napięcie / natężenie prądu': '14 V / 100 A',
    },
    'Pojemność zbiornika': {
      'Pojemność miski olejowej': '10.40 l',
      'Pojemność zbiornika oleju hydraulicznego': '41.60 l',
      'Pojemność zbiornika paliwa': '91 l',
      'Pojemność układu chłodzenia': '13.30 l',
      'Pojemność skokowa / Liczba siłowników': '3.30 l / 4',
    },
    'Pozostałe': {
      'Nacisk na podłoże': '0.43 bar',
    },
  };

  const renderTableSection = (data) => (
    <div className="table-container">
      <table className="specs-table">
        <tbody>
          {Object.entries(data).map(([label, value]) => (
            <tr key={label}>
              <td>{label.replace(/([A-Z])/g, ' $1').replace(/_/g, ' ')}</td>
              <td>{value}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  const AccordionSection = ({ title, children }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleOpen = () => setIsOpen(!isOpen);

    return (
      <div className={`accordion-section ${isOpen ? 'is-open' : ''}`}>
        <button className="accordion-title" onClick={toggleOpen}>
          {title}
        </button>
        <div className="accordion-content">
          {children}
        </div>
      </div>
    );
  };

  const images = [
    zdj3,
    zdj2,
    zdj1,
  ];

  return (
    <div className="product-container">
      <Navbar4 />
      <div className="product-images-info">
        <Carousel showThumbs={true} infiniteLoop autoPlay showStatus={false} className="carousel-root">
          {images.map((src, index) => (
            <img key={index} src={src} alt={`Zdjęcie ${index + 1}`} />
          ))}
        </Carousel>
        <div className="product-info">
          <table className="info-table">
            <tbody>
              <tr>
                <td>Całkowita wysokość robocza - pełne podniesienie:</td>
                <td>4267 mm</td>
              </tr>
              <tr>
                <td>Zasięg wysypu przy maks. wysokości:</td>
                <td>876 mm</td>
              </tr>
              <tr>
                <td>Całkowita długość z łyżką:</td>
                <td>3754 mm</td>
              </tr>
              <tr>
                <td>Całkowita szerokość bez łyżki:</td>
                <td>1636 mm</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="header-container">
        <h2 className="main-header">CHARAKTERYSTYKA</h2>
        <h2 className="accent-header">TECHNICZNA</h2>
        <h1 className="arrow"> <CgArrowDownR /> </h1>
      </div>
      <div className="product-specifications">
        {Object.entries(specs).map(([sectionTitle, data], index) => (
          <AccordionSection key={index} title={sectionTitle}>
            {renderTableSection(data)}
          </AccordionSection>
        ))}
      </div>
    </div>
  );
};

export default rt1850;
