import React, { useState } from 'react';
import '../maszyny.css';
import { Carousel } from 'react-responsive-carousel';
import zdj1 from './zdj/mht790/mht_790_manitou_001.png';
import zdj2 from './zdj/mht790/mht_790_manitou_002.png';
import zdj3 from './zdj/mht790/mht_790_manitou_004.png';
import { CgArrowDownR } from "react-icons/cg";
import Navbar4 from '../../../compo/navbar4/navbar4';

const r2600 = () => {
  const specs = {
    'Pojemność': {
      'Udźwig roboczy': '1179 kg',
      'Udźwig roboczy z opcjonalną przeciwwagą': '1261 kg',
      'Masa własna': '3719 kg',
    },
    'Waga i wymiary': {
      'Całkowita wysokość robocza - pełne podniesienie': '4089 mm',
      'Wysokość do sworznia karetki - ramię całkowicie podniesione': '3124 mm',
      'Całkowita wysokość do górnej krawędzi ROPS': '2057 mm',
      'Kąt wysypu przy pełnej wysokości': '40°',
      'Wysokość wysypu': '2488 mm',
      'Całkowita długość z łyżką': '3632 mm',
      'Zasięg wysypu przy maks. wysokości': '686 mm',
      'Przechył łyżki na podłożu': '28°',
      'Wysokość siedziska nad podłożem': '1016 mm',
      'Rozstaw osi': '1257 mm',
      'Całkowita szerokość bez łyżki': '1664 mm',
      'Szerokość łyżki': '203 mm',
      'Prześwit': '203 mm',
      'Całkowita długość - bez łyżki': '2832 mm',
      'Kąt zejścia': '25°',
      'Promień skrętu - do przodu z łyżką': '2286 mm',
      'Promień skrętu - karetki bez łyżki': '1422 mm',
      'Średnica zachodzenia tyłu': '1626 mm',
    },
    'Wydajność': {
      'Prędkość jazdy z opcją dwóch przełożeń - Maksymalna': '19.20 km/h',
      'Prędkość jazdy (bez obciążenia)': '12.90 km/h',
    },
    'Koła': {
      'Ogumienie standardowe': '12.00x16.5 HD',
    },
    'Silnik': {
      'Producent silnika': 'Yanmar',
      'Model silnika': '4TNV98CT-NMS',
      'Moc maksymalna': '53.70 kW',
      'Moc znamionowa': '52.70 kW',
      'Max. moment obrotowy / Obroty silnika': '294 Nm / 2500 rpm',
      'Źródło zasilania': 'Diesel',
      'Moc znamionowa silnika spalinowego': '72 Hp',
      'Akumulator': '12 V',
      'Alternator': '95 kW',
      'Rozrusznik': '3 kW',
    },
    'hydrauliczny': {
      'Układ hydrauliki dodatkowej': '90.10 l/min',
      'Ciśnienie układu hydrauliki dodatkowej': '227.50 bar',
      'Układ hydrauliki dodatkowej wysokiego przepływu - opcjonalny (l/min)': '132.50 l/min',
      'Ciśnienie układu hydrauliki dodatkowej wysokiego przepływu - opcja': '220.60 bar',
    },
    'Pojemność zbiornika': {
      'Pojemność zbiornika paliwa': '81.40 l',
      'Pojemność zbiornika oleju hydraulicznego': '30.30 l',
      'Pojemność skokowa': '3.30 l',
    },
    'Hałas i wibracje': {
      'Hałas na zewnątrz (LwA)': '101 dB',
      'Hałas na stanowisku operatora (LpA)': '85 dB',
      'Drgania przenoszone na ciało (ISO 2631-1)': '0.96 m/s²',
      'Wibracje przenoszone na ręce/ramiona': '<1.50 m/s²',
    },
  };

  const renderTableSection = (data) => (
    <div className="table-container">
      <table className="specs-table">
        <tbody>
          {Object.entries(data).map(([label, value]) => (
            <tr key={label}>
              <td>{label.replace(/([A-Z])/g, ' $1').replace(/_/g, ' ')}</td>
              <td>{value}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  const AccordionSection = ({ title, children }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleOpen = () => setIsOpen(!isOpen);

    return (
      <div className={`accordion-section ${isOpen ? 'is-open' : ''}`}>
        <button className="accordion-title" onClick={toggleOpen}>
          {title}
        </button>
        <div className="accordion-content">
          {children}
        </div>
      </div>
    );
  };

  const images = [
    zdj3,
    zdj2,
    zdj1,
  ];

  return (
    <div className="product-container">
      <Navbar4 />
      <div className="product-images-info">
        <Carousel showThumbs={true} infiniteLoop autoPlay showStatus={false} className="carousel-root">
          {images.map((src, index) => (
            <img key={index} src={src} alt={`Zdjęcie ${index + 1}`} />
          ))}
        </Carousel>
        <div className="product-info">
          <table className="info-table">
            <tbody>
              <tr>
                <td>Udźwig roboczy:</td>
                <td>1179 kg</td>
              </tr>
              <tr>
                <td>Całkowita wysokość robocza - pełne podniesienie:</td>
                <td>4089 mm</td>
              </tr>
              <tr>
                <td>Zasięg wysypu przy maks. wysokości:</td>
                <td>686 mm</td>
              </tr>
              <tr>
                <td>Całkowita długość z łyżką:</td>
                <td>3632 mm</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="header-container">
        <h2 className="main-header">CHARAKTERYSTYKA</h2>
        <h2 className="accent-header">TECHNICZNA</h2>
        <h1 className="arrow"> <CgArrowDownR /> </h1>
      </div>
      <div className="product-specifications">
        {Object.entries(specs).map(([sectionTitle, data], index) => (
          <AccordionSection key={index} title={sectionTitle}>
            {renderTableSection(data)}
          </AccordionSection>
        ))}
      </div>
    </div>
  );
};

export default r2600;
