import React, { useState } from 'react';
import '../maszyny.css';
import { Carousel } from 'react-responsive-carousel';
import { CgArrowDownR } from "react-icons/cg";
import Navbar4 from '../../../compo/navbar4/navbar4';
import Stopa from '../../../compo/stopa/Stopa';

const AccordionSection = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => setIsOpen(!isOpen);

  return (
    <div className={`accordion-section ${isOpen ? 'is-open' : ''}`}>
      <button className="accordion-title" onClick={toggleOpen}>
        {title}
      </button>
      <div className="accordion-content">
        {children}
      </div>
    </div>
  );
};

const renderTableSection = (data) => (
  <div className="table-container">
    <table className="specs-table">
      <tbody>
        {Object.entries(data).map(([label, value]) => (
          <tr key={label}>
            <td>{label}</td>
            <td>{value}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);

const Mla225h = () => {
  const [isDescriptionExpanded, setDescriptionExpanded] = useState(false);

  const toggleDescription = () => {
    setDescriptionExpanded(!isDescriptionExpanded);
  };

  const specs = {
    'Pojemność': {
      'Statyczne obciążenie destabilizujące z łyżką (na wyprost)': '1015 kg',
      'Statyczne obciążenie destabilizujące z łyżką (pełny skręt)': '865 kg',
      'Statyczne obciążenie destabilizujące z widłami (na wyprost)': '740 kg',
      'Statyczne obciążenie destabilizujące z widłami (pełny skręt)': '630 kg',
      'Max. wysokość punktu obrotu łyżki': '2736 mm'
    },
    'Waga i wymiary': {
      'Masa bez ładunku (z widłami) z dachem na 4 słupkach': '1740 kg',
      'Rozstaw osi': '1419 mm',
      'Szerokość całkowita kabiny': '991 mm',
      'Wysokość całkowita z dachem na 4 słupkach': '2140 mm',
      'Zasięg wysypu przy maks. wysokości': '475 mm',
      'Wysokość pokrywy silnika': '1254 mm',
      'Całkowita wysokość robocza - pełne podniesienie': '3535 mm',
      'Kąt przegubu': '45°',
      'Maximum oscillation angle': '10°',
      'Całkowita długość z łyżką': '3627 mm',
      'Całkowita szerokość bez łyżki': '1040 mm',
      'Całkowita długość - bez łyżki': '2928 mm',
      'Zwis tylny': '801 mm',
      'Kąt wysypu przy maks. podnoszeniu': '41°',
      'Wysokość wysypu przy maks. podnoszeniu': '2008 mm',
      'Maksymalny kąt nabierania przy maks. podnoszeniu': '45°',
      'Maksymalny kąt nabierania na poziomie terenu': '47°',
      'Pozycja wykopowa': '-45 mm',
      'Prześwit': '153 mm',
      'Promień skrętu - do przodu z łyżką': '2681 mm',
      'Clearance Radius - Less bucket': '2251 mm',
      'Wysokość od podłoża do krawędzi łyżki': '2515 mm',
      'Tread width (wheel center)': '848 mm',
      'Luz przegłubu osprzętu': '192 mm',
      'Height from ground to axle center': '144 mm',
      'Szerokość łyżki': '1100 mm',
      'Odległość od środka przedniego koła do sworznia obrotu': '563 mm',
      'Wysokość do sworznia karetki - ramię całkowicie podniesione': '2736 mm'
    },
    'Silnik': {
      'Producent silnika': 'Perkins',
      'Model silnika': '403J-11',
      'Norma emisji spalin': 'Stage V',
      'Liczba siłowników': '3',
      'Moc znamionowa silnika spalinowego / Moc': '24.70 Hp / 18.40 kW',
      'Max. moment obrotowy / Obroty silnika': '66.80 Nm / 2100 rpm',
      'Układ chłodzenia silnika': 'Woda'
    },
    'Transmisja': {
      'Typ przekładni': 'Hydrostatyczny',
      'Liczba biegów (do przodu) / tyłu)': '1 / 1',
      'Prędkość max. (może się różnić w zależności od obowiązujących przepisów)': '12 km/h',
      'Hamulec serisowy': 'Inching Brake'
    },
    'Hydrauliczny': {
      'Typ pompy hydraulicznej': 'Pompa zębata',
      'Przepływ - Ciśnienie hydrauliki': '30 l/min / 207 bar',
      'Opcjonalna hydraulika wysokiego przepływu (l/min)': '30 l/min'
    },
    'Pojemność zbiornika': {
      'Olej hydrauliczny': '24 l',
      'Pojemność zbiornika paliwa': '30 l'
    }
  };

  const images = [
    "https://views.manitou-group.com/tessa/studio_articulated_loader_mla_2_25_manitou_002.png/no-locale?channel=medium",
    "https://views.manitou-group.com/tessa/studio_articulated_loader_mla_2_25_h_cabin_manitou_001.png/no-locale?channel=large",
    "https://views.manitou-group.com/tessa/studio_articulated_loader_mla_2_25_h_cabin_manitou_003.png/no-locale?channel=large",
    "https://views.manitou-group.com/tessa/studio_articulated_loader_mla_2_25_h_cabin_manitou_004.png/no-locale?channel=large"
  ];

  return (
    <div>
    <div className="product-container">
      <Navbar4 />
      <div className="product-images-info">
        <Carousel showThumbs={true} infiniteLoop autoPlay showStatus={false} className="carousel-root">
          {images.map((src, index) => (
            <img key={index} src={src} alt={`Zdjęcie ${index + 1}`} />
          ))}
        </Carousel>
        <div className="product-info">
          <h2>MANITOU</h2>
          <h1>MLA 2-25 H</h1>
          <h3>Ładowarki przegubowe</h3>
          <table className="info-table">
            <tbody>
              <tr>
                <td>Statyczne obciążenie destabilizujące z widłami (na wyprost):</td>
                <td>740 kg</td>
              </tr>
              <tr>
                <td>Statyczne obciążenie destabilizujące z łyżką (na wyprost):</td>
                <td>1015 kg</td>
              </tr>
              <tr>
                <td>Max. wysokość punktu obrotu łyżki:</td>
                <td>2736 mm</td>
              </tr>
              <tr>
                <td>Moc znamionowa silnika spalinowego:</td>
                <td>24.70 Hp</td>
              </tr>
            </tbody>
          </table>
          <div className="product-description">
            <p>
              {isDescriptionExpanded 
                ? `Don't let the size of the MLA 2-25 H fool you. This machine is a true all terrain machine like its larger counterparts. Perfect machine for landscaping, hobby farms or making large jobs a breeze. This machine continues to impress. Looking to expand your rental fleet? This machine will set you apart from what other rental houses are offering in your area. The nice operator station, open canopy or full cabin in our articulated loaders give much better visibility around the machine.`
                : `Don't let the size of the MLA 2-25 H fool you. This machine is a true all terrain machine like its larger counterparts. Perfect machine for landscaping, hobby farms or making large jobs a breeze.`
              }
            </p>
            <button className="see-more" onClick={toggleDescription}>
              {isDescriptionExpanded ? 'Pokaż mniej' : 'Zobacz więcej'}
            </button>
          </div>
          <div className="product-buttons">
          <a href="https://views.manitou-group.com/machines/MLA_2-25_H?format=pdf&unit=metric&locale=pl-PL" className="btn download-button">
          Pobierz kartę danych technicznych
        </a>          </div>
        </div>
      </div>
      <div className="header-container">
        <h2 className="main-header">CHARAKTERYSTYKA TECHNICZNA</h2>
        <CgArrowDownR className='arrow' />
      </div>
      <div className="product-specifications">
        {Object.entries(specs).map(([sectionTitle, data], index) => (
          <AccordionSection key={index} title={sectionTitle}>
            {renderTableSection(data)}
          </AccordionSection>
        ))}
      </div>
      <div className="download-section">

      </div>
    </div>
    <Stopa/>
    </div>
  );
};

export default Mla225h;
